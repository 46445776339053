import { createPromise } from '../utils';
import { IRequestData } from '../../../models/Application';

export function post(endpoint: string, data?: IRequestData) {
	return createPromise('POST', endpoint, data);
}

export function postWithFiles(endpoint: string, data: IRequestData) {
	if (!data.attachedFiles)
		data.attachedFiles = [];
	return createPromise('POST', endpoint, data);
}