import React, { useState } from 'react';
import moment from 'moment';
import ReactTable, { RowInfo, Column } from 'react-table';
import { IListComponentProps } from '../../../../../app/models/Application';
import DurationParse from '../../../../../app/helpers/DurationParse';
import './PalletLoadList.css';
import { IPalletLoadListItem } from '../../../../../app/models/Pallet';
import PalletApi from '../../../../../app/api/PalletApi';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import toastr from 'toastr';

const PalletLoadList: React.FC<IListComponentProps<IPalletLoadListItem>> = props => {
	const [isGeneratingLoadAuthorisation, setIsGeneratingLoadAuthorisation] = useState<number[]>([]);
	const [isGeneratingLoadSummary, setIsGeneratingLoadSummary] = useState<number[]>([]);

	const getLoadAuthorisation = async (loadId: string, index: number) => {
		setIsGeneratingLoadAuthorisation([...isGeneratingLoadAuthorisation, index]);
		try {
			await PalletApi.loads.getLoadAuthorisationFile(loadId);
		} catch (error) {
			console.error(error);
			toastr.error('Error generating Load Authorisation');
		} finally {
			setIsGeneratingLoadAuthorisation(isGeneratingLoadAuthorisation.filter(i => i !== index));
		}
	}

	const getLoadSummary = async (loadId: string, index: number) => {
		setIsGeneratingLoadSummary([...isGeneratingLoadSummary, index]);
		try {
			await PalletApi.loads.getLoadSummaryFile(loadId);
		} catch (error) {
			console.error(error);
			toastr.error('Error generating Load Summary');
		} finally {
			setIsGeneratingLoadSummary(isGeneratingLoadSummary.filter(i => i !== index));
		}
	}

	return (
		<div id="pallet-load-list-table">
			<ReactTable
				manual
				minRows={0}
				data={props.records}
				loading={props.isLoading}
				page={props.page}
				pages={props.totalPages}
				pageSizeOptions={props.pageSizeOptions}
				pageSize={props.pageSize}
				onPageChange={props.onPageChange}
				onPageSizeChange={props.onPageSizeChange}
				onSortedChange={props.onSortedChange}
				getTrProps={(state: any, rowInfo?: RowInfo) => {
					if (!rowInfo)
						return {};
	
					const load = rowInfo.original as IPalletLoadListItem;
					let colorClass = 'in-progress';
	
					if (load.loadCancelledOn)
						colorClass = 'cancelled';
					else if (load.loadFinishedOn)
						colorClass = 'completed';
	
					return { className: colorClass };
				}}
				getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
					// Disable row selection for the Downloads column
					if (!rowInfo || column?.id === 'loadAuthorisation' || column?.id === 'loadSummary')
						return {};
	
					const load = rowInfo.original as IPalletLoadListItem;
					return {
						onClick: () => props.onSelectRow && props.onSelectRow(load.id, rowInfo.index)
					}
				}}
				columns={[
					{
						Header: 'Date',
						accessor: 'loadStartedOn',
						width: 70,
						Cell: row => moment.utc(row.value).local().format('DD/MM/YY'),
					},
					{
						Header: 'DoW',
						accessor: 'loadStartedOn',
						width: 40,
						Cell: row => moment.utc(row.value).local().format('ddd'),
					},
					{
						Header: 'Depot',
						accessor: 'depotName',
						width: 140
					},
					{
						Header: 'Run Number',
						accessor: 'runNumber',
						width: 120
					},
					{
						Header: 'Dock',
						accessor: 'dockNumber',
						width: 45,
						Cell: row => row.value || '-'
					},
					{
						Header: 'Trailer',
						accessor: 'fleetNumber',
						width: 80,
						Cell: row => row.value || '-'
					},
					{
						Header: 'Submitted By',
						accessor: 'workerName'
					},
					{
						Header: 'Pallets',
						accessor: 'totalPallets',
						width: 60
					},
					{
						Header: 'Start',
						accessor: 'loadStartedOn',
						width: 50,
						Cell: row => moment.utc(row.value).local().format('HH:mm'),
					},
					{
						Header: 'Finish',
						accessor: 'loadFinishedOn',
						width: 50,
						Cell: row => row.value ? moment.utc(row.value).local().format('HH:mm') : '-'
					},
					{
						Header: 'Loading Time',
						id: 'status',
						width: 110,
						accessor: p => p,
						Cell: row => {
							const { loadStartedOn, loadFinishedOn, loadCancelledOn } = row.value as IPalletLoadListItem;
							
							if (loadCancelledOn)
								return "-";
	
							const $loadStartTime = moment(loadStartedOn);
							const $loadFinishTime = moment(loadFinishedOn || Date.now());
							const totalTimeInSec = moment.duration($loadFinishTime.diff($loadStartTime)).asSeconds();
							return DurationParse.toHourMin(totalTimeInSec, 'short');
						}
					},
					{
						Header: 'Device',
						accessor: 'deviceName',
						width: 150,
						Cell: row => row.value || '-'
					},
					{
						Header: 'Load Authorisation',
						accessor: 'id',
						id: 'loadAuthorisation',
						className: 'text-center',
						width: 130,
						Cell: row => {
							const { loadCancelledOn, loadFinishedOn } = row.original as IPalletLoadListItem;
	
							if (loadCancelledOn || !loadFinishedOn)
								return "-";
							
							const isGeneratingFile = isGeneratingLoadAuthorisation.includes(row.index);

							return (
								<Button
									block
									bsSize="xs"
									bsStyle="info"
									disabled={isGeneratingFile}
									onClick={() => getLoadAuthorisation(row.value, row.index)}
								>
									<FontAwesome 
										name={isGeneratingFile ? "spinner" : "download"} 
										spin={isGeneratingFile} 
									/> { isGeneratingFile ? "Generating..." : "Load Authorisation" }
								</Button>
							)
						}
					},
					{
						Header: 'Load Summary',
						accessor: 'id',
						id: 'loadSummary',
						className: 'text-center',
						width: 130,
						Cell: row => {
							const { loadCancelledOn, loadFinishedOn } = row.original as IPalletLoadListItem;
	
							if (loadCancelledOn || !loadFinishedOn)
								return "-";
							
							const isGeneratingFile = isGeneratingLoadSummary.includes(row.index);

							return (
								<Button
									block
									bsSize="xs"
									bsStyle="info"
									disabled={isGeneratingFile}
									onClick={() => getLoadSummary(row.value, row.index)}
								>
									<FontAwesome 
										name={isGeneratingFile ? "spinner" : "download"} 
										spin={isGeneratingFile} 
									/> { isGeneratingFile ? "Generating..." : "Load Summary" }
								</Button>
							)
						}
					},
					{
						Header: 'Status',
						id: 'status',
						accessor: p => p,
						Cell: row => row.value.loadCancelledOn ? (
							<b className="text-danger">
								Cancelled
							</b>
						) : (
							row.value.loadFinishedOn ? (
								<b className="text-success">
									Completed
								</b>
							) : (
								<b className="text-info">
									In Progress
								</b>
							)
						)
					},
				]}
			/>
		</div>
	);
};

export default PalletLoadList;