import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import 'chartjs-plugin-zoom';

const propTypes = {
	data: PropTypes.array,
	postfix: PropTypes.string,
	height: PropTypes.number,
	displayLegend: PropTypes.bool,
	onSelectGraphPoint: PropTypes.func
};

const defaultProps = {
	height: 200,
	displayLegend: true
};

class BarChart extends Component {
	componentDidMount() {
		this.renderGraph();
	}

	componentDidUpdate() {
		this.renderGraph();
	}

	renderGraph = () => {
		const { data, displayLegend, postfix } = this.props;

		if (data) {
			const ctx = this.chartElement.getContext('2d');
			this.chart = new Chart(ctx, {
				type: 'bar',
				data: data,
				options: {
					legend: {
						display: displayLegend || false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								callback: value => (value + (postfix || ''))
							}
						}]
					}
				}
			});
		}
	}

	render() {
		return (
			<canvas 
				ref={ref => this.chartElement = ref} 
				height={this.props.height} 
			/>
		);
	}
}

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;

export default BarChart;