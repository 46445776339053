import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import { ContentBox } from '../../common/uiElements';
import { MainContent } from '../../common/layout';
import WeeklyOdometerDrivenSummary from '../../common/chartsComponents/WeeklyOdometerDrivenSummary';

class ReportOdometers extends Component {
	render() {
		return (
			<MainContent title="Odometers Report">
				<Row>
					<Col md={6}>
						<ContentBox bordered color="success" icon="road"  title="Odometer Daily by Week - NSW">
							<WeeklyOdometerDrivenSummary mostRecentWeekDayNonUtcRef={moment().unix()} stateFilter={['NSW']} totalPreviousWeek={3}/>
						</ContentBox>
					</Col>
					<Col md={6}>
						<ContentBox bordered color="success" icon="road" title="Odometer Daily by Week - QLD">
							<WeeklyOdometerDrivenSummary mostRecentWeekDayNonUtcRef={moment().unix()} stateFilter={['QLD']} totalPreviousWeek={3}/>
						</ContentBox>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<ContentBox bordered color="primary" icon="road" title="Odometer Total per Week (All States)">
							<WeeklyOdometerDrivenSummary mostRecentWeekDayNonUtcRef={moment().unix()} totalPreviousWeek={26} totalPerWeek/>
						</ContentBox>
					</Col>
				</Row>
			</MainContent>
		);
	}
}

export default ReportOdometers;