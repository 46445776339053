import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

const CompaniesListFilter = (props) => {
	const handleChange = e => {
		const id = e.target.id;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		props.onChange && props.onChange({ [id]: value });
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			props.onPressEnter && props.onPressEnter();
		}
	};

	return (
		<Row>
			<Col sm={6}>
				<FormGroup>
					<label>Company Name</label>
					<FormControl id="companyName" value={props.companyName} onChange={handleChange} onKeyPress={handleKeyPress} />
				</FormGroup>
			</Col>
			<Col sm={6}>
				<FormGroup>
					<label>ABN</label>
					<FormControl id="abn" value={props.abn} onChange={handleChange} onKeyPress={handleKeyPress} />
				</FormGroup>
			</Col>
		</Row>
	);
}

CompaniesListFilter.propTypes = {
	companyName: PropTypes.string,
	abn: PropTypes.string,
	onChange: PropTypes.func,
	onPressEnter: PropTypes.func
};

export default CompaniesListFilter;