import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DatePeriodInput, VehicleSelect, VehicleTypes } from '../../../../common/inputs';
import { FieldGroup } from '../../../../common/uiElements';
import { IVehicleBasicInfo } from '../../../../../app/models/Vehicle';
import { VehicleMaintenanceListFilter as VehicleMaintenanceListFilterClass } from '../../../../../app/models/VehicleMaintenance';
import moment, { Moment } from 'moment';


interface IProps extends VehicleMaintenanceListFilterClass {
	onChangeVehicle?: (vehicle?: IVehicleBasicInfo) => void
	onChangeVehicleType?: (vehicleTypes: number[]) => void
	onChangePeriod?: (startTime: Moment, finishTime: Moment) => void;
}

const MaintenanceListFilter: React.FC<IProps> = props => {
	return (
		<Row>
			<Col sm={8} md={8} lg={4}>
				<FieldGroup label="Period">
					<DatePeriodInput 
						showHours
						showDefaultRanges
						showFutureDefaultRanges
						startDate={moment(props.startTimeLocal)}
						endDate={moment(props.finishTimeLocal)}
						onChange={props.onChangePeriod}
					/>
				</FieldGroup>
			</Col>
			<Col sm={4} md={4} lg={2}>
				<FieldGroup label="Fleet Number / REGO">
					<VehicleSelect 
						placeholder="All"
						value={props.fleetNumber || ''}
						onChange={props.onChangeVehicle}
					/>
				</FieldGroup>
			</Col>
			<Col lg={6}>
				<FieldGroup label="Vehicle Type">
					<VehicleTypes
						value={props.vehicleTypeIds || []}
						onChange={props.onChangeVehicleType}
					/>
				</FieldGroup>
			</Col>
		</Row>
	);
}

export default MaintenanceListFilter;