/* eslint-disable no-undef */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from 'react-google-maps';
import Icon from 'react-fontawesome';

import { Row, Col, FormGroup, FormControl, Button, ControlLabel } from 'react-bootstrap';
import { Loader, Form } from '../../../../../common/uiElements';

import { GoogleMapContainer } from '../../../../../common/googleMaps';
import AccessPointMarker from '../AccessPointMarker';
import RiskRating from '../../../../../common/inputs/RiskRating';
import PlaceAccessTypes from '../../../../../common/inputs/PlaceAccessTypes';

export default class AccessPointDetailsForm extends Component {
	static propTypes = {
		isActive: PropTypes.bool, 
		riskRatingTypeId: PropTypes.number, 
		heightRestrictionInMeters: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), 
		lengthRestrictionInPallets: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		comments: PropTypes.string,
		accessPointAddress: PropTypes.string,
		accessPointLat: PropTypes.number, 
		accessPointLng: PropTypes.number, 
		storeRoomLat: PropTypes.number, 
		storeRoomLng: PropTypes.number, 
		geofencePolygonArray: PropTypes.array.isRequired,
		geofencePolygonBounds: PropTypes.object.isRequired,
		mudMapPath: PropTypes.string,
		AccessTypesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
		AccessTypesNames: PropTypes.arrayOf(PropTypes.string),

		isDownloadingMudMap: PropTypes.bool,
		currentMudMapPath: PropTypes.string,
		
		/** Refs */
		formRef: PropTypes.func,
		accessPointMarkerRef: PropTypes.func,
		storeRoomMarkerRef: PropTypes.func,
	
		/** Functions */
		onChangeInput: PropTypes.func,
		onChangePlaceAccessTypes: PropTypes.func,
		onChangeMarkerPosition: PropTypes.func,
		onChangeRiskRating: PropTypes.func,
		onChangeMudMapFile: PropTypes.func,
		onDownloadMudMapFile: PropTypes.func,
		onDeleteMudMapFile: PropTypes.func,
		onUndoMudMapFile: PropTypes.func,
		onSubmit: PropTypes.func
	}

	constructor(props) {
		super(props);

		this.state = {
			isDownloadingMudMap: false
		};	

		this.validations = {
			accessPointAddress: 'required',
			riskRatingTypeId: 'required',
			accessTypesIdsToValidate: 'required'
		};
	}

	componentDidMount() {
		const interval = window.setInterval(() => {
			if(this.accessPointMap && this.storeRoomMap) {
				window.clearInterval(interval);
				this.fitMapOnGeofence();
			}
		}, 500);
	}

	fitMapOnGeofence = () => {
		if (!this.props.geofencePolygonBounds)
			return;
			
		const { geofencePolygonBounds, accessPointAddress } = this.props;
		this.accessPointMap.fitBounds(geofencePolygonBounds, 1);
		this.storeRoomMap.fitBounds(geofencePolygonBounds, 1);

		if (!accessPointAddress)
			this.props.onChangeMarkerPosition('accessPoint');
	}

	renderGeofencePolygon = () => {
		const options = {
			fillColor: '#00804d',
			fillOpacity: 0.2,
			strokeColor: '#000000',
			strokeOpacity: 0.9,
			strokeWeight: 1,
		};

		return (
			<Polygon  
				paths={this.props.geofencePolygonArray} 
				options={options}
				zIndex={1}
			/>
		);
	}

	renderMudMapForm = () => {
		return (
			<Row>
				<Col xs={12}>
					<FormGroup>
						<ControlLabel>MUD Map</ControlLabel>
						{
							this.props.mudMapPath ? (
								<div>
									<Button 
										bsStyle="info"
										className="pull-left"
										disabled={this.props.isDownloadingMudMap}
										onClick={this.props.onDownloadMudMapFile}
									>
										{
											this.props.isDownloadingMudMap ? (
												<span><Loader inline white /> Loading...</span>
											) : (
												<span><Icon fixedWidth name="download" /> Download</span>
											)
										}
									</Button>
									<Button 
										onClick={this.props.onDeleteMudMapFile}
										bsStyle="warning"
										className="pull-right"
									>
										Delete / Update
									</Button>
								</div>
							) : (
								<div>
									<FormControl 
										id="mudMapFile" 
										type="file"
										className="pull-left"
										onChange={this.props.onChangeMudMapFile} 
									/>
									{
										this.props.currentMudMapPath && (
											<Button 
												onClick={this.props.onUndoMudMapFile}
												bsStyle="default"
												className="pull-right"
											>
												Undo
											</Button>
										)
									}
								</div>
							)
						}
					</FormGroup>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<Form 
				ref={this.props.formRef}
				validations={this.validations}
				messages={this.validationMessages}
				onSubmit={this.props.onSubmit}
			>
				<Row>
					<Col lg={6}>
						<FormGroup>
							<label>Risk Rating</label>
							<RiskRating 
								value={this.props.riskRatingTypeId}
								onChange={this.props.onChangeRiskRating}
							/>
							<FormControl 
								id="riskRatingTypeId" 
								type="hidden" 
								value={this.props.riskRatingTypeId || ''}
							/>
						</FormGroup>
					</Col>
					<Col lg={3}>
						<FormGroup>
							<label>Height Restriction in Meters</label>
							<FormControl 
								id="heightRestrictionInMeters"
								value={this.props.heightRestrictionInMeters || ''} 
								pattern="\d*" 
								maxLength="4"
								onChange={this.props.onChangeInput} 
							/>
						</FormGroup>
					</Col>
					<Col lg={3}>
						<FormGroup>
							<label>Length Restriction in Meters</label>
							<FormControl 
								id="lengthRestrictionInPallets"
								value={this.props.lengthRestrictionInPallets || ''} 
								pattern="\d*" 
								maxLength="4"
								onChange={this.props.onChangeInput} 
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<label>Delivery Type *</label>
					<PlaceAccessTypes 
						id="placeAccessTypes"
						value={this.props.AccessTypesIds || []}
						onChange={this.props.onChangePlaceAccessTypes} 
					/>
					<FormControl 
						id="accessTypesIdsToValidate" 
						type="hidden" 
						value={this.props.AccessTypesIds && this.props.AccessTypesIds.length > 0 ? 'OK' : ''}
					/>
				</FormGroup>
				<FormGroup>
					<label>Comments</label>
					<FormControl 
						id="comments"
						componentClass="textarea"
						rows={3}
						value={this.props.comments || ''} 
						onChange={this.props.onChangeInput} 
					/>
				</FormGroup>
				{ this.renderMudMapForm() }
			<hr />
			<FormGroup>
				<label>Access Point Address *</label>
				<FormControl 
					id="accessPointAddress"
					value={this.props.accessPointAddress || ''} 
					onChange={this.props.onChangeInput} 
				/>
			</FormGroup>
			<Row>
				<Col lg={6}>
					<label>Access Point Location</label>
					<GoogleMapContainer 
						refs={ref => this.accessPointMap = ref} 
						height={270}
					>
						{ this.renderGeofencePolygon() }
						<AccessPointMarker 
							draggable
							markerRef={this.props.accessPointMarkerRef}
							lat={this.props.accessPointLat}
							lng={this.props.accessPointLng}
							labelText="Access Point"
							isActive={this.props.isActive}
							onDragEnd={() => this.props.onChangeMarkerPosition('accessPoint')}
						/>
					</GoogleMapContainer>
				</Col>
				<Col lg={6}>
					<label>Store Room Location</label>
					<GoogleMapContainer 
						refs={ref => this.storeRoomMap = ref} 
						height={270}
					>
						{ this.renderGeofencePolygon() }
						<Fragment>
							<AccessPointMarker 
								draggable
								markerRef={this.props.storeRoomMarkerRef}
								lat={this.props.storeRoomLat}
								lng={this.props.storeRoomLng}
								labelText="Store Room"
								markerColour="blue"
								isActive={this.props.isActive}
								onDragEnd={() => this.props.onChangeMarkerPosition('storeRoom')}
							/>
						</Fragment>
					</GoogleMapContainer>
				</Col>
			</Row>
		</Form>
		);
	}
}