import React, { Fragment, ChangeEvent, useMemo, useCallback } from 'react';
import TimeField from 'react-simple-timefield';
import { TaskForm as TaskFormClass } from '../../../../app/models/Task';
import { ITaskType } from '../../../../app/models/Task';
import moment from 'moment';
import { Checkbox, CompanySearch, WorkersSelectForTasks } from '../../../common/inputs';
import { FormControl, Row, InputGroup, Col, Well, FormGroup } from 'react-bootstrap';
import { Form, FieldGroup, UserDetailsBox, DriversLicenceCheckCallout, LeaveRequestOverlapAlert, Callout, Tooltip } from '../../../common/uiElements';
import { IWorkerForTaskOrRoster } from '../../../../app/models/Worker';
import { PlacesList, TaskTypesList } from '../../../common/lists';
import { IRunBasicInfo } from '../../../../app/models/Run/Run';
import { getTotalHoursOvertime } from './tasksHelper';

interface IProps {
	taskDetails: TaskFormClass;
	formId?: string;
	isEditMode?: boolean; // disable the fields that can't be changed in the task
	isReadOnly?: boolean;
	isChangeDateEnabled?: boolean;
	isChangePlaceEnabled?: boolean;
	hideTimeFields?: boolean;
	onChangeInput: (id: string, value?: string | boolean) => void;
	onChangeSubContractor: (subContractorAbn?: string) => void;
	onChangeWorker: (worker?: IWorkerForTaskOrRoster) => void;
	onChangeTaskType: (e: any, TaskType?: ITaskType) => void;
	onChangeStartTime: (startTime: string) => void;
	onChangeRun: (run?: IRunBasicInfo) => void;
}
const validations = {
	date: 'required',
	startingDepotId: 'required',
	taskTypeId: 'required',
	startTime: 'required',
	budgetedTime: 'required',
	subContractorAbn: 'required'
}

const RosterTaskForm: React.FC<IProps> = props =>{	
	const handleChangeTaskType = (e: any, text?: string, TaskType?: ITaskType) => {
		props.onChangeTaskType(e, TaskType);
	}

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value, type, checked } = e.target;		
		props.onChangeInput(id, type === 'checkbox' ? checked : value);
	}

	const handleChangeCheckboxDriverNotTracked = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target; 
		props.onChangeInput('isDriverNotTracked', checked);

		// if the checkbox is checked, set the worker to undefined
		if (checked)
			setTimeout(props.onChangeWorker, 150);
	}

	const handleCheckSubContractor = () => {
		const subContractorAbn = props.taskDetails.subContractorAbn === undefined ? '' : undefined;
		props.onChangeSubContractor(subContractorAbn);
	}

	const { Worker, date, startTime, budgetedTime } = props.taskDetails;
	const workerFirstName = Worker?.firstName;
	const rosterStartTimeLocal = Worker?.Roster?.startTimeLocal;
	const rosterBudgetedTime = Worker?.Roster?.budgetedTime;

	const renderPossibleOvertime = useCallback(() => {
		if (!workerFirstName || !rosterStartTimeLocal || !rosterBudgetedTime || !date || !startTime || !budgetedTime)
			return null;

		const startDateTime = date + ' ' + startTime;

		const totalHoursOvertime = getTotalHoursOvertime(rosterStartTimeLocal, rosterBudgetedTime, startDateTime, parseFloat(budgetedTime))
		if (!totalHoursOvertime)
			return null;

		const $workerShiftFinishTime = moment(rosterStartTimeLocal).add(rosterBudgetedTime, 'hours');
		const $taskEstimatedFinishTime = moment(startDateTime).add(budgetedTime, 'hours');
		
		return (
			<Callout color={totalHoursOvertime > 3 ? "danger" : "warning"}  icon="warning" title={`Possible Overtime (${totalHoursOvertime}h)`}>
				<b>{ workerFirstName }</b> is rostered to finish at <b>{$workerShiftFinishTime.format('HH:mm')}</b> but this task is set to finish at <b>{$taskEstimatedFinishTime.format('HH:mm')}</b>, which means <b>{totalHoursOvertime}h overtime</b>. Please check if the employee is available to do any overtime or if their roster is correct in the system.
			</Callout>
		)
	}, [workerFirstName, date, startTime, budgetedTime, rosterBudgetedTime, rosterStartTimeLocal]);

	const basicDataEntered = useMemo(() => (
		props.taskDetails.taskTypeId && props.taskDetails.startingDepotId && props.taskDetails.date && moment(props.taskDetails.date) > moment('2000-01-01')
	), [props.taskDetails.taskTypeId, props.taskDetails.startingDepotId, props.taskDetails.date]);

	const startDateTime = useMemo(() => (
		props.taskDetails.date + ' ' + props.taskDetails.startTime
	), [props.taskDetails.date, props.taskDetails.startTime]);

	return (
		<Form id={props.formId} validations={validations}>
			<Row>
				<FieldGroup label="Date" sm={4} required>
					{
						props.isChangeDateEnabled && !props.isReadOnly ? (
							<FormControl
								id="date"
								type="date"
								value={props.taskDetails.date || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ moment(props.taskDetails.date).format('DD/MM/YYYY') }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Day of Week" sm={4}>
					<p>{ moment(props.taskDetails.date).isValid() ? moment(props.taskDetails.date).format('dddd') : '-' }</p>
				</FieldGroup>
			</Row>
			<FieldGroup label="Task Type" required>
				{
					!props.isEditMode && !props.isReadOnly ? (
						<TaskTypesList 
							id="taskTypeId"
							value={props.taskDetails.taskTypeId}
							onChange={handleChangeTaskType}
						/>
					) : (
						<p>{ props.taskDetails.TaskType?.name || '-' }</p>
					)
				}
			</FieldGroup>
			<FieldGroup label="Starting Depot" required>
				{
					props.isChangePlaceEnabled && !props.isReadOnly ? (
						<PlacesList
							id="startingDepotId"
							type="rosterStartingDepot"
							value={props.taskDetails.startingDepotId || ''}
							onChange={handleChangeInput}
						/>
					) : (
						<p>{ props.taskDetails.startingDepotName }</p>
					)
				}
			</FieldGroup>
			{
				(basicDataEntered || props.isEditMode || props.isReadOnly) && (
					<Fragment>
						{
							!props.hideTimeFields && (
								<Row>
									<FieldGroup label="Start Time" sm={4} required>
										{
											props.isReadOnly ? (
											<p>{ props.taskDetails.isStartTimeAdjustNeeded ? '-' : props.taskDetails.startTime }</p>
											) : (
												<Fragment>
													<TimeField
														width={4}
														value={props.taskDetails.isStartTimeAdjustNeeded || !props.taskDetails.startTime ? '' : props.taskDetails.startTime} 
														input={<FormControl onFocus={(e: any) => e.target.select()} id="startTime" />} 
														onChange={props.onChangeStartTime}
													/>
													{
														props.taskDetails.isStartTimeAdjustNeeded && (
															<Callout text="Start Time needs to be adjusted" />
														)
													}
												</Fragment>
											)
										}
									</FieldGroup>
									<FieldGroup label="Finish Time" sm={4}>
										<p>
											{ moment(startDateTime).isValid() && props.taskDetails.budgetedTime ? moment(startDateTime).add(props.taskDetails.budgetedTime, 'hours').format('HH:mm') : '-' }
										</p>
									</FieldGroup>
									<FieldGroup label="Budgeted Time" sm={4} required>
										{
											props.isReadOnly ? (
											<p>{ props.taskDetails.budgetedTime } h</p>
											) : (
												<InputGroup>
													<FormControl
														id="budgetedTime"
														type="number"
														max={20}
														min={0.25}
														value={props.taskDetails.budgetedTime || ''}
														onChange={handleChangeInput}
													/>
													<InputGroup.Addon>
														HOURS
													</InputGroup.Addon>
												</InputGroup>
											)
										}
									</FieldGroup>
								</Row>
							)
						}
						{
							props.taskDetails.actualStartTimeLocal && (
								<>
									<Well bsSize="small" className="text-bold">
										<Row>
											<FieldGroup label="Actual Start Time" sm={4}>
												<p style={{ lineHeight: '.6' }}>
													<span style={{ fontWeight: 'bold', color: 'green', fontSize: '20px' }}>
														{ moment(props.taskDetails.actualStartTimeLocal).format('HH:mm') }
													</span>
												</p>
											</FieldGroup>
											<FieldGroup label="Actual Finish Time" sm={4}>
												<p style={{ lineHeight: '.6' }}>
													{
														props.taskDetails.actualFinishTimeLocal  ? (
															<span style={{ fontWeight: 'bold', color: 'green', fontSize: '20px' }}>
																{ moment(props.taskDetails.actualFinishTimeLocal).format('HH:mm') }
															</span>
														) : '-'
													}
												</p>
											</FieldGroup>
											<FieldGroup label="Actual Time Spent" sm={4}>
														<p style={{ lineHeight: '.6' }}>
															{
																props.taskDetails.actualFinishTimeLocal ? (
																	<span style={{ fontWeight: 'bold', color: 'green', fontSize: '20px' }}>
																		{ moment.duration(moment(props.taskDetails.actualFinishTimeLocal).diff(moment(props.taskDetails.actualStartTimeLocal))).asHours().toFixed(1) } h
																	</span>
																) : '-'
															}
														</p>
											</FieldGroup>
										</Row>
										<i>* Based on the Pre-Start Check</i>
									</Well>
								</>
							)
						}
						{
							(!props.isReadOnly || props.taskDetails.SubContractor) && (
								<Row>
									<Col xs={12}>
										{
											props.isReadOnly && props.taskDetails.SubContractor ? (
												<FormGroup>
													<label>Sub-Contractor</label>
													<p>{ props.taskDetails.SubContractor.name.replace('SUB - ', '')  }</p>
												</FormGroup>
											) : (
												<FormGroup>
													<Checkbox 
														bold 
														text="Sub-Contractor" 
														checked={props.taskDetails.subContractorAbn !== undefined} 
														onChange={handleCheckSubContractor}
													/>
													{
														props.taskDetails.subContractorAbn !== undefined && (
															<FormGroup>
																<CompanySearch 
																	onlyActive
																	onlySubContractors
																	hideSubFromName
																	id="subContractorAbn"
																	value={props.taskDetails.subContractorAbn}
																	onChange={props.onChangeSubContractor}
																/>
															</FormGroup>
														)
													}
												</FormGroup>
											)
										}
									</Col>
								</Row>
							)
						}
						<FieldGroup label="Assigned Employee">
							{
								props.isReadOnly ? (
									<p>{ props.taskDetails.Worker?.shortName || 'No Assigned Employee' }</p>
								) : (
									props.taskDetails.subContractorAbn === '' ? (
										<Callout color="info" text="Select the Sub-Contractor to proceed"  />
									) : (
										<>
											{
												!props.taskDetails.isDriverNotTracked && (
													<WorkersSelectForTasks 
														showLicenceClass
														id="workerId"
														containerId="workerId_container"
														value={props.taskDetails.workerId}
														availabilityDate={props.taskDetails.date}
														rosterDate={props.taskDetails.date}
														companyAbn={props.taskDetails.subContractorAbn}
														onChange={props.onChangeWorker}
													/>
												)
											}
											{
												props.taskDetails.subContractorAbn !== undefined && (
													<>
														<br/>
														<Checkbox
															id="isDriverNotTracked"
															text="Driver Not Tracked by Micway" 
															checked={props.taskDetails.isDriverNotTracked} 
															onChange={handleChangeCheckboxDriverNotTracked}
														>
															&nbsp;
															<Tooltip 
																text="If checked, the 'Driver' will not be tracked by Micway for this task and it won't be marked as 'No Driver Assigned' in the system and reports."
															/>
														</Checkbox>
													</>
												)
											}
										</>
									)
								)
							}
						</FieldGroup>
						{
							props.taskDetails.Worker && (
								<Fragment>
									<UserDetailsBox azureId={props.taskDetails.workerId} workerData={props.taskDetails.Worker} />
									{
										!props.taskDetails.actualFinishTimeLocal && (
											<>
												{ props.taskDetails.date && <LeaveRequestOverlapAlert date={props.taskDetails.date} Worker={props.taskDetails.Worker} /> }
												{
													props.taskDetails.date && props.taskDetails.startTime && props.taskDetails.Worker?.Roster?.startTimeLocal && moment(props.taskDetails.Worker?.Roster?.startTimeLocal) > moment(props.taskDetails.date + ' ' + props.taskDetails.startTime) && (
														<Callout icon="warning" title="Roster Start Time Issue">
															{ props.taskDetails.Worker.firstName } is rostered to start at <b>{moment(props.taskDetails.Worker?.Roster?.startTimeLocal).format('HH:mm')}</b> but this task starts at <b>{props.taskDetails.startTime}</b>. The system will automatically change his roster start time to <b>{props.taskDetails.startTime}</b>.
														</Callout>
													)
												}
												<DriversLicenceCheckCallout 
													worker={props.taskDetails.Worker}
													taskType={props.taskDetails.TaskType}
													mainVehicle={props.taskDetails.MainVehicle}
													trailer1={props.taskDetails.Trailer1}
													trailer2={props.taskDetails.Trailer2}
												/>
											</>
										)
									}
									{ renderPossibleOvertime() }
								</Fragment>
							)
						}
					</Fragment>
				)
			}
		</Form>
	);
}

export default RosterTaskForm;