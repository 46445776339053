import React from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router';
import Icon from 'react-fontawesome';

import { MainContent } from '../../../common/layout';
import CompaniesListContainer from './list/CompaniesListContainer';

const CompaniesContainer = () => (
	<MainContent title="Companies">
		<Row>
			<Col xs={12}>
				<FormGroup>
					<Link to="/management/companies/new" className="btn btn-block btn-success" >
						<Icon name="plus" /> Add new Company
					</Link>
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col xs={12}>
				<CompaniesListContainer />
			</Col>
		</Row>
	</MainContent>
);

export default CompaniesContainer;