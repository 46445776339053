import React from 'react';

import { MainContent } from '../../../common/layout';
import FaultReportDetailsContainer from './subComponents/FaultReportDetailsContainer';

const FaultReportContainer = props => {
    return (
        <MainContent title="Fault Report Details">
            <FaultReportDetailsContainer faultReportId={props.params ? props.params.faultReportId : ""} />
        </MainContent>
    );
}

export default FaultReportContainer;