import React from 'react';
import { Row, FormControl } from 'react-bootstrap';
import { DatePeriodInput, RadioButtonList, UserSearch, VehicleSelect } from '../../../../common/inputs';
import { FieldGroup } from '../../../../common/uiElements';
import { PalletsListFilter as PalletLoadListFilterClass } from '../../../../../app/models/Pallet';
import { IVehicleBasicInfo, VehicleTypes } from '../../../../../app/models/Vehicle';
import moment, { Moment } from 'moment';
import { IListItem, IReactSelectProps } from '../../../../../app/models/Application';
import { PlacesListNew } from '../../../../common/lists';

interface IProps extends PalletLoadListFilterClass {
	onChangePeriod: (startTimeLocal: string, finishTimeLocal: string) => void;
	onChangeLoader: (loader?: IListItem) => void;
	onChangeFleetNumber: (vehicle?: IVehicleBasicInfo) => void;
	onChangeRunNumber: (runNumber?: string) => void;
	onChangeStatus: (status?: string) => void;
	onChangeDepot: (depotId?: string) => void;
	onPressEnter: () => void;
}

const PalletUnloadListFilter: React.FC<IProps> = props => {	
	const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeStatus(e.target.value)
	};

	const handleChangePeriod = (pStartTimeLocal: Moment, pFinishTimeLocal: Moment) => {
		const startTimeLocal = pStartTimeLocal.format('YYYY-MM-DD HH:mm');
		const endTimeLocal = pFinishTimeLocal.format('YYYY-MM-DD HH:mm');
		props.onChangePeriod(startTimeLocal, endTimeLocal);
	};

	const handleChangeRunNumber = (e: React.ChangeEvent<FormControl & HTMLInputElement>) => {
		props.onChangeRunNumber(e.target.value);
	};

	const handleChangeDepot = (depot?: IReactSelectProps) => {
		props.onChangeDepot(depot?.value);
	};

	return (
		<Row>
			<FieldGroup label="Period" sm={6} md={4} lg={3}>
				<DatePeriodInput 
					startDate={moment(props.startTimeLocal)}
					endDate={moment(props.finishTimeLocal)}
					showDefaultRanges={true} 
					showHours={true}
					onChange={handleChangePeriod}
				/>
			</FieldGroup>
			<FieldGroup label="Depot" sm={6} md={4} lg={3}>
				<PlacesListNew
					type="rosterStartingDepot"
					placeholder="Search Depot..."
					value={props.depotId || ''}
					onChange={handleChangeDepot}
				/>
			</FieldGroup>
			<FieldGroup label="Loader" sm={6} md={4} lg={3}>
				<UserSearch
					id="workerId"
					value={props.workerId || ''}
					onChange={props.onChangeLoader}
				/>
			</FieldGroup>
			<FieldGroup label="Fleet Number" sm={6} md={4} lg={3}>
				<VehicleSelect 
					id="fleetNumber"
					value={props.fleetNumber || ''}
					vehTypeFilter={[VehicleTypes.RIGID, VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE ]}
					onChange={props.onChangeFleetNumber}
				/>
			</FieldGroup>
			<FieldGroup label="Run Number" sm={6} md={4} lg={3}>
				<FormControl
					id="runNumber"
					value={props.runNumber || ''}
					onChange={handleChangeRunNumber}
					onKeyPress={e => e.key === 'Enter' && props.onPressEnter()}
				/>
			</FieldGroup>
			<FieldGroup label="Status" sm={12} md={8} lg={6}>
				<RadioButtonList 
					value={props.status}
					items={[
						{ text: 'All', value: '' },
						{ text: 'In Progress', value: 'INPROGRESS' },
						{ text: 'Completed', value: 'FINISHED' },
						{ text: 'Cancelled', value: 'CANCELLED' },
					]}
					onChange={handleChangeStatus}
				/>
			</FieldGroup>
		</Row>
	);
}

export default PalletUnloadListFilter;