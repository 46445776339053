import React from 'react';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import { IFormField } from '../../../../../app/models/Application';
import { IPalletStore, PalletUnloadShuttleDetailForm as PalletUnloadDetailFormClass } from '../../../../../app/models/Pallet';
import { FieldGroup, Form } from '../../../../common/uiElements';
import { UserSearch } from '../../../../common/inputs';
import { DropDownList } from '../../../../common/inputs/DropDownList';
import { PlacesListNew } from '../../../../common/lists';
import { commodityList, palletTypeList } from '../../PalletLoad/dataForLists';

interface IProps {
	pallet: PalletUnloadDetailFormClass;
	isReadOnly?: boolean;
	onChangeInput: (id: string, value?: string) => void;
	onChangeLoader: (loader: {id: string, name: string}) => void;
	onChangeStore: (store: IPalletStore) => void;
}

const PalletDetailsForm: React.FC<IProps> = props => {
	const { pallet } = props;

	const detailFields: IFormField[] = [
		/** ID  */
		{ 
			id: 'id',
			label: 'ID', 
			sm: 4,
			readOnlyValue: pallet.palletId
		},

		/** Loaded By */
		{
			id: 'scannedBy',
			label: 'Unloaded By',
			sm: 4,
			readOnlyValue: pallet.scannedBy?.name || '-',
			inputComponent: <UserSearch id="workerId" value={pallet.scannedBy.id} onChange={props.onChangeLoader} />
		},

		/** Loaded At */
		{
			id: 'scannedAt',
			label: 'Unloaded At',
			sm: 4,
			readOnlyValue: moment(pallet.scannedAt).format('HH:mm')
		},
		
		/** Store */
		{
			id: 'store',
			label: 'Store',
			required: true,
			sm: 12,
			readOnlyValue: pallet.store?.storeNumber ? pallet.store.storeNumber + ' - ' + pallet.store.name : '-',
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<PlacesListNew
					required
					id="store"
					type="deliveryStores"
					placeholder={pallet.store?.storeNumber ? `${pallet.store.name} (${pallet.store.storeNumber})` : "--- STORE ---"}
					value={ pallet.store?.id && pallet.store?.id !== -1 ? pallet.store.id.toString() : '' }
					onChange={(place: any) => {
						props.onChangeStore && props.onChangeStore({
							id: place?.value ? parseInt(place.value) : -1,
							name: (place.fullObject as any)?.name || '',
							storeNumber: (place.fullObject as any)?.storeNumber || '',
						});
					}}
				/>
			)
		},

		/** Type */
		{
			id: 'type',
			label: 'Type',
			required: true,
			sm: 4,
			readOnlyValue: pallet.palletType,
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<DropDownList
					required
					id={`palletType_` + pallet.palletId}
					placeholder="--- PALLET TYPE ---"
					value={ pallet.palletType }
					data={ palletTypeList }
					onChange={(palletType: string) => props.onChangeInput('palletType', palletType)}
				/>
			)
		},

		/** Commodity */
		{
			id: 'commodity',
			label: 'Commodity',
			required: true,
			sm: 4,
			readOnlyValue: pallet.commodity,
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<DropDownList
					required
					id={`commodity_` + pallet.palletId}
					placeholder="--- COMMODITY ---"
					value={ pallet.commodity }
					data={ commodityList }
					onChange={(commodity: string) => props.onChangeInput('commodity', commodity)}
				/>
			)
		},
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return null;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	const validations = detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {});
	return (	
		<Form id="pallet-details-form" validations={validations}>
			<Row>
				{ renderFields(detailFields) }
			</Row>
		</Form>
	)
}

export default PalletDetailsForm;