import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from "../uiElements/ProgressBar";
import StatusTypes from '../../../app/schemas/signalr/StatusTypes';
import FontAwesome from 'react-fontawesome';
import packageInfo from '../../../../package.json';
import { Button } from 'react-bootstrap';

interface IProps {
	signalrStatus: string;
	latestUiVersion: string;
}

const StatusBars: React.FC<IProps> = props => {
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	useEffect(() => {
		// Add listeners for online and offline events on component mount
		window.addEventListener('online', () => setIsOnline(true));
		window.addEventListener('offline', () => setIsOnline(false));
		
		// Component Unmount, remove listeners
		return () => {
			window.removeEventListener('online', () => setIsOnline(true));
			window.removeEventListener('offline', () => setIsOnline(false));
		}
	}, []);
	
	const renderContent = useCallback(() => {
		return (
			<>
				{
					/* Connection Status Bar */
					!isOnline ? (
						<div title="Connection Status Bar" className="bg-red connection-bar">
							<FontAwesome name='warning'/> You are currently offline
						</div>
					) : (
						<>
							{
								// Show status bar if there is a new UI version available but only in production
								process.env.REACT_APP_ENVIRONMENT === "production" && props.latestUiVersion !== packageInfo.version && (
									<div title="New Version Available" style={{fontSize: 20, fontWeight: 500}} className="bg-yellow connection-bar">
										<FontAwesome name='warning'/>&nbsp; A new version is available! &nbsp; <Button bsSize='sm' bsStyle="success" onClick={() => window.location.reload()}>Reload & Update Now</Button>
									</div>
								)
							}
							{
								/* SignalR Status Bar */
								(props.signalrStatus === StatusTypes.FAILED || props.signalrStatus === StatusTypes.RECONNECTING) && (
									<ProgressBar 
										noMargin
										title="Reconnecting to the server..." 
										color={props.signalrStatus === StatusTypes.FAILED ? "danger" : "warning"} 
										height={6} 
									/>
								)
							}
						</>
					)
				}
			</>
		)
	}, [isOnline, props.latestUiVersion, props.signalrStatus]);

	return (
		<div style={{ position: 'sticky', top: 50, left: 0, width: '100%', zIndex: 1 }}>
			{ renderContent() }
		</div>
	)
}

export { StatusBars }