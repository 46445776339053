import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Checkbox, DatePeriodInput, VehicleSearch, UserSearch, RadioButtonList } from '../../../../common/inputs';
import { NonConformanceTypesList } from '../../../../common/lists';

const propTypes = {
	startTimeLocal: PropTypes.string,
	endTimeLocal: PropTypes.string,
	reportedById: PropTypes.string,
	actionTakenById: PropTypes.string,
	involvedFleetNumber: PropTypes.string,
	nonConformanceTypeId: PropTypes.string,
	isOpen: PropTypes.bool,
	isClosed: PropTypes.bool,
	onChange: PropTypes.func
};

const NonConformanceListFilter = props => {
	const handleChange = (e) => {
		const { id, type, checked, value } = e.target;
		var val = type === 'checkbox' ? checked : value;
		props.onChange && props.onChange({ [id]: val });
	};

	const handleChangeVehicle = ({ vehFleetNumber }) => {
		props.onChange && props.onChange({ involvedFleetNumber: vehFleetNumber || '' });
	};

	const handleChangeUser = (fieldId, user) => {
		props.onChange && props.onChange({ [fieldId]: user.value });
	};

	const handleChangePeriod = (startTimeLocal, endTimeLocal) => {
		startTimeLocal = startTimeLocal.format('YYYY-MM-DD HH:mm');
		endTimeLocal = endTimeLocal.format('YYYY-MM-DD HH:mm');
		props.onChange && props.onChange({ startTimeLocal, endTimeLocal });
	};

	return (
		<div>
			<Row>
				<Col sm={6} md={4}>
					<FormGroup>
						<RadioButtonList 
							id="filterBy"
							value={props.filterBy}
							items={[{text: 'All', value: 'all'}, {text: 'By Report Date', value: 'reportDate'}]} 
							onChange={handleChange} 
						/>
						<div className={props.filterBy !== 'reportDate' ? 'hidden' : undefined} style={{marginTop: '-18px'}}>
							<DatePeriodInput 
								showDefaultRanges
								format="DD/MM/YYYY"
								startDate={moment(props.startTimeLocal)}
								endDate={moment(props.endTimeLocal)}
								onChange={handleChangePeriod}
							/>
						</div>
					</FormGroup>
				</Col>
				<Col sm={6} md={4}>
					<FormGroup>
						<label>Reported By</label>
						<UserSearch 
							id="reportedById" 
							value={props.reportedById} 
							onlyActive={false}
							onChange={user => handleChangeUser('reportedById', user)}
						/>
					</FormGroup>
				</Col>
				<Col sm={6} md={4}>
					<FormGroup>
						<label>Action Taken By</label>
						<UserSearch 
							id="actionTakenById" 
							value={props.actionTakenById} 
							onlyActive={false}
							onChange={user => handleChangeUser('actionTakenById', user)}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm={6} md={2}>
					<FormGroup>
						<label>Reference #</label>
						<FormControl 
							id="id" 
							value={props.id} 
							onChange={handleChange}
						/>
					</FormGroup>
				</Col>
				<Col sm={6} md={3}>
					<FormGroup>
						<label>Vehicle</label>
						<VehicleSearch 
							id="involvedFleetNumber" 
							value={props.involvedFleetNumber}
							onChange={handleChangeVehicle}
						/>
					</FormGroup>
				</Col>
				<Col sm={6} md={3}>
					<label>Type</label>
					<NonConformanceTypesList 
						id="nonConformanceTypeId"
						value={props.nonConformanceTypeId}
						onChange={handleChange}
					/>
				</Col>	
				<Col sm={6} md={4}>
					<label>Status</label>
					<ul className="list-inline">
						<li>
							<Checkbox 
								id="isOpen"
								text="Open"
								checked={props.isOpen}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="isClosed"
								text="Closed"
								checked={props.isClosed}
								onChange={handleChange}
							/>
						</li>
					</ul>
				</Col>
			</Row>
		</div>
	);
};

NonConformanceListFilter.propTypes = propTypes;

export default NonConformanceListFilter;