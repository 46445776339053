import React, { Component } from 'react';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { MainContent } from '../../../common/layout';
import './style.css';

import WorkerTimeSheet from './WorkerTimeSheet';
import WorkerTimeSheetFilters from './WorkerTimeSheetFilters';
import WorkerTimeSheetList from './WorkerTimeSheetList';

import PayrollApi from '../../../../app/api/PayrollApi';
import { Callout, ContentBox, ErrorBox, Loader } from '../../../common/uiElements';

class WorkerTimeSheetContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: {
				workerId: '',
				startTime: moment().add(-1, 'days').format('YYYY-MM-DD'),
				finishTime: moment().add(-1, 'days').format('YYYY-MM-DD'),
				minimumActualWorkedHours: 0,
				isChecked: '',
				isHidden: '',
				hasEmployeeAddedFinishTime: '',
				hasHRAddedDataManually: ''
			},
			dataList: null,
			selectedTimeSheet: null,
			selectedTimeSheetHasFinishTime: null,
			isLoading: false,
			isSaving: false,
			error: null,
			errorSaving: null
		};
	}   

	componentWillUnmount() {
		this.unmounted = true;
	}

	_handleChangeFilter = filter => {
		this.setState({
			filter: {
				...this.state.filter,
				...filter
			}
		});
	}

	_handleEditData = timeSheetData => {
		this.setState({
			selectedTimeSheet: {
				...timeSheetData,
				selectedTimeSheetHasFinishTime: timeSheetData.finishTimeLocal ? true : false
			}
		});
	}

	_handleChange = e => {
		const { id, value } = e.target;
		this.setState({
			selectedTimeSheet: {
				...this.state.selectedTimeSheet,
				[id]: value
			}
		});   
	}

	_handleChangeWorker = user => {
		this.setState({
			selectedTimeSheet: {
				...this.state.selectedTimeSheet,
				workerId: user.value
			}
		});
	}

	_handleAddNew = () => {
		this.setState({
			selectedTimeSheet: {
				workerId: '',
				startTimeZoneNameIana: 'Australia/Sydney',
				finishTimeZoneNameIana: 'Australia/Sydney',
				startTimeLocal: '',
				finishTimeLocal: '',
				adjustedStartTimeLocal: '',
				adjustedFinishTimeLocal: '',
				isChecked: true,
				isHidden: false,
				hasHRAddedDataManually: true,
				comments: null,
				absenceReasonId: ''
			}
		});
	}

	_handleCloseModal = () => {
		this.setState({ selectedTimeSheet: null, selectedTimeSheetHasFinishTime: null });
	}

	_handleSearch = () => {
		this.setState({ isLoading: true, error: null });
		let { 
			workerId, startTime, finishTime, isChecked, isHidden, 
			hasEmployeeAddedFinishTime, hasHRAddedDataManually, minimumActualWorkedHours 
		} = this.state.filter;
		
		PayrollApi.getWorkerTimeSheetList(workerId, startTime, finishTime, minimumActualWorkedHours, isChecked, isHidden, hasEmployeeAddedFinishTime, hasHRAddedDataManually)
			.then(dataList => {
				if (this.unmounted) return;

				this.setState({ 
					dataList,
					isLoading: false 
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}


	_handleSave = () => {
		if (!window.$(this.formWorkerTimeSheet).find('form').valid())
			return;
		
		this.setState({ isSaving: true, errorSaving: null });
		const { selectedTimeSheet } = this.state;
		selectedTimeSheet.isChecked = true;
		
		let promise;
		if (selectedTimeSheet.id) 
			promise = PayrollApi.updateWorkerTimeSheet(selectedTimeSheet.id, selectedTimeSheet);
		else {
			selectedTimeSheet.hasHRAddedDataManually = true;
			promise = PayrollApi.saveNewWorkerTimeSheet(selectedTimeSheet);
		}

		promise.then(() => {
			toastr.success('The data was successfully saved', 'Success!');
			if (this.unmounted) return;
			
			const { dataList } = this.state;
			if (dataList) {
				const index = dataList.findIndex(d => d.id === selectedTimeSheet.id);
				if (index !== -1) 
					dataList[index] = { ...selectedTimeSheet };
			}

			this.setState({ 
				dataList,
				selectedTimeSheet: null,
				isSaving: false
			});
		})
			.catch(error => {
				console.error(error);
				this.setState({ isSaving: false, errorSaving: error });
			});
	}

	_handleChangeStatus = (selectedTimeSheet, e) => {
		const { id, checked } = e.target;
		selectedTimeSheet[id] = checked;

		// Force update the row color instantly
		const { dataList } = this.state;
		const index = dataList.findIndex(d => d.id === selectedTimeSheet.id);
		if (index !== -1) {
			dataList[index][id] = checked;
			this.forceUpdate();
		}

		PayrollApi.updateWorkerTimeSheet(selectedTimeSheet.id, selectedTimeSheet)
			.catch(error => {
				console.error(error);

				const { dataList } = this.state;
				const index = dataList.findIndex(d => d.id === selectedTimeSheet.id);
				if (index !== -1) {
					dataList[index][id] = !checked;
					this.forceUpdate();
				}
			});
	}
	
	render() {
		const s = this.state;

		return (
			<MainContent title="Bundy Clock - Data Management">
				<div className="worker-time-sheet-container">
					<Button block bsStyle="success" onClick={this._handleAddNew}>
						<Icon name="plus" /> Add New
					</Button>
					<br />
					<ContentBox color="primary" title="Filters">
						<WorkerTimeSheetFilters 
							{ ...s.filter }
							onChange={this._handleChangeFilter}
							onPressEnter={this._handleSearch}  />
						<hr />
						<Button 
							bsStyle="primary"
							disabled={s.isLoading}
							block onClick={this._handleSearch}
						>
							Search <Icon name="search"/>
						</Button>
					</ContentBox>
						
					{
						s.error ? (
							<ErrorBox error={s.error} retryFunc={this._handleSearch} />
						) : (
							s.isLoading ? (
								<Loader text="Loading data..." />
							) : (
								s.dataList && (
									s.dataList.length > 0 ? (
										<WorkerTimeSheetList
											pageSize={100}
											data={s.dataList}
											onClickEdit={this._handleEditData}
											onChangeStatus={this._handleChangeStatus}
										/>
									) : (
										<Callout title="No data to show" color="info">
														Change the filters and try again.
										</Callout>
									)
								)
							)
						)
					}
				</div>
				{
					s.selectedTimeSheet && (
						<Modal show={true} onHide={this._handleCloseModal}>
							<Modal.Header closeButton={!s.isSaving}>
								<Modal.Title>Time Sheet</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div ref={ref => this.formWorkerTimeSheet = ref}>
									{
										s.errorSaving && (
											<ErrorBox error={s.errorSaving} retryFunc={this._handleSearch}/>
										)
									}
									{
										s.isSaving ? (
											<Loader text="Saving the data. Please wait..." />
										) : (
											<WorkerTimeSheet 
												data={s.selectedTimeSheet}
												hasFinishTime={s.selectedTimeSheetHasFinishTime}
												onChange={this._handleChange}
												onChangeWorker={this._handleChangeWorker}
											/>
										)
									}
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button 
									disabled={s.isSaving}
									className="pull-left" 
									bsStyle="success" 
									onClick={this._handleSave}>
											Save
								</Button>
								<Button 
									disabled={s.isSaving}
									bsStyle="danger" 
									onClick={this._handleCloseModal}>
											Cancel
								</Button>
							</Modal.Footer>
						</Modal>
					)
				}
			</MainContent>
		);
	}
}

export default WorkerTimeSheetContainer;