import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ContentBox } from '../../common/uiElements';
import LineChart from '../../common/charts/LineChart';

const propTypes = {
	breadcrumbs: PropTypes.array,
	responsiveClass: PropTypes.string,
	vehicle: PropTypes.object, 
	onSelectGraphPoint: PropTypes.func,
};

const DoorComponent = ({ breadcrumbs, responsiveClass, vehicle, onSelectGraphPoint }) => {   
	if (!breadcrumbs || !vehicle || !vehicle.vehSetHasDoorSen)
		return <div></div>;

	return (
		<div className={('graph-box ' + (responsiveClass ? responsiveClass : ''))}>
			<ContentBox title="Door" icon="key" color="warning" minimize={true} bordered={true}>
				<LineChart displayLegend={false} data={breadcrumbs} onSelectGraphPoint={onSelectGraphPoint} vehicle={vehicle} chartName="doorOpenedClosed"/>
			</ContentBox>
		</div>
	);
};

DoorComponent.propTypes = propTypes;
  
const mapStateToProps = state => ({
	breadcrumbs: state.map.breadcrumbs,
	vehicle: state.filters.vehicle
});
  
export default connect(mapStateToProps)(DoorComponent);