import React, { Component } from 'react';
import { Select } from '../inputs';
import { ErrorBox } from '../uiElements';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { IError } from '../../../app/models/Application';
import { FormControlProps } from 'react-bootstrap';

interface IState {
	list: object[];
	isLoading: boolean;
	error?: IError;
}

interface IProps extends FormControlProps {
	promise: any;
	onLoadList?: () => void
}

class ListContainer extends Component<IProps, IState> {
	state = {
		isLoading: false,
		error: undefined,
		list: []
	};

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		componentRequestHandler(this, this.props.promise, 'list').then(this.props.onLoadList);
	}
	
	render () {
		const { state, props } = this;
		
		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchData} />;
		
		return <Select {...props as any} isLoading={state.isLoading} data={state.list} />;
	}
}

export default ListContainer;