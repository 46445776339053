import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import { MultiplePicturesInput } from '../../common/inputs';

const propTypes = {
	show: PropTypes.bool,
	vehFleetNumber: PropTypes.string,
	onClose: PropTypes.func,
	data: PropTypes.array
}

class VehicleDamagesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingPicture: false
		}
	}
	
	handleCloseModal = () => {
		if (this.state.isLoadingPicture) return;

		this.props.onClose();
	}

	handleChange = (photos) => {
		return this.props.onChange({
			[this.props.vehFleetNumber]: photos
		})
	}

	render() {
		const p = this.props;
		const s = this.state;

		return (
			<Modal show={p.show} onHide={this.handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Vehicle {p.vehFleetNumber} damages</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<MultiplePicturesInput 
						id="VehicleDamages"
						quality={0.4} 
						maxPictures={4} 
						pictures={p.data || []} 
						onChange={this.handleChange}
						onLoadPicture={(isLoadingPicture) => this.setState({ isLoadingPicture })}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={s.isLoadingPicture} bsStyle="danger" onClick={this.handleCloseModal}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

VehicleDamagesModal.propTypes = propTypes;

export default VehicleDamagesModal;