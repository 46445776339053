import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

const propTypes = {
	faultReportItems: PropTypes.arrayOf(
		PropTypes.shape({
			dtoFaultReportTypeName: PropTypes.string.isRequired,
			reportedVehicleId: PropTypes.string.isRequired,
			reportedDescription: PropTypes.string.isRequired
		})
	),
	onView: PropTypes.func,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	isReadOnly: PropTypes.bool
};

const defaultProps = {
	isReadOnly: false,
	faultReportItems: []
};

const FaultReportList = props => {
	const handleView = (index) => {
		if (props.onView)
			return props.onView(index);
	};

	const handleEdit = (index) => {
		if (props.onEdit)
			return props.onEdit(index);
	};

	const handleDelete = (index) => {
		if (props.onDelete)
			return props.onDelete(index);
	};

	if (props.faultReportItems.length === 0)
		return <p><i>No fault report has been registered.</i></p>;

	return (
		<div>
			{
				props.faultReportItems.map((fault, index) => {
					fault.index = index;
					return (
						<table className="table table-bordered table-condensed" key={index}>
							<tbody>
								<tr>
									<th>{fault.dtoFaultReportTypeName}</th>
									<th width="100">{fault.reportedVehicleId}</th>
									{
										fault.id || props.isReadOnly ? (
											<td className="cursor-pointer text-primary fault-report__view-button" width="45px" onClick={() => { handleView(index); }}>
												<Icon name="search" size="2x" />
											</td>
										) : [
											<td className="fault-report__edit-button" key={0} width="40" onClick={() => { handleEdit(index); }}>
												<Icon name="edit" size="2x" className="edit-btn" />
											</td>,
											<td className="fault-report__delete-button" key={1} width="40" onClick={() => { handleDelete(index); }}>
												<Icon name="trash" size="2x" className="remove-btn" />
											</td>
										]
									}
								</tr>
								<tr>
									{ 
										fault.reportedDescription && <td colSpan="4">{fault.reportedDescription}</td>
									}
								</tr>
							</tbody>
						</table>
					);
				})
			}
		</div>
	);
};

FaultReportList.propTypes = propTypes;
FaultReportList.defaultProps = defaultProps;

export default FaultReportList;