import React from 'react';
import { Select } from '../inputs';

export const AustraliaTimeZoneNamesList = props => (
	<Select 
		data={[
			{ id: 'Australia/Sydney', name: 'Sydney' },
			{ id: 'Australia/Brisbane', name: 'Brisbane' }
		]}
		{ ...props }
	/>
);