import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import moment from 'moment';

const propTypes = {
	id: PropTypes.string,
	value: PropTypes.string,
	errorToShow: PropTypes.string,
	onChange: PropTypes.func
};

class TimeInput extends Component {
	constructor() {
		super();
		this.state = {
			isInvalid: null
		};
	}

	handleBlur = e => {
		const { value } = e.target;
		if (value && value !== '__:__' && !moment(value, 'HH:mm', true).isValid()) {
			e.target.focus();
			return this.setState({ isInvalid: true });
		}
		
		this.setState({ isInvalid: false });
		this.props.onChange && this.props.onChange(e);
	}

	renderErrorMessage = () => {
		if (this.state.isInvalid)
			return <label className="error">Invalid</label>;
		else if (this.props.errorToShow)
			return <label className="error">{ this.props.errorToShow }</label>;

		return null;
	}

	render() {
		const s = this.state;
		return (
			<Fragment>
				<InputMask 
					alwaysShowMask
					mask="99:99"
					{...this.props}
					onBlur={this.handleBlur}
				>
					{ 
						({ id, value, placeholder, bsSize, onChange }) => (
							<FormControl
								id={id}
								value={value}
								placeholder={placeholder}
								bsSize={bsSize}
								type="tel"
								className={s.isInvalid || this.props.errorToShow ? 'error' : ''}
								onChange={onChange}
							/> 
						)
					}
				</InputMask>
				{ this.renderErrorMessage() }
			</Fragment>
		);
	}
}

TimeInput.propTypes = propTypes;

export { TimeInput };
