import React, { Fragment } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { Loader, ErrorBox, Callout } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IError } from '../../../../../app/models/Application';
import VehiclesApi from '../../../../../app/api/VehiclesApi';
import { IVehicleDetails, VehicleDetailsForm as VehicleDetailsFormClass, VehiclePOST, VehiclePUT, VehicleTypes } from '../../../../../app/models/Vehicle';
import VehicleDetailsForm from './VehicleDetailsForm';
import VehicleRegoHistory from './VehicleRegoHistory';
import $ from 'jquery';

interface IProps {
	fleetNumber?: string;
	regoNumber?: string;
	onClose: () => void;
	onCompleteSaving?: (fleetNumber: string) => void;
	onCompleteInactivation?: () => void;
}

interface IState {
	details: VehicleDetailsFormClass;
	regoId?: number;
	initialRegoNumber?: string;
	editMode: boolean;
	selectedTab: number;
	isSaving: boolean;
	isLoading: boolean;
	isInactivating: boolean;
	errorSaving?: IError;
	errorLoading?: IError;
	errorInactivating?: IError;
}

class VehicleDetailsModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			details: { ...new VehicleDetailsFormClass() },
			initialRegoNumber: '',
			editMode: false,
			selectedTab: 1,
			isLoading: false,
			isSaving: false,
			isInactivating: false,
			errorLoading: undefined,
			errorSaving: undefined,
			errorInactivating: undefined
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.fleetNumber !== prevProps.fleetNumber)
			this.fetchDetails();
	}	

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = () => {
		const { fleetNumber } = this.props;
		if (!fleetNumber) return;

		const promise = () => VehiclesApi.getVehicleDetails(fleetNumber);
		componentRequestHandler(this, promise, 'details')
			.then((details: IVehicleDetails) => {
				this.setState({
					editMode: false,
					initialRegoNumber: details.regoNumber,
					details: {
						...new VehicleDetailsFormClass(details),
					}
				});
			});
	}

	save = () => {
		const { fleetNumber, onCompleteSaving } = this.props;
		let promise = () => VehiclesApi.createVehicle(new VehiclePOST(this.state.details));

		if (fleetNumber)
			promise = () => VehiclesApi.updateVehicle(fleetNumber!, new VehiclePUT(this.state.details));

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(() => {
			this.fetchDetails();
			onCompleteSaving && onCompleteSaving(this.state.details.fleetNumber)
		})
	}

	inactivate = () => {
		const { fleetNumber, onCompleteInactivation } = this.props;
		if (!fleetNumber) return;

		const promise = () => VehiclesApi.inactivateVehicle(fleetNumber!);
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isInactivating',
			errorAttrName: 'errorInactivating'
		})
		.then(onCompleteInactivation)
	}

	changeForm = (newData: object) => {
		return new Promise(resolve => {
			this.setState({
				details: {
					...this.state.details,
					...newData
				}
			}, resolve);
		})
	}
	
	// Handles the change of the form fields and updates the rego object if it has been edited
	handleChangeForm = (id: string, value: any) => {
		const { details } = this.state;

		if (id === 'newRegoStartDate' || id === 'oldRegoCancelReason') {
			this.changeForm({
				VehicleRegoNew: {
					...details.VehicleRegoNew,
					regoStateId: details.regoStateId,
					[id]: value
				}
			});
		} else {
			const updatedForm = {
				[id]: value,
			};

			if (id === 'regoNumber') {
				updatedForm['VehicleRegoNew'] = {
					...details.VehicleRegoNew,
					regoStateId: details.regoStateId,
					regoNumber: value
				};
			}

			this.changeForm(updatedForm);
		}
	}

	handleSaveVehicle = () => {
		if ($('#vehicle-form').valid())
			this.save();
	}

	handleToggleEditMode = () => {
		this.setState({ 
			editMode: !this.state.editMode,
			selectedTab: 1
		})
	}

	handleChangeTab = (selectedTab: number) => {
		this.setState({ selectedTab });
	}

	renderBodyContent = () => {
		const { state, props } = this;

		if (state.errorLoading)
			return <ErrorBox error={state.errorLoading} retryFunc={this.fetchDetails} />;

		if (state.isSaving || state.isLoading)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;

		const { details, selectedTab, editMode, errorSaving, errorInactivating } = this.state;

		return (
			<Fragment>
				{ errorSaving && <ErrorBox error={errorSaving} retryFunc={this.save} /> }
				{ errorInactivating && <ErrorBox error={errorInactivating} retryFunc={this.inactivate} /> }
				{ !details.isActive && <Callout icon="warning" title="Inactive" text="This vehicle is inactive and can't be edited" /> }
				<Tabs
					id="vehicle-tabs"
					animation={false}
					activeKey={selectedTab}
					onSelect={this.handleChangeTab as any}
				>
					<Tab eventKey={1} title="Details">
						<VehicleDetailsForm 
							disableVehicleType={props.fleetNumber ? true : false}
							disableFleetNumber={props.fleetNumber ? true : false}
							disableRego={this.state.details.typeId === VehicleTypes.PALLET_JACK.toString()}
							disableWorkingState={props.fleetNumber ? true : false}
							showEditRego={state.editMode && this.state.initialRegoNumber !== details.regoNumber && this.state.details.typeId !== VehicleTypes.PALLET_JACK.toString()}
							isReadOnly={props.fleetNumber && !state.editMode ? true : false}
							{...details}
							onChange={this.handleChangeForm}
						/>
					</Tab>
					{
						!editMode && details?.VehicleRegoHistory && details?.VehicleRegoHistory.length > 1 && (
							<Tab eventKey={2} title="REGO History">
								<VehicleRegoHistory 
									vehicleRegoHistory={details.VehicleRegoHistory}
								/>
							</Tab>
						)
					}
				</Tabs>
			</Fragment>
		)
	}

	render() {
		const { state, props } = this;
		
		return (
			<Modal
				show
				id="vehicle-modal"
				backdrop="static"
				bsSize="large"
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{ props.fleetNumber ? `Vehicle Details` : 'Add New Vehicle' }
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderBodyContent() }
				</Modal.Body>
				{
					!state.isSaving && !state.isLoading && (
						<Modal.Footer>
							{
								(!props.fleetNumber || state.editMode) && (
									<Button 
										bsStyle="success"
										className="pull-left"
										onClick={this.handleSaveVehicle}
									>
										{ props.fleetNumber ? 'Save Changes' : 'Save Vehicle' }
									</Button>
								)
							}
							{
								props.fleetNumber && state.details?.isActive && state.editMode && (
									<Button
										bsStyle="danger"
										onClick={this.inactivate}
									>
										Inactivate Vehicle
									</Button>
								)
							}
							{/* Edit and Add new will be finished in the future */}
							{
								props.fleetNumber && !state.editMode && state.details.isActive && (
									<Button 
										bsStyle="warning"
										className="pull-left"
										onClick={this.handleToggleEditMode}
									>
										Edit Vehicle
									</Button>
								)
							}
							<Button onClick={props.onClose}>
								Close
							</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		)
	}
}

export default VehicleDetailsModal;