import moment from 'moment';

function batteryVoltage(data) {
	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'rgba(13, 66, 132, .4)',
		borderColor: 'rgba(13, 66, 132, .7)',
		label: 'Voltage'
	};

	var datasets = [];
	var dataGraph = [];

	data.forEach(function(obj) {
		var time = moment.unix(obj.trkCollectedOnEpoch);
		var battery = {};
		battery.x = time;
		battery.y = parseInt(obj.trkVehicleBattVolt / 1000, 10);
		dataGraph.push(battery);
	});

	datasets.push({
		...defaultStyle,
		data: dataGraph
	});

	return datasets;
}

function doorOpenedClosed(data) {
	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'transparent',
		borderColor: 'rgba(13, 66, 132, .7)',
		label: 'Door'
	};

	var datasets = [];
	var dataGraph = [];

	data.forEach(function(obj) {
		var time = moment.unix(obj.trkCollectedOnEpoch);
		var door = {};
		door.x = time;
		door.y = (obj.trkIsDoorOpen ? 1 : 0);
		dataGraph.push(door);
	});

	datasets.push({
		...defaultStyle,
		data: dataGraph
	});

	return datasets;
}

function engineOnOff(data) {
	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'transparent',
		borderColor: 'rgba(13, 66, 132, .7)'
	};

	var datasets = [];
	var dataGraph = [];

	data.forEach(function(obj) {
		var time = moment.unix(obj.trkCollectedOnEpoch);
		var engine = {};
		engine.x = time;
		engine.y = (obj.trkIsEngineOn ? 1 : 0);
		dataGraph.push(engine);
	});

	datasets.push({
		...defaultStyle,
		data: dataGraph
	});

	return datasets;
}

function freezerOnOff(data) {
	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'transparent',
		borderColor: 'rgba(13, 66, 132, .7)'
	};

	var datasets = [];
	var dataGraph = [];

	data.forEach(function(obj) {
		var time = moment.unix(obj.trkCollectedOnEpoch);
		var freezer = {};
		freezer.x = time;
		freezer.y = (obj.trkIsFreezer ? 1 : 0);
		dataGraph.push(freezer);
	});

	datasets.push({
		...defaultStyle,
		data: dataGraph
	});

	return datasets;
}

function speed(data) {
	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'rgba(13, 66, 132, .4)',
		borderColor: 'rgba(13, 66, 132, .7)'
	};

	var datasets = [];
	var dataGraph = [];

	data.forEach(function(obj) {
		var time = moment.unix(obj.trkCollectedOnEpoch);
		var speed = {};
		speed.x = time;
		speed.y = parseInt(obj.trkSpeedKm, 10);
		dataGraph.push(speed);
	});

	datasets.push({
		...defaultStyle,
		data: dataGraph
	});

	return datasets;
}

function zonesTemperatures(data, vehicle) {
	var datasets = [];
	var dataZone1 = [];
	var dataZone2 = [];
	var dataZone3 = [];

	var defaultStyle = {
		lineTension: 0,
		pointRadius: 0.5,
		backgroundColor: 'transparent'
	};

	var styleZone1 = { borderColor: 'rgba(13, 66, 132, .7)', label: 'Zone 1' };
	var styleZone2 = { borderColor: 'rgba(19, 132, 13, .7)', label: 'Zone 2' };
	var styleZone3 = { borderColor: 'rgba(220, 46, 25, .7)', label: 'Zone 3' };

	data.forEach(function(obj){
		var time = moment.unix(obj.trkCollectedOnEpoch);
		if (vehicle.vehSetHasTempZone1Sen) {
			var zone1 = {};
			zone1.x = time;
			zone1.y = obj.trkTempZone1;
			dataZone1.push(zone1);
		}

		if (vehicle.vehSetHasTempZone2Sen) {
			var zone2 = {};
			zone2.x = time;
			zone2.y = obj.trkTempZone2;
			dataZone2.push(zone2);
		}

		if (vehicle.vehSetHasTempZone3Sen) {
			var zone3 = {};
			zone3.x = time;
			zone3.y = obj.trkTempZone3;
			dataZone3.push(zone3);
		}
	});

	if (vehicle.vehSetHasTempZone1Sen) {
		datasets.push({
			...defaultStyle,
			...styleZone1,
			data: dataZone1
		});
	}

	if (vehicle.vehSetHasTempZone2Sen) {
		datasets.push({
			...defaultStyle,
			...styleZone2,
			data: dataZone2
		});
	}

	if (vehicle.vehSetHasTempZone3Sen) {
		datasets.push({
			...defaultStyle,
			...styleZone3,
			data: dataZone3
		});
	}

	return datasets;
}

var GraphData = {
	render: (type, data, vehicle) => {
		switch (type) {
		case 'batteryVoltage':
			return batteryVoltage(data);
		case 'doorOpenedClosed':
			return doorOpenedClosed(data);
		case 'engineOnOff':
			return engineOnOff(data);
		case 'freezerOnOff':
			return freezerOnOff(data);
		case 'speed':
			return speed(data);
		case 'zonesTemperatures':
			return zonesTemperatures(data, vehicle);
		default:
			return defaultGraph(data);
		}
	}
};

function defaultGraph(data) {
	var datasets = [];
	var dataGraph = [];
	
	data.forEach(function(obj) {
		var dataItem = {};
		dataItem.x = obj.x;
		dataItem.y = obj.y;
		dataGraph.push(dataItem);
	});

	datasets.push({
		data: dataGraph
	});

	return datasets;
}

export default GraphData;