import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup } from 'react-bootstrap';

import { UserDetailsLink } from '../../../../common/uiElements';

const propTypes = {
    reportedOnLocal: PropTypes.string.isRequired,
    WorkerReportedBy: PropTypes.shape({
        shortName: PropTypes.string.isRequired
    }).isRequired,
    FaultReportHistories: PropTypes.arrayOf(
        PropTypes.shape({
            status: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]).isRequired,
            actionOn: PropTypes.string,
            closedOffOn: PropTypes.string,
            WorkerActionBy: PropTypes.shape({
                shortName: PropTypes.string.isRequired
            }),
            WorkerClosedOffBy: PropTypes.shape({
                shortName: PropTypes.string.isRequired
            })
        })
    )
};

const FaultReportDetails = props => {
    const lastStatus = props.FaultReportHistories ? props.FaultReportHistories[props.FaultReportHistories.length - 1] : null;
    const isClosed = lastStatus && lastStatus.closedOffOn;

    return (
        <Row>
            <Col xs={12} sm={4}>
                <FormGroup>
                    <label>Reported By</label>
                        <p>
                            {
                                props.WorkerReportedBy && props.reportedOnLocal ? (
                                    <span>
                                        <UserDetailsLink azureId={props.WorkerReportedBy.azureId}>
                                            { props.WorkerReportedBy.shortName }
                                        </UserDetailsLink>&nbsp;
                                        on { moment(props.reportedOnLocal).format('DD/MM/YYYY HH:mm') }
                                    </span>
                                ) : '-'
                            }
                        </p>
                </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
                <FormGroup>
                    <label>Repaired By</label>
                    <p>
                        {
                            isClosed ? (
                                <span>
												{
													lastStatus.actionById ? (
														<UserDetailsLink azureId={lastStatus.WorkerActionBy.azureId}>
															{ lastStatus.WorkerActionBy.shortName }
														</UserDetailsLink>
													) : (
														<b>{ lastStatus.actionByName }</b>
													)
												}
                                    &nbsp;
                                    on { moment(lastStatus.actionOn).format('DD/MM/YYYY') }
                                </span>
                            ) : '-'
                        }
                    </p>
                </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
                <FormGroup>
                    <label>Closed Off By</label>
                    <p>
                        {
                            isClosed ? (
                                <span>
                                    <UserDetailsLink azureId={lastStatus.WorkerClosedOffBy.azureId}>
                                        { lastStatus.WorkerClosedOffBy.shortName }
                                    </UserDetailsLink>&nbsp;
                                    on { moment(lastStatus.closedOffOn).format('DD/MM/YYYY') }
                                </span>
                            ) : '-'
                        }
                    </p>
                </FormGroup>
            </Col>
        </Row>
    );
};

FaultReportDetails.propTypes = propTypes;

export default FaultReportDetails;