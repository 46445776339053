import React from 'react';
import PropTypes from 'prop-types';

import { MainContent } from '../../../common/layout';
import CompanyView from './CompanyView';

const CompanyViewContainer = props => (
    <MainContent title="Company Details">
        <CompanyView showBackButton abn={props.params.abn} />
    </MainContent>
);

CompanyViewContainer.propTypes = {
    params: PropTypes.shape({
        abn: PropTypes.string.isRequired
    }).isRequired
};

export default CompanyViewContainer;