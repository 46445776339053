import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';

import AppReducer from './app';
import FiltersReducer from './filter';
import MapReducer from './map';
import UserReducer from './user';
import VehiclesReducer from './vehicle';
import SignalRReducer from './signalr';

/** REDUCERS */
const rootReducer = combineReducers({
	app: AppReducer,
	user: UserReducer,
	signalr: SignalRReducer,
	filters: FiltersReducer,
	map: MapReducer,
	vehicle: VehiclesReducer
});

export default createStore(
	rootReducer,
	compose(
		applyMiddleware(reduxThunk),
		window.__REDUX_DEVTOOLS_EXTENSION__  ? window.__REDUX_DEVTOOLS_EXTENSION__ () : f => f
	)
);