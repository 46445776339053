import React, { Fragment, useEffect, useReducer } from 'react';
import { MainContent } from '../../../common/layout';
import TasksList from './list';
import TasksListFilter from './list/filters/ListFilter';
import TasksMainFilter from './list/filters/MainFilter';
import TasksWeekDaysFilter from './list/filters/WeekDaysFilter';
import { Badge, Button, Col, FormGroup, Row } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { Callout, ErrorBox, Loader } from '../../../common/uiElements';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import TaskApi from '../../../../app/api/TaskApi';
import { ITaskListItem, TaskForm } from '../../../../app/models/Task';
import { TasksListMainFilter as TasksListMainFilterClass, TasksListFilter as TasksListFilterClass } from '../../../../app/models/Task';
import { filterTasksList } from './tasksHelper';
import { IError } from '../../../../app/models/Application';
import TaskModal from './TaskModal';
import $ from 'jquery';
import moment from 'moment';
import listsStore from '../../../../stores/mobx/database/listsStore';
// import confirm from '../../../../app/helpers/confirm';

interface IState {
	currentPage: number;
	tasksList: ITaskListItem[];
	tasksListLastUpdatedAt: moment.Moment;
	filteredStaffRosterList: ITaskListItem[];
	selectedTaskId?: number;
	selectedRowIndex?: number;
	mainFilter: TasksListMainFilterClass;
	listFilter: TasksListFilterClass;
	selectedPlaceName: string;
	showTaskModal: boolean;
	showUpdateReasonModal: boolean;
	isLoading: boolean;
	error?: IError;
	errorSaving?: IError;
	isLoadingInBackground: boolean;
}

class TasksContainer extends React.Component<{}, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			currentPage: 0,
			tasksList: [],
			tasksListLastUpdatedAt: moment(),
			filteredStaffRosterList: [],
			mainFilter: new TasksListMainFilterClass(),
			listFilter: new TasksListFilterClass(),
			selectedPlaceName: '',
			showTaskModal: false,
			showUpdateReasonModal: false,
			isLoading: false,
			error: undefined,
			isLoadingInBackground: false
		};
	}

	componentDidMount() {
		// Hide left menu
		$('body:not(.sidebar-collapse)').addClass('sidebar-collapse');

		this.fetchTasks();

		// Force fetch workers for tasks list when the component is mounted
		// To save time when the user opens the modal
		this.fetchReferenceData();
	}	

	/**
	 * Fetch the reference data to be used in the task details modal
	 */
	fetchReferenceData = () => {
		const { date } = this.state.mainFilter;
		if (!date) return;

		listsStore.fetchWorkersForTasks(true, true, date, date);
		listsStore.fetchVehiclesForTasks(true);
	}

	fetchTasks = (loadInBackground?: boolean, forceFetch?: boolean) => {
		// If it's already loading, don't fetch again unless it's forced
		if ((this.state.isLoadingInBackground || this.state.isLoading) && !forceFetch)
			return;

		const { date } = this.state.mainFilter;
		if (!date)
			return;

		const startDateTime = `${date} 00:00`;
		const finishDateTime = `${date} 23:59`;

		const promise = () => TaskApi.getTasksList(startDateTime, finishDateTime);

		const tasksListLastUpdatedAt = moment();
		if (!loadInBackground) {
			this.setState({ tasksList: [], filteredStaffRosterList: [], tasksListLastUpdatedAt, error: undefined })
			componentRequestHandler(this, promise, 'tasksList').then(this.filterList);

			// Force fetch the reference data when the user refreshes the list which means the date has changed
			this.fetchReferenceData();
			return;
		}

		this.setState({ isLoadingInBackground: true, error: undefined });
		promise()
			.then(tasksList => this.setState({ tasksList, tasksListLastUpdatedAt }))
			.then(() => this.filterList())
			.catch(error => this.setState({ error }))
			.finally(() => this.setState({ isLoadingInBackground: false }));
	}

	filterList = () => {
		const { tasksList, mainFilter, listFilter, currentPage } = this.state;
		const filteredStaffRosterList = filterTasksList(tasksList, listFilter, mainFilter.startingDepotId, mainFilter.date);
		this.setState({ filteredStaffRosterList, currentPage });
	}

	clearListFilter = () => {
		this.setState({ 
			tasksList: [],
			tasksListLastUpdatedAt: moment(),
			listFilter: new TasksListFilterClass(),
			currentPage: 0
		}, this.filterList);
	}

	showTaskModal = () => {
		this.setState({ showTaskModal: true });
	}

	closeTaskModal = () => {
		this.setState({ showTaskModal: false, selectedTaskId: undefined });
	}

	closeUpdateReasonModal = () => {
		this.setState({ showUpdateReasonModal: false });
	}

	showUpdateReasonModal = () => {
		this.setState({ 
			showUpdateReasonModal: false
		});
	}

	handleChangeYear = (year: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				year,
				date: '',
				weekNumber: moment().year() !== year ? 1 : moment().isoWeek(),
			},
		}, this.clearListFilter);
	}

	handleChangeWeek = (weekNumber: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				weekNumber,
				date: ''
			},
		}, this.clearListFilter);
	}

	handleChangeDepot = (startingDepotId?: string, startingDepotName?: string) => {
		this.setState({
			currentPage: 0,
			mainFilter: {
				...this.state.mainFilter,
				startingDepotName,
				startingDepotId
			}
		}, this.filterList);
	}

	handleChangeWeekDayDate = (date: string) => {
		this.setState({
			currentPage: 0,
			mainFilter: {
				...this.state.mainFilter,
				date
			},
			listFilter: new TasksListFilterClass()
		}, () => this.fetchTasks(false, true));
	}

	handleChangeListFilter = (key: string, value: number | string) => {
		this.setState({
			currentPage: 0,
			listFilter: {
				...this.state.listFilter,
				[key]: value
			}
		}, this.filterList);
	}

	handleClearListFilter = () => {
		this.setState({ 
			listFilter: new TasksListFilterClass()
		}, this.filterList);
	}

	handleClearHoursRangeFilter = () => {
		this.setState({ 
			listFilter: {
				...this.state.listFilter,
				hoursRange: ''
			}
		}, this.filterList);
	}

	handleSelectTask = (selectedTask: ITaskListItem) => {
		this.setState({
			selectedTaskId: selectedTask.id
		}, this.showTaskModal);
	}
	
	handleAddNewTask = () => {
		this.setState({ 
			selectedRowIndex: undefined, 
			selectedTaskId: undefined 
		}, this.showTaskModal);
	}

	handleChangeListPage = (currentPage: number) => {
		this.setState({ currentPage });
	}

	handleSaveComplete = (newTaskDetails: TaskForm, taskId?: string) => {
		const parsedNewDetails = {
			...newTaskDetails,
			id: newTaskDetails.id || parseInt(taskId!),
			isStartTimeAdjustNeeded: false,
			taskTypeId: parseInt(newTaskDetails.taskTypeId),
			budgetedTime: parseFloat(newTaskDetails.budgetedTime),
			startTimeLocal: newTaskDetails.date + ' ' + newTaskDetails.startTime,
			startingDepotId: parseInt(newTaskDetails.startingDepotId),
			budgetedKm: parseInt(newTaskDetails.budgetedKm || "0"),
			runId: parseInt(newTaskDetails.runId || "0"),
			totalStores: newTaskDetails.RunCustomerOrders?.length || null,
		} as ITaskListItem;
		
		// Update existing task with the new details in the list or adding new one
		// to show the changes in the list straight away
		const { tasksList } = this.state;
		const index = tasksList.findIndex(task => task.id === newTaskDetails.id);
		
		if (index === -1)
			tasksList.push(parsedNewDetails);
		else
			tasksList[index] = {
				...tasksList[index],
				...parsedNewDetails
			};

		this.setState({
			tasksList,
			showTaskModal: false
		}, () => {
			this.filterList();
			this.fetchTasks(true);
		});
	}

	render() {
		const { state } = this;
		const { year, weekNumber, startingDepotId, startingDepotName, date } = state.mainFilter;

		return (
			<MainContent title="Roster - Tasks" className="staff-roster">
				{
					state.showTaskModal && (
						<TaskModal 
							show
							tasksList={state.tasksList}
							taskId={state.selectedTaskId}
							isChangeDateEnabled={false}
							isChangePlaceEnabled={false}
							initialDate={date}
							initialStartingDepotId={startingDepotId}
							initialStartingDepotName={startingDepotName}
							onClose={this.closeTaskModal}
							onSaveComplete={this.handleSaveComplete}
						/>
					)
				}
				<TasksMainFilter
					{...state.mainFilter}
					onChangeYear={this.handleChangeYear}
					onChangeWeek={this.handleChangeWeek}
					onChangeDepot={this.handleChangeDepot}
				/>
				{
					!year || !weekNumber ? (
						<Callout icon="info-circle" color="info" title="Select the Year and Week" />
					) : (
						<>
							<FormGroup>
								<TasksWeekDaysFilter 
									date={date}
									year={year}
									weekNumber={weekNumber}
									onChange={this.handleChangeWeekDayDate}
								/>
							</FormGroup>
							{ !date && <Callout icon="info-circle" color="info" title="Select the day of the week" /> }
						</>
					)
				}
				{
					date && (
						<Fragment>
							<FormGroup>
								<Row>
									<Col xs={6}>
										<Button
											block
											bsStyle="info"
											disabled={state.isLoading || state.isLoadingInBackground}
											onClick={() => (!state.isLoading || !state.isLoadingInBackground) && this.fetchTasks()}
										>
											{
												state.isLoadingInBackground || state.isLoading ? (
													<>
														<Loader inline white/> Loading...
													</>
												) : (
													<>
														<Icon name="refresh" /> Refresh List
													</>
												)
											}
										</Button>
									</Col>
									<Col xs={6}>
										<Button
											block
											bsStyle="success"
											onClick={this.handleAddNewTask}
										>
											<Icon name="plus" /> Add New Task
										</Button>
									</Col>
								</Row>
							</FormGroup>
							<TasksListFilter 
								{ ...state.listFilter }
								tasksList={state.tasksList}
								selectedDepotId={state.mainFilter.startingDepotId}
								onChange={this.handleChangeListFilter}
								onClear={this.handleClearHoursRangeFilter}
							/>
							{
								state.error ? (
									<ErrorBox error={state.error} retryFunc={this.fetchTasks} />
								)	: (
									<>
										<LastListUpdate lastUpdatedAt={state.tasksListLastUpdatedAt} />
										<TasksList 
											tasksList={state.filteredStaffRosterList}
											isLoading={state.isLoading}
											currentPage={state.currentPage}
											onSelectTask={this.handleSelectTask}
											onChangePage={this.handleChangeListPage}
										/>
									</>
								)
							}
						</Fragment>
					)
				}
			</MainContent>
		);
	}
}

export default TasksContainer;

interface ILastListUpdateProps {
	lastUpdatedAt: moment.Moment;
}

const LastListUpdate: React.FC<ILastListUpdateProps> = (props) => {
	// Force Update approach according to React documentation
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	// Update the component every minute to show the last update time
	useEffect(() => {
		const interval = setInterval(forceUpdate, 60000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div style={{ textAlign: 'left' }}>
			<Badge style={{ marginBottom: 5, fontSize: 15, fontWeight: 'normal' }}>
				Last Update: { props.lastUpdatedAt.format('DD/MM/YY HH:mm') }
				&nbsp;(<b>{ props.lastUpdatedAt.fromNow() }</b>)
			</Badge>
		</div>
	);
}