import httpRequests from './httpRequests';
import nonConformanceEndpoints from './endpoints/nonConformanceEndpoints';

export default {

	/**
	 * Gets Non Conformance list
	 * @param filters 
	 * @param restrictAzureId 
	 */
	getNonConformanceList(filters: string, restrictAzureId: number) {
		let endpoint = nonConformanceEndpoints.root;
        
		if (filters)
			filters = JSON.stringify(filters);

		if (restrictAzureId)
			endpoint += '?restrictAzureId=' + restrictAzureId;

		return httpRequests.get(endpoint, { filterString: filters });
	},

	/**
	 * Gets Non Conformance details by Id
	 * @param nonConformanceId 
	 */
	getNonConformanceDetails(nonConformanceId: number) {
		let endpoint = nonConformanceEndpoints.byId(nonConformanceId);
		return httpRequests.get(endpoint);
	},

	/**
	 * Saves Non Conformance data
	 * @param data 
	 */
	saveNonConformanceData(data: object) {
		let endpoint = nonConformanceEndpoints.root;
		return httpRequests.post(endpoint, data);
	},

	/**
	 * Saves Non Conformance data
	 * @param nonConformanceId 
	 * @param data 
	 */
	updateNonConformanceData(nonConformanceId: number, data: object) {
		let endpoint = nonConformanceEndpoints.byId(nonConformanceId);
		return httpRequests.put(endpoint, data);
	}
};
