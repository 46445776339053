import groups from '../app/schemas/PermissionGroups';

// Companies
import CompaniesContainer from '../components/features/management/companies/CompaniesContainer';
import CompanyNewContainer from '../components/features/management/companies/CompanyAddNewContainer';
import CompanyEditContainer from '../components/features/management/companies/CompanyEditContainer';
import CompanyViewContainer from '../components/features/management/companies/CompanyViewContainer';

// Workers
import WorkersContainer from '../components/features/management/workers/WorkersContainer';
import WorkersDirectory from '../components/features/management/workers/WorkersDirectory';
import WorkersNew from '../components/features/management/workers/WorkersNew';
import WorkersEditContainer from '../components/features/management/workers/WorkersEditContainer';
import WorkersViewContainer from '../components/features/management/workers/WorkersViewContainer';

import LeaveRequests from '../components/features/management/leaveRequests';

export default {
	path: 'management',
	menuItem: { title: 'Management', icon: 'folder' },
	authorize: [
		groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
		groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
		groups.SUPERVISORS, groups.KIOSK
	],
	childRoutes: [
		{ 
			path: 'directory', 
			menuItem: { title: 'Directory', icon: 'users' },
			component: WorkersDirectory,
			authorize: [
				groups.ADMIN, groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
				groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
				groups.SUPERVISORS, groups.KIOSK
			]
		},
		{ 
			path: 'companies',
			menuItem: { title: 'Companies', icon: 'institution' },
			indexRoute: { component: CompaniesContainer },
			authorize: [groups.ADMIN_STAFF_SIMPLE, groups.ADMIN_STAFF_FULL],
			childRoutes: [
				{ 
					path: 'new', 
					component: CompanyNewContainer
				},
				{ 
					path: ':abn', 
					component: CompanyViewContainer
				},
				{ 
					path: 'edit/:abn', 
					component: CompanyEditContainer
				}
			]
		},
		{ 
			path: 'workers',
			menuItem: { title: 'Workers', icon: 'user' },
			indexRoute: { component: WorkersContainer },
			authorize: [groups.ADMIN_STAFF_FULL],
			childRoutes: [
				{ 
					path: 'new', 
					component: WorkersNew
				},
				{ 
					path: ':azureId', 
					component: WorkersViewContainer
				},
				{ 
					path: 'edit/:azureId', 
					component: WorkersEditContainer
				},
			]
		},
		{ 
			path: 'leaveRequests', 
			menuItem: { title: 'Leave Requests', icon: 'plane' },
			component: LeaveRequests,
			authorize: [groups.ADMIN_STAFF_FULL],
		}
	]
};