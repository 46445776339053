import { string, number, object, oneOfType, arrayOf } from 'prop-types';
import workerSimpleType from './workerSimpleType';

export default {
	actionTakenById: string.isRequired,
	actionTakenOn: string.isRequired,
	shortTermAction: string.isRequired, 
	longTermAction: string,
	preventativeAction: string.isRequired, 
	comments: string,
	estimatedCost: oneOfType([
		number,
		string
	]),
	actualCost: oneOfType([
		number,
		string
	]),
	WorkerActionTakenBy: workerSimpleType,
	PreStartCorrectiveActionDocuments: arrayOf(object)
};