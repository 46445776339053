import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import DurationParse from '../../../../app/helpers/DurationParse';
import moment from 'moment';
import { IPalletUnloadListItem } from '../../../../app/models/Pallet';
import { ProgressBar } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

interface IProps {
	data: IPalletUnloadListItem;
	small?: boolean;
	hideDock?: boolean;
	hideTotalPallets?: boolean;
	hideDuration?: boolean;
	showFinishedTime?: boolean;
	onClick?: (id: string) => void;
}

const PalletUnloadBox: React.FC<IProps> = props => {
	var interval = setInterval(() => {
		setLoadingTimeInSec(loadingTimeInSec + 60)
	}, 60000);

	const { data } = props;
	const [loadingTimeInSec, setLoadingTimeInSec] = useState(moment().diff(moment(data.startedAt), 'seconds'));

	useEffect(() => {
		if (data.finishedAt)
			clearInterval(interval);

		return () => clearInterval(interval);
	}, [data.finishedAt, interval])
	
	// Get percentage of loaded pallets based on total pallet capacity of the trailer
	const percentageLoadedTrailer = data.finishedAt ? 100 : Math.round(((data.totalScannedPallets || 0) / (data.totalPalletsToUnload || 0)) * 100);

	const status = useMemo(() => {
		return { style: 'warning', bgColor: 'bg-yellow' };
	}, []);

	const $startTime = moment(data.startedAt);
	const $finishTime = moment(data.finishedAt || Date.now());
	const timeSpentParsed = data.cancelledAt ? '-' : DurationParse.toHourMin(moment.duration($finishTime.diff($startTime)).asSeconds(), 'short');
	const totalPallets = (data.totalScannedPallets || 0) + (data.totalEmptyPalletsChep || 0) + (data.totalEmptyPalletsLoscam || 0) + (data.totalEmptyPalletsT3 || 0) + (data.totalPalletsWithCratesChep || 0) + (data.totalPalletsWithCratesLoscam || 0);
	
	const statusIcon = useMemo(() => {
		if (data.finishedAt)
			return "check";
		
		return "refresh"
	}, [data.finishedAt]);

	return (
		<div className={classNames("panel-row-container", status.bgColor, "cursor-pointer", { "small": props.small })} onClick={() => props.onClick && props.onClick(data.id)}>
			<div className="flex-1 flex flex-row panel-row">
				<span className="flex-2">{ data.run?.name || '-' }</span>
				<span className="flex-2">{ data.depot.name }</span>
				{ !props.hideDock && <span className="flex-1">{ data.dockNumber || '-' }</span> }
				<span className="flex-2">{ data.fleetNumber || '-' }</span>
				{ !props.hideTotalPallets && <span className="flex-2">{ totalPallets } {!data.finishedAt && data.totalPalletsToUnload ? `/ ${ data.totalPalletsToUnload || '' }` : ''} pallets</span> }
				<span className="flex-3">
					{ 
						data.loaders?.length > 0 ? (
							data.loaders.map((loader, index) => (
								<>
									{ index > 0 && <br /> }
									<span key={index}>{ loader.name }</span>
								</>
							))
						) : '-'
					}
				</span>
				{ props.showFinishedTime && <span className="flex-2">{ moment(data.finishedAt || new Date()).format('HH:mm') }</span> }
				{ !props.hideDuration && <span className="flex-2">{ timeSpentParsed }</span> }
				<FontAwesome name={statusIcon} className="status-icon" />
				<ProgressBar 
					active={!data.isPaused && !data.finishedAt}
					now={percentageLoadedTrailer} 
					bsStyle={status.style}
				/>
			</div>
		</div>
	)
}

export default PalletUnloadBox;