import moment from 'moment';
import React from 'react';
import ReactTable, { Column, RowInfo } from 'react-table';
import { IPallet_Unload } from '../../../../../app/models/Pallet';
import { Callout } from '../../../../common/uiElements';
import { Badge } from 'react-bootstrap';

interface IProps {
	pallets: IPallet_Unload[];

	isReadOnly?: boolean;

	onSelectPallet: (pallet: IPallet_Unload, rowIndex: number) => void;
	onDeletePallet: (pallet: IPallet_Unload) => void;
}

const PalletsListUnloads: React.FC<IProps> = props => (
	<div id="unload-pallets-list-table">
		<Callout icon="info-circle" color="info" title="Tap on the pallet to edit" />
		<ul className="list-inline">
			<li><Badge className="warning">ID Manually Entered</Badge></li>
			<li><Badge className="danger">Not Previously Loaded</Badge></li>
		</ul>
		<ReactTable
			minRows={0}
			pageSize={props.pallets.length}
			showPagination={false}
			data={props.pallets as any}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				// Disable row selection for the DELETE column
				if (!rowInfo || column?.id === 'delete') return {};

				const pallet = rowInfo.original as IPallet_Unload;
				return {
					onClick: () => props.onSelectPallet && props.onSelectPallet(pallet, rowInfo.index)
				}
			}}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo) return {};

				const pallet = rowInfo.original as IPallet_Unload;

				let className = '';
				if (pallet.notPreviouslyLoaded)
					className += 'bg-red-light';

				return { className };
			}}
			columns={[
				{
					Header: '#',
					id: 'order',
					width: 30,
					Cell: row => row.index + 1,
				},
				{
					Header: 'Store',
					accessor: 'store',
					Cell: row => !row.value ? 'Unknown' : (row.value.storeNumber ? row.value.storeNumber + ' - ' : '' ) + row.value.name,
				},
				{
					Header: 'Pallet ID',
					id: 'palletId',
					width: 150,
					Cell: row => {
						const pallet = row.original as IPallet_Unload;
						return (
							<>
								{pallet.palletId}
							</>
						)
					}
				},
				{
					Header: 'Type',
					accessor: 'palletType',
					width: 90,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Commodity',
					accessor: 'commodity',
					width: 90,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Unloaded At',
					accessor: 'scannedAt',
					width: 100,
					Cell: row => row.value ? moment(row.value).format('HH:mm') :  '-'
				},
				{
					Header: 'Unloaded By',
					accessor: 'scannedBy',
					Cell: row => !row.value ? '-' : row.value ? row.value.name : '',
				}
			]}
		/>
	</div>
);



export default PalletsListUnloads;