import React from 'react';
import { MainContent } from './common/layout';

import GeneralDashboard from './features/dashboards/GeneralDashboard';
import DriversDashboard from './features/dashboards/DriversDashboard';
import { isDriver, isLoader } from '../app/helpers/permissionHelper';

const Home = () => (
	<MainContent title="Home">
		{ isDriver() || isLoader() ? <DriversDashboard /> : <GeneralDashboard /> }
	</MainContent>
)

export default Home;