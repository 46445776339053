import React from 'react';
import PropTypes from 'prop-types';
import { ContentBox } from './';

const propTypes = {
	noMarginBottom: PropTypes.bool,
	children: PropTypes.any,
};

const FiltersBox = ({ noMarginBottom, children }) => (
	<div>
		<ContentBox 
			title="Filters" 
			icon="search" 
			color="primary" 
			minimize={true} 
			noMarginBottom={noMarginBottom}
		>
			{children}
		</ContentBox>
	</div>
);

FiltersBox.propTypes = propTypes;

export { FiltersBox };