import { makeAutoObservable, runInAction } from "mobx";
import { IWorkerForTaskOrRoster } from "../../../app/models/Worker";
import ListsApi from "../../../app/api/ListsApi";
import { AustralianState, IError } from "../../../app/models/Application";
import moment from "moment";
import { IVehicleDetailsForTask } from "../../../app/models/Vehicle";

// TODO - Implement local storage caching
// TODO - Transfer the interface to a separate file
// TODO - Create interface or class for filters and pass it as a parameter 
// (e.g IWorkersForTaskFilter { onlyActive: boolean, availabilityDate: string, rosterDate: string })

interface IFetchDataOptions<T> {
	/** If set to true, it will force the fetch of the data */
	forceFetch?: boolean;

	/** The time in minutes before it auto force-fetch (default is 12 hours) */
	expiryInMinutes?: number;

	/** The promise that will fetch the data */
	fetchFunction: () => Promise<T>;
}

class ListStoreItem<T> {
	/** The data that will be stored in the store */
  data?: T;

	/** The date when the data was last fetched */
	lastFetchDate?: Date;

	/** If the data is being fetched */
  isFetching = false;

	/** If there was an error while fetching the data */
  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

	async fetchData(options: IFetchDataOptions<T>): Promise<void> {
		let { forceFetch, fetchFunction } = options;

		// Check if the data is already fetched and if it's not expired
		// default expiry time is 12 hours
		const expiryInMinutes = options.expiryInMinutes || (12 * 60);
		const minutesSinceLastFetch = moment().diff(moment(this.lastFetchDate), 'minutes');
		if (minutesSinceLastFetch > expiryInMinutes)
			forceFetch = true;

		if (this.data && !forceFetch) return;
		
    this.setLoading(true);
    this.setError(null);

    try {
      this.setData(await fetchFunction());
    } catch (error) {
      this.setError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setData = (data?: T) => {
		runInAction(() => {
			this.data = data;
			this.lastFetchDate = new Date();
		})
  };

  setLoading = (loading: boolean) => {
    runInAction(() => this.isFetching = loading);
  };

  setError = (error: any) => {
		runInAction(() => this.error = error);
  };
}

class ListsStore {
  workersForTaskOrRoster = new ListStoreItem<IWorkerForTaskOrRoster[]>();
	vehiclesForTask = new ListStoreItem<IVehicleDetailsForTask[]>();

  fetchWorkersForTasks = async (forceFetch = false, onlyActive = true, availabilityDate: string, rosterDate: string) => {
    await this.workersForTaskOrRoster.fetchData({ 
			forceFetch, 
			fetchFunction: ListsApi.getWorkersListForTasks.bind(this, onlyActive, undefined, availabilityDate, rosterDate)
		});
	}

	fetchVehiclesForTasks = async (forceFetch = false, onlyActive = true, vehTypeFilter?: number[], stateFilter?: AustralianState[]) => {
		await this.vehiclesForTask.fetchData({ 
			forceFetch, 
			fetchFunction: ListsApi.getVehiclesForTaskList.bind(this, onlyActive, vehTypeFilter, stateFilter)
		});
	}
}

const listsStore = new ListsStore();
export default listsStore;