import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import cloneObject from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { Button, FormGroup, ButtonToolbar } from 'react-bootstrap';
import confirm from '../../../../../../app/helpers/confirm';
import { thereAreOverlappedWindows, isWindowInvalid, setTemporaryIds } from '../../../../../../app/helpers/timeWindowsHelper';
import weekDays from '../../../../../../app/store/weekDays';

import CurfewWindowsForm from './CurfewWindowsForm';
import CurfewWindowsTable from './CurfewWindowsTable';

export default class CurfewWindowsContainer extends Component {
	static propTypes = {
		curfews: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				dayOfWeek: PropTypes.number.isRequired,
				startTime: PropTypes.string.isRequired,
				endTime: PropTypes.string.isRequired,
				curfewTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				comments: PropTypes.string,
			})
		).isRequired,
		isReadOnly: PropTypes.bool,
		onClickReplicateButton: PropTypes.func
	}

	state = {
		enableEditMode: false,
		curfews: [],
		curfewsBeingEdited: []
	};

	componentDidMount() {
		if (!this.props.curfews)
			return;

		this.setState({ 
			curfews: setTemporaryIds(cloneObject(this.props.curfews))
		});
	}	

	handleAddNewWindow = () => {
		this.setState({ showAddNewWindowModal: true });
	}

	handleSaveNewWindow = newWindows => {
		// Check overlaps
		if (thereAreOverlappedWindows(newWindows, this.state.curfews))
			return;

		newWindows = setTemporaryIds(newWindows);

		this.setState({ 
			curfews: [
				...this.state.curfews,
				...newWindows
			], 
			showAddNewWindowModal: false 
		});
	}

	handleSaveChanges = () => {
		let { curfews, curfewsBeingEdited } = this.state;

		// Check overlaps
		if (thereAreOverlappedWindows(curfewsBeingEdited, curfews, true))
			return;
		
		// Check invalid windows
		let anyInvalidWindow = false;
		curfewsBeingEdited = curfewsBeingEdited.map(window => {
			const invalidWindowMessage = isWindowInvalid(window.startTime, window.endTime);
			if (invalidWindowMessage) {
				anyInvalidWindow = true;
				window.invalidMessage = invalidWindowMessage;
			} else {
				window.invalidMessage = null;
			} 

			return window;
		});

		if (anyInvalidWindow)
			return this.setState({ curfewsBeingEdited });

		this.setState({ 
			curfews: [ ...curfewsBeingEdited ],
			editedWindowsList: [],
			enableEditMode: false
		});
	}

	handleRemoveWindow = pTempId => {
		const { curfews } = this.state;
		const indexToRemove = curfews.findIndex(p => p.tempId === pTempId);
		const windowToRemove = curfews[indexToRemove];
		if (!windowToRemove)	
			throw new Error('Window not found in the array');

		const { startTime, endTime, dayOfWeek } = windowToRemove;
		confirm(
			(
				<h4 className="text-center">
					The window <b>{weekDays[dayOfWeek - 1]} {startTime} - {endTime}</b> will be deleted. Do you wish to continue?
				</h4>
			),
			() => {
				if (windowToRemove.id)
					windowToRemove.isDeleted = true;
				else
					curfews.splice(indexToRemove, 1);

				this.setState({ curfews });
			}
		);
	}

	handleChangeWindow = (tempId, event, text) => {
		const { curfewsBeingEdited } = this.state;
		const { id, value, type, checked } = event.target;
		const windowToChange = curfewsBeingEdited.find(p => p.tempId === tempId);
		windowToChange[id] = type === 'checkbox' ? checked : value;
		windowToChange.curfewTypeName = id === 'curfewTypeId' ? text : windowToChange.curfewTypeName;
		this.setState({ curfewsBeingEdited });
	}

	handleToggleEditMode = () => {
		const enableEditMode = !this.state.enableEditMode;
		this.setState({ 
			enableEditMode,
			curfewsBeingEdited: enableEditMode ? cloneObject(this.state.curfews) : []
		});
	}

	handleClearAllWindows = () => {
		confirm(
			'All windows will be deleted! Do you wish to continue?',
			() => {
				const curfews = this.state.curfews.filter(window => window.id).map(window => { 
					window.isDeleted = true;
					return window;
				});

				this.setState({ curfews });
			}
		);
	}

	renderActionButton = (text, icon, colour, onClick, className) => {
		return (
			<Button 
				bsStyle={colour}
				onClick={onClick}
				className={className}
			>
				<Icon name={icon} /> {text}
			</Button>
		);
	}

	render() {
		const { state, props } = this;

		return (
			<div className="time-windows">
				{
					!props.isReadOnly && (
						<React.Fragment>
							<CurfewWindowsForm 
								show={state.showAddNewWindowModal}
								onCancel={() => this.setState({ showAddNewWindowModal: false })}
								onSave={this.handleSaveNewWindow}
							/>
							<FormGroup>
								<ButtonToolbar>
									{
										state.enableEditMode ? (
											<Fragment>
												{ this.renderActionButton('Cancel', 'arrow-left', 'danger', this.handleToggleEditMode) }
												{ this.renderActionButton('Save Changes', 'floppy-o', 'success', this.handleSaveChanges) }
											</Fragment>
										) : (
											<Fragment>
												{
													props.windows && props.windows.length > 0 && (
														this.renderActionButton('Edit Curfews', 'edit', 'warning', this.handleToggleEditMode)
													)
												}
												{ this.renderActionButton('Add New Curfew', 'plus', 'success', this.handleAddNewWindow) }
												{ this.renderActionButton('Clear All Curfews', 'trash', 'danger', this.handleClearAllWindows, 'pull-right') }
											</Fragment>
										)
									}
									
								</ButtonToolbar>
							</FormGroup>
						</React.Fragment>
					)
				}
				<CurfewWindowsTable
					isReadOnly={props.isReadOnly}
					enableEditMode={state.enableEditMode}
					windows={sortBy(state.curfews, ['startTime'])}
					onClickRemove={this.handleRemoveWindow}
					onChange={this.handleChangeWindow}
				/>
			</div>
		);
	}
}