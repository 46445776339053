import humanizeDuration from 'humanize-duration';

humanizeDuration.languages.shortEn = {
	mo: () => 'mon',
	d: val => val > 1 ? 'days' : 'day',
	h: val => val > 1 ? 'hrs' : 'hr',
	m: () => 'min',
	s: () => 'sec',
	ms: () => 'ms',
};

var defaultOptions = {
	conjunction: ' and ', 
	language: 'en',
	round: true, 
	serialComma: false,
	units: ['d', 'h', 'm'] 
};

const renderDuration = (durationInSec, units, isShort) => 
	humanizeDuration(durationInSec * 1000, {
		...defaultOptions,
		units,
		language: isShort ? 'shortEn' : 'en'
	});

export default {
	toDayHour: (durationInSec, isShort) => renderDuration(durationInSec, ['d', 'h'], isShort),
	toDayHourMinSec: (durationInSec, isShort) => renderDuration(durationInSec, ['d', 'h', 'm', 's'], isShort),
	toHour: (durationInSec, isShort) => renderDuration(durationInSec, ['h'], isShort),
	toHourMin: (durationInSec, isShort) => renderDuration(durationInSec, ['h', 'm'], isShort),
	toHourMinSec: (durationInSec, isShort) => renderDuration(durationInSec, ['h', 'm', 's'], isShort),
	toMinSec: (durationInSec, isShort) => renderDuration(durationInSec, ['m', 's'], isShort)
};