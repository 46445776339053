import httpRequests from './httpRequests';
import correctiveActionEndpoints from './endpoints/correctiveActionEndpoints';

export default {
	/**
	 * Gets Corrective Action details by Id
	 * @param correctiveActionId 
	 */
	getCorrectiveActionDetails(correctiveActionId: number) {
		let endpoint = correctiveActionEndpoints.byId(correctiveActionId);		
		return httpRequests.get(endpoint);
	},

	/**
	 * Get the URL of the document
	 * @param correctiveActionId 
	 * @param fileId 
	 */
	getDocument(correctiveActionId: number, fileId: number) {
		const endpoint = correctiveActionEndpoints.document(correctiveActionId, fileId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Saves Corrective Action data
	 * @param data 
	 */
	saveCorrectiveActionData(data: object) {
		let endpoint = correctiveActionEndpoints.root;
		return httpRequests.postWithFiles(endpoint, data);
	},

	/**
	 * Saves Corrective Action data
	 * @param correctiveActionId 
	 * @param data 
	 */
	updateCorrectiveActionData(correctiveActionId: number, data: object) {
		let endpoint = correctiveActionEndpoints.byId(correctiveActionId);
		return httpRequests.putWithFiles(endpoint, data);
	},

	/**
	 * Saves Corrective Action data
	 * @param correctiveActionId 
	 * @param file 
	 */
	uploadDocument(correctiveActionId: number, file: object) {
		let endpoint = correctiveActionEndpoints.addDocument(correctiveActionId);
		return httpRequests.postWithFiles(endpoint, file);
	},
	
};
