import httpRequests from './httpRequests';
import preStartEndpoints from './endpoints/preStartEndpoints';
import axios from 'axios';
import IPreStart from '../models/PreStart';

export default {

	/**
     * Get Pre-Starts list
     */
	getPreStartsList(filters: string) {
		const endpoint = preStartEndpoints.root;
        
		if (filters)
			filters = JSON.stringify(filters);

		return httpRequests.get(endpoint, { filterString: filters });
	},

	/**
     * Get the Pre-Start data
     * @param {string} preStartId Pre-Start ID
     */
	getPreStart(preStartId: string) {
		const endpoint = preStartEndpoints.byId(preStartId);
		return httpRequests.get(endpoint);
	},

	/**
     * Get a list of Pre-Starts with the IDs passed as parameter
     * @param {array} preStartIdsList An array with all pre-starts Ids that you want to get the data
     */
	getPreStartsListById(preStartIdsList: string[]) {
		if (!preStartIdsList)
			throw new Error('preStartIdsList is required');

		let promisesList = [];
		for (var i = 0; i < preStartIdsList.length; i++) {
			let preStartId = preStartIdsList[i];
			let promise = this.getPreStart(preStartId);
			promisesList.push(promise);
		}
        
		return httpRequests.getMany(promisesList);
	},

	/**
     * Get the worker's signature image
     * @param {string} preStartId Pre-Start ID
     */
	getPreStartReportedBySignature(preStartId: string) {
		const endpoint = preStartEndpoints.workerSignature(preStartId);
		return httpRequests.getImage(endpoint);
	},


	/**
     * Save a new Pre Start Check
     * @param {object} data Pre Start Data 
     */
	saveNewPreStart(data: object) {
		const endpoint = preStartEndpoints.root;
		return httpRequests.postWithFiles(endpoint, data);
	},

	/**
     * Save the last part of the Pre Start Check
     * @param {string} preStartId Id of the Pre-Start
     * @param {object} data Pre Start Data 
     */
	saveLastPartPreStart(preStartId: string, data: object) {
		const endpoint = preStartEndpoints.byId(preStartId);
		return httpRequests.putWithFiles(endpoint, data);
	},

	/**
     * Check out the Pre-Start check
     * @param {string} preStartId Id of the Pre-Start that is being checked out
     * @param {string} checkOutById The azureId of who is checking out
     * @param {boolean} checkOutIsApproved If the Pre-Start is approved or disapproved
     * @param {string} checkOutComments Check out comments
     */
	checkoutPreStart(preStartId: string, checkOutById: string, checkOutIsApproved: boolean, checkOutComments?: string) {
		const endpoint = preStartEndpoints.checkOut(preStartId);
		return httpRequests.put(endpoint, { checkOutById, checkOutIsApproved, checkOutComments });
	},

	/**
     * Finish the Pre-Start check
     * @param {string} preStartId Id of the Pre-Start that is being checked out
     * @param {string} lastAcknowledgeById The azureId of who is checking out
     * @param {string} lastAcknowledgeComments Check out comments
     */
	acknowledgePreStart(preStartId: string, lastAcknowledgeById: string, lastAcknowledgeComments?: string) {
		const endpoint = preStartEndpoints.acknowledge(preStartId);
		return httpRequests.put(endpoint, { lastAcknowledgeById, lastAcknowledgeComments });
	},

	/**
     * Check out many Pre-Start check
     * @param {string} checkOutById The azureId of who is checking out
	  * @param {array} preStartsData List with pre-starts objects
     */
	checkoutManyPreStarts(checkOutById: string, preStartsData: IPreStart[]) {
		if (!preStartsData)
			throw new Error('preStartsList is required');
        
		let promisesList = [];
		for (var i = 0; i < preStartsData.length; i++) {
			const preStart = preStartsData[i];
			const preStartId = preStart.preStartId || preStart.id;

			let promise;

			if (preStart.lastReportedOnLocal)
				promise = this.acknowledgePreStart(preStartId, checkOutById);
			else
				promise = this.checkoutPreStart(preStartId, checkOutById, true);

			promisesList.push(promise);
		}
        
		return axios.all(promisesList);
	},

	/**
     * Send an SMS to notify supervisors of the pre-start that has been done
     * @param {string} preStartId Id of the Pre-Start
     * @param {string} supervisorId Azure Id of the Supervisor
     */
	notifySupervisor(preStartId: string, supervisorId: string) {
		const endpoint = preStartEndpoints.notifySupervisor(preStartId, supervisorId);
		return httpRequests.post(endpoint);
	},

	
	/**
	 * Check if the mass is greater than the vehicle GVM
	 * @param {object} massManagementData 
	 */
	checkWeight(massManagementData: object) {
		const endpoint = preStartEndpoints.checkVehicleIsNotOverloaded;
		return httpRequests.post(endpoint, massManagementData);
	},

	/**
	 * Save the subsequent runs
	 * @param {string} preStartId 
	 * @param {object[]} runs 
	 */
	saveSecondRuns(preStartId: string, runs: object[]) {
		const endpoint = preStartEndpoints.addRuns(preStartId);
		return httpRequests.post(endpoint, runs);
	},

	/**
	 * Register that the driver didn't have enough time to complete their tasks
	 * @param {string} preStartId 
	 */
	registerWorkHoursBreach(preStartId: string) {
		const endpoint = preStartEndpoints.registerWorkHoursBreach(preStartId);
		return httpRequests.post(endpoint);
	},
};