import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';

// Inputs
import VehicleDamagesModal from '../../../../vehicles/VehicleDamagesModal';
import { RadioButtonList } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';

const propTypes = {
	data: PropTypes.shape({
		lastIsThereAnyDamage: PropTypes.bool,
		VehicleDamages: PropTypes.object,
	}).isRequired,
	onChange: PropTypes.func
};

const defaultProps = {
	data: {
		lastIsThereAnyDamage: false,
		VehicleDamages: {}
	}
};

class VehicleDamages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showVehicleDamageModal: false,
			selectedVehFleetNumber: null
		};

		this.validations = {
			lastIsThereAnyDamage: 'required',
			lastDamageDescription: 'required'
		};

		this.handleChange = (e, val) => {
			const id = e.target.id;
			const value = val !== undefined ? val : e.target.value;
			return this.props.onChange && this.props.onChange({ [id]: value });
		};

		this.handleChangeVehicleDamages = (VehicleDamages) => {
			return this.props.onChange && this.props.onChange({ VehicleDamages });
		};

		this.handleShowModalVehDamage = (selectedVehFleetNumber) => {
			this.setState({
				showVehicleDamageModal: true,
				selectedVehFleetNumber
			});
		};

		this.handleCloseModalVehDamage = () => {
			this.setState({ 
				showVehicleDamageModal: false,
				selectedVehFleetNumber: null
			});
		};
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<Form validations={this.validations}>
				<Row>
					<Col xs={12}>
						<FormGroup>
							<label>Was any damage sustained to the vehicle(s) or any third party property throughout the journey?</label>
							<RadioButtonList 
								id="lastIsThereAnyDamage" 
								value={p.data.lastIsThereAnyDamage}
								items={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} 
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				{
					p.data.lastIsThereAnyDamage && (
						<Row>
							<Col xs={12}>
								<FormGroup>
									<label>What happened?</label>
									<FormControl
										id="lastDamageDescription"
										onChange={this.handleChange}
										defaultValue={p.data.lastDamageDescription}
										placeholder="Describe what happened here..."
										componentClass="textarea"
										rows="5"
									/>
								</FormGroup>
								<FormGroup>
									<label>Attach pictures of the damages to the respective vehicle:</label>
									<ul className="list list-inline">
										{
											p.fleetNumbers.map((fleet, index) => {
												let damages = this.props.data.VehicleDamages ? this.props.data.VehicleDamages[fleet] : null;
												return (
													<li key={index}>
														<Button 
															bsStyle="info"
															onClick={() => { this.handleShowModalVehDamage(fleet); }}
														>
															{`${fleet} (${damages ? damages.length : 0 })`}
														</Button>
													</li>
												);
											})
										}
									</ul>
									<VehicleDamagesModal 
										data={p.data.VehicleDamages && p.data.VehicleDamages[s.selectedVehFleetNumber]}
										vehFleetNumber={s.selectedVehFleetNumber} 
										onChange={this.handleChangeVehicleDamages}
										show={s.showVehicleDamageModal}
										onClose={this.handleCloseModalVehDamage}
									/>
								</FormGroup>
							</Col>
						</Row>
					)
				}
			</Form>
		);
	}
}

VehicleDamages.propTypes = propTypes;
VehicleDamages.defaultProps = defaultProps;

export default VehicleDamages;