export default {
	ticks: chartName => {
		switch (chartName) {
		case 'batteryVoltage':
			return {
				stepSize: 5,
				callback: value => value + 'V'
			};
		case 'doorOpenedClosed':
			return {
				beginAtZero: true,
				stepSize: 1,
				callback: value => value === 0 ? 'Closed' : 'Opened'
			};
		case 'engineOnOff':
			return {
				beginAtZero: true,
				stepSize: 1,
				callback: value => value === 0 ? 'Off' : 'On'
			};
		case 'freezerOnOff':
			return {
				beginAtZero: true,
				stepSize: 1,
				callback: value => value === 0 ? 'Off' : 'On'
			};
		case 'speed':
			return {
				stepSize: 20,
				callback: value => value + ' Km/h'
			};
		case 'zonesTemperatures':
			return {
				stepSize: 10,
				callback: value => value + ' ºC'
			};
		default:
			return { };
		}
	},
	tooltips: chartName => {
		switch (chartName) {
		case 'batteryVoltage':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel + 'V'
				}
			};
		case 'doorOpenedClosed':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel === 0 ? 'Closed' : 'Opened'
				}
			};
		case 'engineOnOff':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel === 0 ? 'Off' : 'On'
				}
			};
		case 'freezerOnOff':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel === 0 ? 'Off' : 'On'
				}
			};
		case 'speed':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel + ' Km/h'
				}
			};
		case 'zonesTemperatures':
			return {
				callbacks: {
					label: tooltipItem => tooltipItem.yLabel + ' ºC'
				}
			};
		default:
			return { };
		}
	}
};