import vehicleMaintenanceEndpoints from './endpoints/vehicleMaintenanceEndpoints';
import httpRequests from './httpRequests';
import { ListRequestOptions, ListReturn } from '../models/Application';
import { IVehicleMaintenance, IVehicleMaintenanceListDetails, VehicleMaintenanceListFilter, VehicleMaintenancePOST, VehicleMaintenancePUT } from '../models/VehicleMaintenance';

export default {
	getList(listOptions: ListRequestOptions, filters: VehicleMaintenanceListFilter): Promise<ListReturn<IVehicleMaintenanceListDetails>> {
		const endpoint = vehicleMaintenanceEndpoints.root;
		return httpRequests.get(endpoint, { filterString: JSON.stringify({ ...listOptions, ...filters }) });
	},

	getDetails(maintenanceId: number): Promise<IVehicleMaintenance> {
		const endpoint = vehicleMaintenanceEndpoints.byId(maintenanceId);
		return httpRequests.get(endpoint);
	},

	create(maintenanceDetails: VehicleMaintenancePOST) {
		const endpoint = vehicleMaintenanceEndpoints.root;
		return httpRequests.post(endpoint, maintenanceDetails);
	},

	update(maintenanceId: number, maintenanceDetails: VehicleMaintenancePUT) {
		const endpoint = vehicleMaintenanceEndpoints.byId(maintenanceId);
		return httpRequests.put(endpoint, maintenanceDetails);
	},

	delete(maintenanceId: number) {
		const endpoint = vehicleMaintenanceEndpoints.byId(maintenanceId);
		return httpRequests.delete(endpoint);
	}
};