import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import FaultReportApi from '../../../../app/api/FaultReportApi';
import { MainContent } from '../../../common/layout';
import { ContentBox, ErrorBox, DataStatusMessage } from '../../../common/uiElements';
import FaultReport from './subComponents/FaultReport';

class FaultReportAddNewContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			faultReport: {},
			isLoadingPicture: false,
			error: null,
			isSaving: false,
			isSaved: false
		};

		this.handleChange = (field, value) => {
			const { faultReport } = this.state;
			
			if (field === 'reportedVehicleId') {
				faultReport.reportedTypeId = '';
				faultReport.reportedTypeOption = '';
			} else if (field === 'reportedTypeId') {
				faultReport.reportedTypeOption = '';
			}

			this.setState({
				faultReport: {
					...faultReport,
					[field]: value
				}
			});
		};

		this.handleLoadingPicture = (isLoadingPicture) => {
			this.setState({ isLoadingPicture });
		};

		this.handleAddNew = () => {
			this.setState({
				faultReport: {},
				isSaving: false,
				isSaved: false
			});
		};

		this.handleSave = () => {
			if (window.$(this.toValidate).find('form').valid()) {
				this.setState({ isSaving: true, error: null });

				let faultReport = window.$.extend(true, {}, this.state.faultReport);
                
				let attachedFiles = [];

				faultReport.dtoSignatureFileIndex = attachedFiles.length;
				attachedFiles.push(faultReport.signature);

				let FaultReportPhotos = faultReport.FaultReportPhotos || [];
				for (let j = 0; j < FaultReportPhotos.length; j++) {
					let faultPhotoObject = FaultReportPhotos[j];
					if (faultPhotoObject.file) {
						let { file } = faultPhotoObject;
						faultPhotoObject.dtoFileIndex = attachedFiles.length;
						attachedFiles.push(file);
						delete faultPhotoObject.dataURL;
						delete faultPhotoObject.file;
					}
				}

				const dataToSend = {
					...faultReport,
					attachedFiles
				};
                
				FaultReportApi.saveFaultReport(dataToSend)
					.then(() => {
						this.setState({ isSaved: true });
					})
					.catch(error => {
						console.error(error);
						this.setState({ isSaving: false, error });
					});
			}
		};
	}
    
	render() {
		const s = this.state;

		return (
			<MainContent title="Fault Report - Add New">
				{
					s.isSaved || s.isSaving ? (
						<div>
							{
								s.isSaved && (
									<Button 
										block
										bsSize="lg" 
										bsStyle="success" 
										onClick={this.handleAddNew}
									>
										<Icon name="plus"/> Add a New Fault Report
									</Button>
								)
							}
							<br />
							<DataStatusMessage status={s.isSaved ? 'success' : 'saving'} />
						</div>
					) : (
						<div>
							{ s.error && <ErrorBox error={s.error} retryFunc={this.handleSave} /> }
							<ContentBox color="primary">
								<div ref={ref => this.toValidate = ref}>
									<FaultReport 
										faultReportData={s.faultReport}
										showAllFields={true}
										onChange={this.handleChange}
										onLoadPicture={this.handleLoadingPicture}
									/>
								</div>
							</ContentBox>
							{
								!s.error && s.faultReport.reportedVehicleId && (
									<Button 
										block
										disabled={s.isLoadingPicture}
										bsSize="lg"
										bsStyle="success" 
										onClick={this.handleSave}
									>
										<Icon name="floppy-o" /> Save Fault Report
									</Button>
								)
							}
						</div>
					)
				}
			</MainContent>
		);
	}
}

export default FaultReportAddNewContainer;