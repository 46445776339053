import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { Checkbox, CheckboxList, TimeInput } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';
import { weekDaysShort } from '../../../../../../app/store/weekDays';

const validations = {
	startTime: 'required',
	endTime: 'required',
	curfewTypeId: 'required',
	daysOfWeek: 'required'
};

const validationMessages = {
	daysOfWeek: 'Select at least one day of the week'
};

export default class AccessWindowModalForm extends Component {
	static propTypes = {
		show: PropTypes.bool,
		onSave: PropTypes.func,
		onCancel: PropTypes.func
	}

	constructor() {
		super();

		this.defaultState = {
			selectedDaysOfWeek: [],
			startTime: '',
			endTime: '',
			isAttended: true,
			isMonToFri: false,
			comments: ''
		};

		this.state = { ...this.defaultState };
	}

	componentDidUpdate(prevProps) {
		if (this.props.show && prevProps.show !== this.props.show)
			this.resetForm();
	}

	resetForm() {
		this.setState({ 
			...this.defaultState,
			selectedDaysOfWeek: []
		});
	}
	
	checkIsMonToFri = () => {
		const { selectedDaysOfWeek } = this.state;
		return selectedDaysOfWeek.length === 5 && selectedDaysOfWeek.indexOf(6) === -1 && selectedDaysOfWeek.indexOf(7) === -1;
	}

	handleClickSave = () => {
		this.form.submit();
	}

	handleClickCancel = () => {
		this.props.onCancel && this.props.onCancel();
	}

	handleChangeDayOfWeek = selectedDaysOfWeek => {
		this.setState({ selectedDaysOfWeek });
	}

	handleChangeInput = e => {
		const { id, value, checked, type } = e.target;
		const isCheckbox = type === 'checkbox';

		this.setState({ [id]: isCheckbox ? checked : value });
	}

	handleChangeTime = e => {
		const { id, value } = e.target;

		this.setState({ [id]: value });
	}

	handleChangeMonFri = e => {
		this.setState({ selectedDaysOfWeek: e.target.checked ? [1, 2, 3, 4, 5] : [] });
	}

	handleSubmit = () => {
		const { startTime, endTime, isAttended, selectedDaysOfWeek, comments } = this.state;

		const accessWindowsObject = selectedDaysOfWeek.map(dayOfWeek => ({
			dayOfWeek,
			startTime,
			endTime,
			isAttended,
			comments
		}));

		this.props.onSave && this.props.onSave(accessWindowsObject);
	}

	render() {
		const { state, props } = this;

		return (
			<Modal show={props.show} onHide={this.handleClickCancel}>
				<Modal.Header closeButton>
					<Modal.Title>
						Add New Access Window
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form 
						ref={ref => this.form = ref} 
						validations={validations}
						messages={validationMessages}
						onSubmit={this.handleSubmit}
					>
						<FormGroup>
							<label>Day Of Week *</label>
							<FormGroup>
								<Checkbox
									checked={this.checkIsMonToFri()}
									text="Mon - Fri" 
									onChange={this.handleChangeMonFri}
								/>
							</FormGroup>
							<CheckboxList 
								value={state.selectedDaysOfWeek}
								items={weekDaysShort.map((dayOfWeek, index) => ({ text: dayOfWeek, value: index + 1 }))}
								onChange={this.handleChangeDayOfWeek}
							/>
							<FormControl id="daysOfWeek" type="hidden" value={state.selectedDaysOfWeek.length > 0 ? 'ok' : ''} />
						</FormGroup>
						<Row>
							<Col sm={4}>
								<FormGroup>
									<label>Start Time *</label>
									<TimeInput 
										id="startTime"
										value={state.startTime}
										onChange={this.handleChangeTime}
									/>
								</FormGroup>
							</Col>
							<Col sm={4}>
								<FormGroup>
									<label>End Time *</label>
									<TimeInput 
										id="endTime"
										value={state.endTime}
										onChange={this.handleChangeTime}
									/>
								</FormGroup>
							</Col>
							<Col sm={4}>
								<FormGroup>
									<label>Attended</label>
									<Checkbox 
										id="isAttended"
										checked={state.isAttended}
										onChange={this.handleChangeInput}
									/>
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<label>Comments</label>
							<FormControl
								id="comments" 
								maxLength="1000" 
								componentClass="textarea"
								value={state.comments} 
								onChange={this.handleChangeInput} 
							/>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button className="pull-left" onClick={this.handleClickSave} bsStyle="success">Save</Button>
					<Button onClick={this.handleClickCancel} bsStyle="danger">Cancel</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
