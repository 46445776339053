import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Loader, ContentBox } from './';

const propTypes = {
/** The status of the message */
	status: PropTypes.oneOf(['saving', 'success', 'error']),
	savingText: PropTypes.string,
	errorText: PropTypes.string,
	successText: PropTypes.string,

	/** If true, shows the default status message */
	showMessage: PropTypes.bool,

	/** If true, wrap the content inside a ContentBox with the status color */
	showBox: PropTypes.bool,
};

const defaultProps = {
	status: 'success',
	savingText: 'Saving the data. Please wait...',
	errorText: 'The data could not be saved',
	successText: 'The data was successfully saved',
	showMessage: true,
	showBox: true
};

const DataStatusMessage = ({
	status,
	savingText,
	errorText,
	successText,
	showMessage,
	showBox
}) => {
	const colorClass = status === 'saving' ? 'primary' : (status === 'error' ? 'danger' : 'success');

	const renderContent = () => {
		const textColorClass = `text-${colorClass}`;
		return (
			<div className="text-center">
				<h4>
					{
						status === 'saving' ? (
							<Loader text={savingText} />
						) : (
							<Icon 
								size="3x" 
								className={textColorClass}
								name={ status === 'error' ? 'times-circle' : 'check-circle' } 
							/>
						)
					}
				</h4>
				{ 
					status !== 'saving' && showMessage && (
						<h4 className={textColorClass}>
							{ status === 'error' ? errorText : successText }
						</h4>
					) 
				}
			</div>
		);
	};

	if (showBox)
		return (
			<ContentBox color={colorClass}>
				{ renderContent() }
			</ContentBox>
		);

	return renderContent();
};

DataStatusMessage.propTypes = propTypes;
DataStatusMessage.defaultProps = defaultProps;

export { DataStatusMessage };