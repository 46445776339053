import React from 'react';
import { RadioButtonList } from './';

const YesNoRadio = (props) => {
	return (
		<RadioButtonList 
			{...props}
			items={[{text: 'Yes', value: true}, {text: 'No', value: false}]}
		/>
	);
};

export { YesNoRadio };