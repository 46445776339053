export const VehiclesByPlaceSettings= {
	rangeMetersMin: 100,
	rangeMetersMax: 1000,
	rangeMetersPips: [100, 200, 400, 600, 800, 1000],
	rangeMetersStartValue: 100,
	dateStartMinutes: -120,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 2880
};

export const ServicingCountersSettings= {
	dateStartMinutes: -10080,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 44640
};

export const JourneyReportSettings= {
	dateStartMinutes: -120,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 2880
};

export const ExceptionsReportSettings= {
	dateStartMinutes: -120,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 2880
};

export const SpeedingReportSettings= {
	dateStartMinutes: -120,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 2880
};

export const PreStartSettings= {
	odometerKmGapMax: 50,
	odometerKmGapMin: 50,
	freezerHoursGapMax: 20,
	freezerHoursGapMin: 20
};

export const JourneyStepsReportSettings= {
	dateStartMinutes: -1440,
	dateFinishMinutes: 0,
	periodLimitInMinutes: 10080
};