import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { vehicleIconNames, drawRotatedIcon } from '../../../../app/helpers/vehicles';
import { VehicleTypes } from '../../../../app/models/Vehicle';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

const propTypes = {
	itemRef: PropTypes.func,
	vehTypeID: PropTypes.number, 
	vehFleetNumber: PropTypes.string, 
	position: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	id: PropTypes.number,
	trkPositionLat: PropTypes.number, 
	trkPositionLong: PropTypes.number, 
	trkGpsHeading: PropTypes.number,
	vehLowBatteryEventId: PropTypes.number, 
	trkUnitIsExternalPowerOff: PropTypes.bool, 
	trkIsEngineOn: PropTypes.bool,
	onClick: PropTypes.func
};

class VehicleMarker extends Component {
	shouldComponentUpdate(nextProps) {
		if (nextProps.id === this.props.id)
			return false;
		
		return true;
	}
	
	renderIcon = () => {
		const { 
			vehTypeID,
			trkUnitIsExternalPowerOff,
			vehLowBatteryEventId,
			trkIsEngineOn,
			trkGpsHeading
		} = this.props;

		let mapIconName = '';
	
		switch(vehTypeID) {
		case VehicleTypes.PALLET_JACK:
			if (trkUnitIsExternalPowerOff)
				mapIconName = vehicleIconNames.PALLET_JACK_CHARGING;
			else if (vehLowBatteryEventId)
				mapIconName = vehicleIconNames.PALLET_JACK_LOW_BATTERY;
			else
				mapIconName = vehicleIconNames.PALLET_JACK_BATTERY_OK;
			break;
		case VehicleTypes.RIGID:
			if (trkIsEngineOn === true)
				mapIconName = vehicleIconNames.RIGID_ENGINE_ON;
			else
				mapIconName = vehicleIconNames.RIGID;
			break;
		case VehicleTypes.PRIME_MOVER:
			if (trkIsEngineOn === true)
				mapIconName = vehicleIconNames.PRIME_MOVER_ENGINE_ON;
			else
				mapIconName = vehicleIconNames.PRIME_MOVER;
			break;
		case VehicleTypes.TRAILER:
			mapIconName = vehicleIconNames.TRAILER;
			break;
		case VehicleTypes.B_DOUBLE:
			mapIconName = vehicleIconNames.B_DOUBLE;
			break;
		case VehicleTypes.UTE:
			mapIconName = vehicleIconNames.UTE;
			break;
		default:
			break;
		}
		
		let icon = undefined;
		if (mapIconName)
			icon = {
				url: drawRotatedIcon(trkGpsHeading, mapIconName),
				labelOrigin: new window.google.maps.Point(25, 55),
				scaledSize: new window.google.maps.Size(50, 50)
			};

		return icon;
	}

	render() {
		const p = this.props;

		return (
			<MarkerWithLabel 
				icon={this.renderIcon()}
				labelAnchor={new window.google.maps.Point(10, 4)}
				labelStyle={{
					fontSize: '13px',
					fontWeight: 'bold'
				}}
				position={p.position} 
				zIndex={2}
				onClick={p.onClick}
			>
				<label>
					{ p.vehFleetNumber }
				</label>
			</MarkerWithLabel>
		);
	}
}

VehicleMarker.propTypes = propTypes;

export default VehicleMarker;
