import moment from 'moment';
import React from 'react';
import ReactTable, { Column, RowInfo } from 'react-table';
import { IPallet } from '../../../../../app/models/Pallet';
import FontAwesome from 'react-fontawesome';
import { Callout } from '../../../../common/uiElements';
import { Badge, Button } from 'react-bootstrap';

interface IProps {
	pallets: IPallet[];
	isConsolidatedPallet?: boolean;
	isAddingConsolidatedPalletFromList?: boolean;
	isReadOnly?: boolean;

	hideStore?: boolean;
	locationInTrailer?: boolean;
	hideLoadedAt?: boolean;

	onAddPallet?: () => void;
	onSelectPallet: (pallet: IPallet, rowIndex: number) => void;
	onConsolidateExistingPallet?: (pallet: IPallet) => void;
	onRemoveConsolidatedPallet?: (pallet: IPallet) => void;
	onDeletePallet: (pallet: IPallet) => void;
}

const PalletsList: React.FC<IProps> = props => (
	<>
		<Callout 
			icon="info-circle" 
			color="info" 
			title={`Tap on the pallet to ${props.isAddingConsolidatedPalletFromList ? 'change consolidation' : 'edit'}`}
		/>
		{
			/* Add a pallet to the trailer */
			(!props.isConsolidatedPallet && !props.isAddingConsolidatedPalletFromList) && (
				<Button block bsStyle="success" style={{ marginBottom: 10}}
					onClick={() => { props.onAddPallet && props.onAddPallet() }}>
					Add new Pallet
				</Button>
			)
		}
		<ul className="list-inline">
			<li><Badge className="warning">ID Manually Entered</Badge></li>
		</ul>
		<ReactTable
			minRows={0}
			pageSize={props.pallets.length}
			showPagination={false}
			data={props.pallets}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				// Disable row selection for the DELETE column
				if (!rowInfo || ['delete'].includes(column?.id || '')) return {};

				const pallet = rowInfo.original as IPallet;
				return {
					onClick: () => {
						// If we are adding a consolidated pallet from the list, consolidate the pallet
						if (props.isAddingConsolidatedPalletFromList && !pallet.isUpstacked) {
							return props.onConsolidateExistingPallet && props.onConsolidateExistingPallet(pallet);
						}
						
						// If the pallet is upstacked, remove it from the consolidated list
						if(props.isAddingConsolidatedPalletFromList && pallet.isUpstacked) {
							return props.onRemoveConsolidatedPallet && props.onRemoveConsolidatedPallet(pallet);
						} 
						
						// Otherwise, select the pallet
						props.onSelectPallet && props.onSelectPallet(pallet, rowInfo.index) 
				}
				}
			}}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo) return {};

				const pallet = rowInfo.original as IPallet;
				return {
					className: pallet.isDeleted ? 'red line-through text-italic text-bold' : pallet.manuallyEntered ? 'orange' : '',
				}
			}}
			columns={[
				{
					Header: '#',
					id: 'order',
					width: 30,
					show: !props.isAddingConsolidatedPalletFromList,
					Cell: row => row.index + 1
				},
				{
					Header: 'Action',
					id: 'editing',
					width: 52,
					className: 'text-center',
					show: props.isAddingConsolidatedPalletFromList,
					Cell: row => {
						const pallet = row.original as IPallet;
						return (
							<FontAwesome 
								name={`${(props.isAddingConsolidatedPalletFromList && pallet.isUpstacked) ? 'minus' : 'plus'}`} 
								title="Consolidate Pallet" 
								className={`cursor-pointer text-${pallet.isUpstacked ? 'danger' : 'success'}`} 
								style={{ fontSize: 18 }} 
							/>
						)
					}
				},
				{
					Header: 'Store',
					accessor: 'store',
					show: !props.hideStore,
					Cell: row => !row.value ? 'Unknown Store' : (row.value.storeNumber ? row.value.storeNumber + ' - ' : '' ) + row.value.name,
				},
				{
					Header: 'Pallet ID',
					id: 'palletId',
					width: 150,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.palletId;

						return (
							<>
								{pallet.palletId}
								<br />
								<span>
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<span className={palletUpstacked.wasPalletTimberKept ? 'text-danger' : 'text-info'} key={palletUpstacked.palletId}>
												{ palletUpstacked.palletId } ({ palletUpstacked.wasPalletTimberKept ? 'Forked Up' : 'Upstacked' })
												<br />
											</span>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Commodity',
					id: 'commodity',
					width: 90,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.commodity;

						return (
							<>
								{pallet.commodity}
								<br />
								<span className="text-muted">
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<React.Fragment key={palletUpstacked.palletId}>
												{ palletUpstacked.commodity }
												<br />
											</React.Fragment>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Type',
					id: 'palletType',
					width: 70,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.palletType || 'N/A';

						return (
							<>
								{pallet.palletType || 'N/A'}
								<br />
								<span className="text-muted">
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<React.Fragment key={palletUpstacked.palletId}>
												{ palletUpstacked.palletType || 'N/A' }
												<br />
											</React.Fragment>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Location',
					accessor: 'locationInTrailer',
					width: 70,
					show: !props.locationInTrailer,
					Cell: row => row.value ? row.value === "L" ? "LEFT" : "RIGHT" : '-'
				},
				{
					Header: 'Loaded At',
					accessor: 'loadedOn',
					width: 75,
					show: !props.hideLoadedAt,
					Cell: row => row.value ? moment(row.value).format('HH:mm') :  '-'
				},
				
				{
					Header: 'Loaded By',
					accessor: 'loadedBy',
					Cell: row => !row.value ? '-' : row.value ? row.value.name : '',
				},
				{
					Header: 'Consolidation',
					accessor: 'wasPalletTimberKept',
					show: props.isConsolidatedPallet,
					Cell: row => row.value ? 'Forked Up' : 'Upstacked',
				},
				{
					show: !props.isReadOnly,
					id: 'delete',
					className: 'text-center',
					width: 35,
					accessor: d => d,
					Cell: row => {
						const pallet = row.value as IPallet;

						return (
							<FontAwesome 
								name={pallet.isDeleted ? "undo" : "trash"} 
								title={pallet.isDeleted ? "Undo Deletion" : "Delete Pallet"}
								className={`cursor-pointer text-${pallet.isDeleted ? "info" : "red"}`}
								style={{ fontSize: 18 }} 
								onClick={() => props.onDeletePallet(pallet)}
							/>
						)
					},
				}
			]}
		/>
	</>
);



export default PalletsList;