/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { Modal, FormGroup, Button } from 'react-bootstrap';
import { Form, ErrorBox } from '../uiElements';
import WorkerApi from '../../../app/api/WorkerApi';
import emailsList from '../../../app/store/emailsList.json';

const propTypes = {
	loggedUserId: PropTypes.string, 
	loggedUserName: PropTypes.string
};

class MessageBoxLink extends Component {
	constructor() {
		super();
		this.state = {
			isSending: false,
			showModal: false,
			error: null
		};

		this.validations = {
			department: 'required',
			message: 'required',
		};
	}

	handleOpenModal = () => {
		this.setState({ showModal: true });
	};

	handleCloseModal = () => {
		if (!this.state.isSending) {
			this.department.value = '';
			this.message.value = '';
			this.setState({ showModal: false, error: null });
		}
	};

	handleSubmit = () => {
		if (!window.$(this.messageForm).find('form').valid())
			return;
				
		this.setState({ isSending: true, error: null });
		const { loggedUserId, loggedUserName } = this.props;
		const department = this.department.value;
		const message = this.message.value;
		
		WorkerApi.sendEmail(loggedUserId, loggedUserName, department, message)
			.then(() => {
				this.department.value = '';
				this.message.value = '';
				toastr.success('Your message was successfully sent', 'Success');
				this.setState({ showModal: false, isSending: false, error: null });
			})
			.catch(error => {
				return this.setState({ isSending: false, error });
			});
	};
    
	render() {
		const s = this.state;

		return (
			<Fragment>
				<a className="cursor-pointer" onClick={this.handleOpenModal} title="Send a message">
					<Icon name="envelope-o" />
				</a>
				<Modal bsSize="small" show={s.showModal} onHide={this.handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>Send a message</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div ref={ref => this.messageForm = ref}>
							<Form validations={this.validations}>
								<FormGroup>
									<label>Department *</label>
									<select ref={ref => this.department = ref} id="department" className="form-control">
										<option value="">--- SELECT ---</option>
										{
											emailsList.map((email, index) => <option key={index} value={email.value}>{ email.text }</option>)
										}
									</select>
								</FormGroup>
								<FormGroup>
									<label>Message *</label>
									<textarea ref={ref => this.message = ref} id="message" className="form-control" rows="5" maxLength="1000" />
								</FormGroup>
							</Form>
							{ s.error && <ErrorBox error={s.error} retryFunc={this.handleSubmit} /> }
						</div>
					</Modal.Body>
					{
						!s.error && (
							<Modal.Footer>
								<Button disabled={s.isSending} block bsStyle="success" onClick={this.handleSubmit}>
									{
										s.isSending ? (
											<span>Sending...</span>
										) : (
											<span>Submit <Icon fixedWidth name="send"/> </span>
										)
									}
								</Button>
							</Modal.Footer>
						)
					}
				</Modal>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({ 
	loggedUserId: state.user.data.azureId,
	loggedUserName: state.user.data.fullName 
});

MessageBoxLink.propTypes = propTypes;

const MessageBoxLinkComponent = connect(mapStateToProps)(MessageBoxLink);
export { MessageBoxLinkComponent as MessageBoxLink };