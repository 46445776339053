import React, { ChangeEvent, Fragment } from 'react';
import Select from 'react-select';
import { FormControl, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import ListsApi from '../../../app/api/ListsApi';
import { AustralianState, IError, ColorsBootstrap } from '../../../app/models/Application';
import { IVehicleBasicInfo, IVehicleDetailsForTask } from '../../../app/models/Vehicle';

interface IProps extends Omit<FormControlProps, 'onChange'> {
	showInactive?: boolean;
	vehTypeFilter?: number[];
	stateFilter?: AustralianState[];
	subContractorAbn?: string;
	excludeFleetNumbers?: string[];
	color?: ColorsBootstrap;
	normalSelectInput?: boolean;
	onChange?: (vehicle?: IVehicleBasicInfo, event?: ChangeEvent<FormControl & HTMLInputElement>) => void;
}

interface IState {
	vehiclesList: IVehicleDetailsForTask[];
	isLoading: boolean;
	error?: IError;
}

class VehicleSelect extends React.Component<IProps, IState> {
	state = {
		vehiclesList: [] as IVehicleDetailsForTask[],
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchVehiclesList();
	}
	

	fetchVehiclesList = () => {
		const { showInactive, vehTypeFilter, stateFilter, subContractorAbn } = this.props;
		const promise = ListsApi.getVehiclesList.bind(this, !showInactive, vehTypeFilter, stateFilter, subContractorAbn);
		componentRequestHandler(this, promise, 'vehiclesList');
	}


	handleChange = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const vehicle = this.state.vehiclesList.find((p: IVehicleBasicInfo) => p.fleetNumber === e.target.value)
		this.props.onChange && this.props.onChange(vehicle, e);
	}

	handleChangeSearchInput = (newValue: any) => {
		const fleetNumber = newValue?.value;
		const Vehicle = this.state.vehiclesList.find((p: IVehicleBasicInfo) => p.fleetNumber === fleetNumber);
		this.props.onChange && this.props.onChange(Vehicle);
	}

	renderSearchInputContent = () => {
		let filteredVehiclesList = this.state.vehiclesList;

		if (this.props.excludeFleetNumbers)
			filteredVehiclesList = filteredVehiclesList.filter(vehicle => !this.props.excludeFleetNumbers!.includes(vehicle.fleetNumber));

		return filteredVehiclesList.map((data: IVehicleBasicInfo) => ({
			value: data.fleetNumber,
			label: data.fleetNumber + (data.regoNumber ? ` - ${data.regoNumber}` : ''),
		}))
	}

	renderSelectContent = () => {
		const { vehiclesList, isLoading, error } = this.state;
		
		let firstOptionText = '--- SELECT ---';

		if (isLoading)
			firstOptionText = 'Loading vehicles. Please wait...';
		
		if (error)
			firstOptionText =  'It was not possible to retrieve the vehicles.';

		return [(
		<option value="" key={-1}>{ firstOptionText }</option>
		),
		vehiclesList.map((vehicle: IVehicleDetailsForTask) => (
			<option key={vehicle.fleetNumber} value={vehicle.fleetNumber}>
				{ vehicle.fleetNumber }{ vehicle.regoNumber ? ` - ${vehicle.regoNumber}` : '' }
			</option>
		))
	]
	};

	render() {
		const { props } = this;

		if (props.normalSelectInput)
			return (
				<FormControl
					componentClass="select"
					id={props.id}
					className={classNames(
						props.className, 
						{ [`form-control-${props.color}`]: props.color } 
					)}
					value={props.value}
					bsSize={props.bsSize}
					disabled={props.disabled}
					onChange={this.handleChange as any}
				>
				{ this.renderSelectContent() }
				</FormControl>
			)
			
		return (
			<Fragment>
				<Select 
					className={props.className}
					disabled={props.disabled}
					name={props.id}
					value={props.value as any}
					options={this.renderSearchInputContent()}
					onChange={this.handleChangeSearchInput as any}
					placeholder={props.placeholder || 'Search Vehicle...'}
					// noResultsText={props.noResultsText}
				/>
				<input id={props.id} type="hidden" value={props.value} />
			</Fragment>
		);
	}
}

export { VehicleSelect };