import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import FaultReportListContainer from '../../FaultReportListContainer';
import VehicleDamages from '../../../../vehicles/VehicleDamages';

const propTypes = {
	faultReportItems: PropTypes.array,
	fleetNumbers: PropTypes.arrayOf(PropTypes.string),
	isGuideMode: PropTypes.bool,
	addGuideSteps: PropTypes.func
};

const guideSteps = [
	{
		title: 'Step 2 - Opened Fault Reports & Damages',
		text: 'All the opened issues of the selected vehicles will be shown here',
		selector: '.pre-start__opened-fault-reports-main',
		position: 'top'
	},
	{
		title: 'Opened Fault Reports',
		text: 'If the Fault is listed you do not need to report it again',
		selector: '.pre-start__opened-fault-reports',
		position: 'top'
	},
	{
		title: 'Reported Vehicle Damages',
		text: 'Damages shown below have already been reported. Only report new damages',
		selector: '.pre-start__vehicle-damages',
		position: 'top',
		isLastStep: true
	}
];

class ProblemsFirstList extends Component {
	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);
	}
	
	render() {
		const p = this.props;
		
		return (
			<div className="pre-start__opened-fault-reports-main">
				<div className="pre-start__opened-fault-reports">
					<h3 className="title">
						<Icon name="warning" /> Opened Fault Reports
					</h3>
					<FaultReportListContainer 
						faultReportItems={p.faultReportItems && p.faultReportItems.filter(p => p.id)}
						fleetNumbers={p.fleetNumbers} 
						isReadOnly={true}
						onChange={(FaultReports) => { p.onChange(FaultReports, 'FaultReports'); }}
					/>
				</div>
				<div className="pre-start__vehicle-damages">
					<h3 className="title">
						<Icon name="warning" /> Vehicle Damages
					</h3>
					<VehicleDamages 
						fleetNumber={p.fleetNumbers} 
						showOnlyOpenedDamages={true}
						shouldFetchData={p.isGuideMode ? false : true} />
				</div>
			</div>
		);
	}
}

ProblemsFirstList.propTypes = propTypes;

export default ProblemsFirstList;