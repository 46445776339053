export default {
	/** Geofence */
	root: '/Geofence',
	byId: (geofenceId: number) => `/Geofence/${geofenceId}`,
	
	/** Places */
	place: '/Place',
	unlinkedPlaces: '/Place/Unlinked',
	geofencePlaces: (geofenceId: number) => `/Geofence/${geofenceId}/Place`,
	geofencesByStoreNumber: (storeNumber: string) => `/Geofence/Store/${storeNumber}`,
	placeById: (placeId: number) => `/Place/${placeId}`,

	/** Access Point  */
	mudMapFile: (placeAccessPointId: number) => `/AccessPoint/${placeAccessPointId}/mudMapFile`,
	accessWindows: (placeAccessPointId: number) => `/AccessPoint/${placeAccessPointId}/Window`,
	accessWindowsByCommodity: (placeAccessPointId: number, placeCategoryCommodityLinkId: number) => `/AccessPoint/${placeAccessPointId}/Window/${placeCategoryCommodityLinkId}`,
	curfews: (placeAccessPointId: number) => `/AccessPoint/${placeAccessPointId}/Curfew`,
};