import toastr from 'toastr';

class IOptions {
	defaultValue?: any;
	loadingAttrName?: string;
	errorAttrName?: string;
	showSuccessNotification?: boolean;
}

/**
 * Handle the requests and responses for any data requests managing the loading and errors on the state of the component
 * @param pComponent The component you are working on
 * @param pPromise The promise you want to handle
 * @param pValueAttrName The attribute name in the state of the component you want to populate the returned data
 * @param [pOptions] 
 * @param [pOptions.defaultValue=null] The default value you want to be set before the request
 * @param [pOptions.loadingAttrName=isLoading] The loading attribute name in the state
 * @param [pOptions.errorAttrName=error] The error attribute name in the state
 * @param [pOptions.showSuccessNotification=error] Should show notification toastr message
 * @returns {Promise} Returns a promise containing either the result or the error
 */
export default function(pComponent: any, pPromise: Function, pValueAttrName?: string, pOptions: IOptions = getDefaultConfig()): Promise<any> {
	if (!pComponent)
		throw new Error('Component cannot be null');
	else if (typeof pPromise === 'object')
		throw new Error('The promise is an object. It has to be a promise function or a function returning a promise');


	let { defaultValue, loadingAttrName, errorAttrName } = pOptions;
	pComponent.componentWillUnmount = () => pComponent.unmounted = true;

	if (!defaultValue && pValueAttrName)
		if (Array.isArray(pComponent.state[pValueAttrName]))
			defaultValue = [];

	let state = {
		[loadingAttrName!]: true,
		[errorAttrName!]: undefined
	};

	if (pValueAttrName) state = { ...state }

	pComponent.setState(state);

	return new Promise((resolve, reject) => {
		return pPromise()
			.then((result: any) => {
				if (pOptions.showSuccessNotification)
					toastr.success('Successfully saved!', 'Success');

				if(pComponent.unmounted) 
					return;

				let state = { [loadingAttrName!]: false };
				if (pValueAttrName) state = { ...state, [pValueAttrName]: result }

				pComponent.setState(state, () => resolve(result));
			})
			.catch((error: any) => {
				toastr.error('Try again or contact TMS Support if the error persists.', 'Sorry, something went wrong :(');
				
				if(pComponent.unmounted) 
					return;

				pComponent.setState({ 
					[errorAttrName!]: error, 
					[loadingAttrName!]: false,
				}, () => reject(error));
			});
	});
}

function getDefaultConfig(pComponent?: any, pValueAttrName?: string) {
	return {
		// defaultValue: pComponent && pValueAttrName ? pComponent.state[pValueAttrName] : undefined, 
		loadingAttrName:'isLoading', 
		errorAttrName: 'error'
	};
}