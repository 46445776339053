import React from 'react';
import PropTypes from 'prop-types';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';

export const CommodityTypesLinksList = props => (
	<ListContainer 
		{...props}
		promise={() => ListsApi.getCommodityTypesLinksList(props.placeCategoryId)}
	/>
);

CommodityTypesLinksList.propTypes = {
	id: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	placeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	bsSize: PropTypes.string,
	onChange: PropTypes.func
};