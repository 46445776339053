import React from 'react';
import PropTypes from 'prop-types';

import { MainContent } from '../../../common/layout';
import PreStartViewDetails from './subComponents/PreStartViewDetails';

const PreStartViewContainer = props => (
    <MainContent title="Pre-Start Details">
        <PreStartViewDetails preStartId={props.params.preStartId} />
    </MainContent>
);

PreStartViewContainer.propTypes = {
    params: PropTypes.shape({
        preStartId: PropTypes.string.isRequired
    }).isRequired
};

export default PreStartViewContainer;