import { ILeaveRequestListFilter } from '../models/LeaveRequest';
import leaveRequestEndpoints from './endpoints/leaveRequestEndpoints';
import httpRequests from './httpRequests';

export default {
	/**
     * Get Leave Requests List
     */
		getLeaveRequestsList(filters: string, onlyActive?: boolean) {
			const endpoint = leaveRequestEndpoints.root;
					
			if (filters)
				filters = JSON.stringify(filters);
	
			return httpRequests.get(endpoint, { filterString: filters, onlyActive });
		},

		/**
		 * Export the Worker List to a file
		 */
		getLeaveRequestsListReportFile(filterString: ILeaveRequestListFilter) {
			const endpoint = leaveRequestEndpoints.exportReport;

			return httpRequests.downloadFile(endpoint, { filterString: JSON.stringify(filterString) });
		},
	
		/**
			 * Get Leave Request Details
			 */
		getLeaveRequestDetails(leaveRequestId: number) {
			const endpoint = leaveRequestEndpoints.leaveRequestById(leaveRequestId);
			return httpRequests.get(endpoint);
		},
	
	
		/**
			 * Save a new Leave Request
			 * @param data LeaveRequest data object
			 */
		saveNewLeaveRequest(data: object) {
			const endpoint = leaveRequestEndpoints.root;
			return httpRequests.post(endpoint, data);
		},
	
		/**
			 * Update a Leave Request
			* @param leaveRequestId LeaveRequest Id
			 * @param data LeaveRequest data object
			* @param isReview if it is a review
			 */
		updateLeaveRequest(leaveRequestId: number, data: object, isReview: boolean) {
			let endpoint = leaveRequestEndpoints.leaveRequestById(leaveRequestId);
			if (isReview) 
				endpoint += '/Review';
	
			return httpRequests.put(endpoint, data);
		},
	
		/**
			 * Cancel a Leave Request
			 * @param leaveRequestId
			 */
		cancelLeaveRequest(leaveRequestId: number) {
			const endpoint = leaveRequestEndpoints.leaveRequestById(leaveRequestId);
			return httpRequests.delete(endpoint);
		},
}