import React from 'react';
import { MainContent } from '../../../../common/layout';
import { FormGroup, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import RunTemplateDayOfWeekFilter from './list/DayOfWeekFilter';
import RunTemplateList from './list';
import { IRunTemplateListItem, RunTemplateMainFilter as RunTemplateMainFilterClass } from '../../../../../app/models/Run/RunTemplate';
import { IError } from '../../../../../app/models/Application';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { ErrorBox } from '../../../../common/uiElements';
import RunTemplateModal from './RunTemplateModal';
import RunTemplateApi from '../../../../../app/api/RunTemplateApi';

interface IState {
	filter: RunTemplateMainFilterClass,
	listData: IRunTemplateListItem[],
	selectedId?: number;
	selectedRowIndex?: number;
	showModal: boolean;
	isLoading: boolean;
	error?: IError;
}

class RunTemplate extends React.Component<{}, IState> {
	state = {
		filter: new RunTemplateMainFilterClass(),
		listData: [] as IRunTemplateListItem[],
		selectedId: undefined,
		selectedRowIndex: undefined,
		showModal: false,
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	fetchList = () => {
		const { filter } = this.state;

		const promise = () => RunTemplateApi.getList(filter);
		componentRequestHandler(this, promise, 'listData');
	}

	changeFilter = (fieldId: string, value: any) => {
		this.setState({
			filter: {
				...this.state.filter,
				[fieldId]: value
			}
		}, this.fetchList);
	}

	showModal = () => {
		this.setState({ showModal: true });
	}

	closeModal = () => {
		this.setState({ 
			showModal: false,
			selectedId: undefined
		});
	}

	handleChangeDayOfWeek = (dayOfWeek: number) => {
		this.changeFilter('dayOfWeek', dayOfWeek);
	}

	handleSelectRow = (selectedRunTemplateId: number) => {
		this.setState({ selectedId: selectedRunTemplateId }, this.showModal);
	}

	handleAddNewRun = () => {
		this.setState({ selectedId: undefined }, this.showModal);
	}

	handleSaveComplete = () => {
		this.fetchList();
		this.closeModal();
	}

	render() {
		const { state } = this;

		return (
			<MainContent title="Runs Master Template" className="run-template">
				{
					state.showModal && (
						<RunTemplateModal 
							runTemplateId={state.selectedId}
							onClose={this.closeModal}
							onSaveComplete={this.handleSaveComplete}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={this.handleAddNewRun}
					>
						<FontAwesome name="plus" /> Add New Run
					</Button>
				</FormGroup>
				<RunTemplateDayOfWeekFilter 
					dayOfWeek={state.filter.dayOfWeek}
					onChange={this.handleChangeDayOfWeek}
				/>
				<br />
				<FormGroup>
					<Button
						block
						bsStyle="info"
						onClick={this.fetchList}
					>
						<FontAwesome name="refresh" /> Refresh
					</Button>
				</FormGroup>
				{
					state.error ? (
						<ErrorBox error={state.error} retryFunc={this.fetchList} />
					) : (
						<RunTemplateList 
							records={state.listData}
							isLoading={state.isLoading}
							onSelectRow={this.handleSelectRow}
						/>
					)
				}
			</MainContent>
		);
	}
}

export default RunTemplate;