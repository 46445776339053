import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import WorkerApi from '../../../../../app/api/WorkerApi';
import { ErrorBox, Callout, Loader } from '../../../../common/uiElements';

const propTypes = {
	azureId: PropTypes.string.isRequired
};

class WorkerSendPin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSending: false,
			successfullySent: null,
			error: null,
		};
	}
	
	componentWillUnmount() {
		this.unmounted = true;
	}

	handleSendPin = () => {
		this.setState({ 
			isSending: true,
			error: null
		}, () => {
			WorkerApi.sendPin(this.props.azureId)
				.then(successfullySent => {
					if (this.unmounted) return;
					this.setState({ successfullySent });
				})
				.catch(error => {
					console.error(error);
					if (this.unmounted) return;
					this.setState({ error });
				})
				.then(() => {
					if (this.unmounted) return;
					this.setState({ isSending: false });
				});
		});
	}
	
	render() {
		const s = this.state;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.handleSendPin} />;

		if (s.isSending)
			return <Loader text="Sending..." />;

		if (s.successfullySent !== null) {
			return ( 
				<Callout color={s.successfullySent ? 'info' : 'danger'}>
					{
						s.successfullySent ? (
							"An SMS with the Username and PIN was sent to the worker's mobile number."
						) : (
							"The SMS couldn't be sent. Please, check if the worker has a valid mobile number."
						)
					}
				</Callout>
			);
		}

		return (
			<Button onClick={this.handleSendPin} block bsStyle="success">
					Send PIN via SMS
			</Button>
		);
	}
}

WorkerSendPin.propTypes = propTypes;

export default WorkerSendPin;