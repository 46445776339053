import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import InfoWinowContent from '../../common/InfoWindowContent';

const propTypes = {
	trkPositionLat: PropTypes.number.isRequired,
	trkPositionLong: PropTypes.number.isRequired,
	trkIsEngineOn: PropTypes.bool, 
	trksptIsOverSpeedLimit: PropTypes.bool, 
	trkSpeedKm: PropTypes.number, 
	trkGpsHeading: PropTypes.number,
	trkVehicleBattVolt: PropTypes.number,
	trkIsDoorOpen: PropTypes.bool,
	trkIsFreezer: PropTypes.bool,
	trkCollectedOnEpoch: PropTypes.number,
	trksptRoadSpeedLimit: PropTypes.number,
	trkTempZone1: PropTypes.number,
	trkTempZone2: PropTypes.number,
	trkTempZone3: PropTypes.number,
};

class VehicleBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoWindow: false
		};
	}

	getIcon = () => {
		const { 
			trkIsEngineOn, trksptIsOverSpeedLimit, 
			trkSpeedKm, trkGpsHeading 
		} = this.props;

		// The color of the point is based on the speed or if the truck is parked
		let iconColor = 'black';

		if (trkIsEngineOn === false) {
			iconColor = 'black';
		} else {
			if (trksptIsOverSpeedLimit) {
				iconColor = '#A30B00';
			}
			else if (trkSpeedKm <= 20) {
				iconColor = '#0C5200';
			}
			else if (trkSpeedKm <= 50) {
				iconColor = '#0091B2';
			}
			else if (trkSpeedKm <= 80) {
				iconColor = '#0064B2';
			}
			else if (trkSpeedKm <= 95) {
				iconColor = '#0046B2';
			}
			else {
				iconColor = '#A30B00';
			} 
		}

		return {
			path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			scale: 1.8,
			strokeColor: iconColor,
			rotation: parseInt(trkGpsHeading, 10)
		};
	}

	handleToggleInfoWindow = () => {
		this.setState({ showInfoWindow: !this.state.showInfoWindow });
	}

	render() {
		const p = this.props;
		const s = this.state;

		return (
			<Marker
				ref={p.refs}
				position={{ 
					lat: p.trkPositionLat, 
					lng: p.trkPositionLong 
				}}
				icon={this.getIcon()}
				onClick={this.handleToggleInfoWindow}
			>
				{
					s.showInfoWindow && (
						<InfoWindow onCloseClick={this.handleToggleInfoWindow}>
							<InfoWinowContent
								batteryVoltage={p.trkVehicleBattVolt} 
								isDoorOpen={p.trkIsDoorOpen} 
								isEngineOn={p.trkIsEngineOn} 
								isFreezerOn={p.trkIsFreezer}
								pingTime={p.trkCollectedOnEpoch}
								roadSpeedLimit={p.trksptRoadSpeedLimit}
								speed={p.trkSpeedKm}
								tempZone1={p.trkTempZone1}
								tempZone2={p.trkTempZone2}
								tempZone3={p.trkTempZone3} 
							/>
						</InfoWindow>
					)	
				}
			</Marker>
		);
	}
}

VehicleBreadcrumb.propTypes = propTypes;

export { VehicleBreadcrumb };