import React from 'react';
import PropTypes from 'prop-types';

import { MainContent } from '../../../common/layout';
import WorkerEdit from './subComponents/WorkerEdit';

const WorkersEditContainer = props => (
    <MainContent title="Edit Worker">
        <WorkerEdit azureId={props.params.azureId} />
    </MainContent>
);

WorkersEditContainer.propTypes = {
    params: PropTypes.shape({
        azureId: PropTypes.string.isRequired
    }).isRequired
};

export default WorkersEditContainer;