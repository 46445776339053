import React from 'react';
import Icon from 'react-fontawesome';
import { MainContent } from '../../../common/layout';
import { ContentBox, ErrorBox } from '../../../common/uiElements';
import { IError, IListItem, ListRequestOptions, ListReturn } from '../../../../app/models/Application';
import { SortingRule} from 'react-table';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import PalletUnloadList from './List/PalletUnloadList';
import PalletApi from '../../../../app/api/PalletApi';
import { Button, FormGroup } from 'react-bootstrap';
import { IPalletUnloadListItem, PalletsListFilter } from '../../../../app/models/Pallet';
import PalletUnloadListFilter from './List/PalletUnloadListFilter';
import { IVehicleBasicInfo } from '../../../../app/models/Vehicle';
import PalletUnloadDetailsModal from './Details/PalletUnloadDetailsModal';

interface IState {
	filter: PalletsListFilter;
	listRequestOptions: ListRequestOptions;
	listReturn: ListReturn<IPalletUnloadListItem>
	selectedUnloadId?: string;
	isLoading: boolean;
	isAddingNewLoad: boolean;
	error?: IError;
	isExporting: boolean;
	errorExporting: undefined;
}

class PalletUnloadContainer extends React.Component<{}, IState> {
	state = {
		filter: new PalletsListFilter(),
		listReturn: new ListReturn<IPalletUnloadListItem>(),
		listRequestOptions: new ListRequestOptions(),
		selectedUnloadId: new URLSearchParams(window.location.search).get('id') || undefined,
		isLoading: false,
		isAddingNewLoad: false,
		error: undefined,
		isExporting: false,
		errorExporting: undefined,
	}

	fetchList = () => {
		const { filter, listRequestOptions } = this.state;
		const promise = () => PalletApi.unloads.getUnloadsList(listRequestOptions, filter);
		componentRequestHandler(this, promise, 'listReturn');
	}

	changeFilter = (fieldId: string, value: any, fetch?: boolean) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				[fieldId]: value
			}
		}, () => fetch ? this.fetchList() : undefined )
	}

	changeListOption = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: {
				...this.state.listRequestOptions,
				[fieldId]: value
			}
		}, this.fetchList)
	}

	changeSelectedUnloadId = (selectedUnloadId?: string) => {
		// set query param id
		if (selectedUnloadId)
			window.history.pushState({}, '', `?id=${selectedUnloadId}`);
		else
			window.history.pushState({}, '', window.location.pathname);

		this.setState({ selectedUnloadId })
	}

	handleChangePage = (page: number) => {
		this.changeListOption('page', page);
	};

	handleChangePageSize = (pageSize: number) => {
		this.changeListOption('pageSize', pageSize);
	};

	handleChangeSorted = (sorted: SortingRule[]) => {
		this.changeListOption('sorted', sorted);
	};

	handleChangePeriod = (startTimeLocal: string, finishTimeLocal: string) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				startTimeLocal,
				finishTimeLocal
			}
		}, this.fetchList)
	}

	handleChangeRunNumber = (runNumber?: string) => {
		this.changeFilter('runNumber', runNumber);
	}

	handleChangeLoader = (loader?: IListItem) => {
		this.changeFilter('workerId', loader?.id || '', true);
	}

	handleChangeFleetNumber = (vehicle?: IVehicleBasicInfo) => {
		this.changeFilter('fleetNumber', vehicle?.fleetNumber, true);
	}

	handleChangeDepot = (depotId?: string) => {
		this.changeFilter('depotId', depotId || '', true);
	}

	handleChangeStatus = (status?: string) => {
		this.changeFilter('status', status || '', true);
	}

	handleAddNewUnload = () => {
		this.setState({ isAddingNewLoad: true })
	}

	handleSelectRow = (selectedUnloadId?: string) => {
		this.changeSelectedUnloadId(selectedUnloadId);
	}

	handleCloseDetailsModal = () => {
		this.setState({ isAddingNewLoad: false }, this.changeSelectedUnloadId)
	}

	handleCompleteSaving = (unloadId?: string) => {
		this.changeSelectedUnloadId(unloadId);
	}

	handleCompleteDeletion = () => {
		this.changeSelectedUnloadId(undefined);
		this.fetchList();
	}

	// TODO: Implement Export CSV
	// handleExport = () => {
	// 	const { filter } = this.state;
	// 	const promise = () => PalletUnloadingApi.getUnloadsListReportFile({ ...filter });
	// 	componentRequestHandler(this, promise, undefined, {
	// 		loadingAttrName: 'isExporting',
	// 		errorAttrName: 'errorExporting',
	// 	})
	// }

	render() {
		const { state } = this;
		
		return (
			<MainContent title="Pallets - Unloads">
				{
					(state.selectedUnloadId || state.isAddingNewLoad) && (
						<PalletUnloadDetailsModal
							unloadId={state.selectedUnloadId}
							isAddingNewTask={state.isAddingNewLoad}
							onClose={this.handleCloseDetailsModal}
							onCompleteSaving={this.handleCompleteSaving}
							onCompleteDeletion={this.handleCompleteDeletion}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						bsSize="lg"
						onClick={this.handleAddNewUnload}
					>
						<Icon name="plus" /> Add New Unload
					</Button>
				</FormGroup>
				<ContentBox title="Filters">
					<PalletUnloadListFilter 
						{...state.filter}
						onChangePeriod={this.handleChangePeriod}
						onChangeRunNumber={this.handleChangeRunNumber}
						onChangeLoader={this.handleChangeLoader}
						onChangeFleetNumber={this.handleChangeFleetNumber}
						onChangeStatus={this.handleChangeStatus}
						onChangeDepot={this.handleChangeDepot}
						onPressEnter={this.fetchList}
					/>
				</ContentBox>
				<FormGroup>
					<Button
						block
						bsStyle="info"
						onClick={this.fetchList}
					>
						<Icon name="search" /> Search
					</Button>
				</FormGroup>
				{
					state.error ? (
						<ErrorBox 
							error={state.error} 
							retryFunc={this.fetchList}
						/>
					) : (
						<PalletUnloadList 
							{...state.listRequestOptions}
							{...state.listReturn}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onSelectRow={this.handleSelectRow}
						/>
					)
				}
			</MainContent>
		)
	}
}

export default PalletUnloadContainer;