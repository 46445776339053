import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const propTypes = {
	hideOkButton: PropTypes.bool,
	title: PropTypes.string,
	children: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired,
};

const AlertModal = props => (
	<Modal bsSize="sm" show={true} onHide={props.onClose} backdrop="static">
		{
			props.title && (
				<Modal.Header>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
			)
		}
		<Modal.Body>
			{ props.children }
		</Modal.Body>
		{
			!props.hideOkButton && (
				<Modal.Footer>
					<Button block onClick={props.onClose} bsStyle="success">OK</Button>
				</Modal.Footer>
			)
		}
	</Modal>
);

AlertModal.propTypes = propTypes;

export { AlertModal };