import { ListRequestOptions } from "./Application";

export interface ILeaveRequestSimple {
	id: number;
	startDate: string;
	endDate: string;
	absenceReasonTypeId: string;
	absenceReasonTypeName: string;
	isApproved: boolean;
}

export interface ILeaveRequestListFilter extends ListRequestOptions {
	filterBy?: string;
	startDate?: string;
	endDate?: string;
	workerId?: string;
	status?: string;
	withPay?: boolean;
	isRevised?: boolean;
	hideAlreadyTaken?: boolean;
	typeId?: string;
	year?: number;
	week?: number;
}

export const leaveRequestsListExportColumns = [
	{ text: 'Employee', value: 'employee' },
	{ text: 'Employee Number', value: 'employeeNumber' },
	{ text: 'Position', value: 'positionTitle' },
	{ text: 'Current Contract', value: 'currentContract' },
	{ text: 'Drivers Licence CLass', value: 'driverLicenceClass' },
	{ text: 'Leave Type', value: 'leaveType' },
	{ text: 'Request Date', value: 'requestedOn' },
	{ text: 'Revision Date', value: 'revisedOn' },
	{ text: 'Revisor', value: 'revisedBy' },
	{ text: 'Start Date', value: 'startDate' },
	{ text: 'End Date', value: 'endDate' },
	{ text: 'Leave Length', value: 'leaveLength' },
	{ text: 'Payment Method', value: 'paymentMethod' },
	{ text: 'Status', value: 'status' },
	{ text: 'Employee\'s COmments', value: 'employeeComments' },
	{ text: 'Revisor\'s Comments', value: 'reviewerComments' }
]