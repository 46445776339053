import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Select, { Creatable, Option, OptionValues, ReactSelectProps } from 'react-select';
import { FormControl } from 'react-bootstrap';
import moment from 'moment';
import { ErrorBox } from '../uiElements';
import listsStore from '../../../stores/mobx/database/listsStore';
import { observer } from 'mobx-react-lite';
import { IWorkerForTaskOrRoster } from '../../../app/models/Worker';

interface IProps extends Omit<ReactSelectProps, 'onChange'> {
	onlyActive?: boolean;
	containerId?: string;
	isCreatable?: boolean;
	availabilityDate?: string;
	rosterDate?: string;
	companyAbn?: string;
	showLicenceClass?: boolean;
	onChange: (worker?: IWorkerForTaskOrRoster) => void;
}

const WorkersSelectForTasks = observer((props: IProps) => {	
	const inputRef = useRef<HTMLDivElement>(null);

	const { data, isFetching, error } = listsStore.workersForTaskOrRoster;

	const fetchData = React.useCallback(() => {
		if (props.availabilityDate && props.rosterDate)
			listsStore.fetchWorkersForTasks(false, props.onlyActive, props.availabilityDate, props.rosterDate)
		
	}, [props.availabilityDate, props.rosterDate, props.onlyActive]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChange = (selectedOption: Option<OptionValues> | null) => {
		console.log(selectedOption);
		if (selectedOption?.value && inputRef.current) {
      const controlElement = inputRef.current.querySelector('.Select-control');
      controlElement?.classList.remove('invalid');
      inputRef.current.querySelectorAll('label.error').forEach(label => label.remove());
		}

		const worker = data?.find(w => w.azureId === selectedOption?.value);
		props.onChange(worker)
	}

	const { availabilityDate, showLicenceClass } = props;
	const renderLabel = useCallback((user: IWorkerForTaskOrRoster) => {
		let label = user.shortName;		

		if (showLicenceClass)
			label += ` (${user.currentLicenceType || 'No Licence'})`;

		if (availabilityDate) {
			const { LeaveRequest, Roster } = user;
			
			if (LeaveRequest)
				label += ` (${LeaveRequest.isApproved ? 'Approved' : 'Pending'} ${LeaveRequest.absenceReasonTypeName} from ${moment(LeaveRequest.startDate).format('DD/MM')} to ${moment(LeaveRequest.endDate).format('DD/MM')})`;
			else if (!Roster)
				label += ' (Not Rostered)';
			else {
				const { startTimeLocal, budgetedTime } = Roster;
				const $rosterStartTime = moment(startTimeLocal);
				const $rosterFinishTime = $rosterStartTime.clone().add(budgetedTime, 'hours');
				label += ` (${$rosterStartTime.format('HH:mm')} - ${$rosterFinishTime.format('HH:mm')})`
			}
		}
		
		return label;
	}, [showLicenceClass, availabilityDate]);

	/** Render select options */
	const { companyAbn } = props;
	const options = useMemo(() => {
		if (!data) return [];

		return data
		.filter(worker => !companyAbn || worker.currentCompanyId === companyAbn)
		.map(user => {
			const { LeaveRequest, Roster } = user;
			
			let className = '';
			if (LeaveRequest)
				className = (LeaveRequest.isApproved ? 'text-danger' : 'text-warning');
			else if (Roster)
				className = 'text-success text-bold';

			return { 
				value: user.azureId, 
				label: renderLabel(user), 
				className,
				data: user
			}
		});
	}, [data, companyAbn, renderLabel]);

	if (error)
		return <ErrorBox error={error} retryFunc={fetchData} />;

	const selectPops = {
		value: props.value,
		placeholder: isFetching ? 'Loading...' : 'Search User...',
		noResultsText: isFetching ? '' : 'No user was found',
		disabled: props.disabled,
		options,
		cache: false,
		isLoading: isFetching,
		onChange: handleChange
	};

	return (
		<div 
			id={props.containerId} 
			ref={inputRef} 
			className="react-select-container"
		>
			{
				props.isCreatable ? (
					<Creatable { ...selectPops } />
				) : (
					<Select { ...selectPops } />
				)
			}
			<FormControl 
				id={props.id} 
				className={props.required ? 'required' : ''}
				type="hidden" 
				value={props.value ? 'OK' : ''}
			/>
		</div>
	);
});

export { WorkersSelectForTasks };