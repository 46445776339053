import React from 'react';
import PropTypes from 'prop-types';

import { MainContent } from '../../../common/layout';
import CompanyForm from './CompanyForm';

const CompanyEditContainer = props => (
    <MainContent title="Edit Company">
        <CompanyForm isEditMode abn={props.params.abn} />
    </MainContent>
);

CompanyEditContainer.propTypes = {
    params: PropTypes.shape({
        abn: PropTypes.string.isRequired
    }).isRequired
};

export default CompanyEditContainer;