import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ContentBox } from '../../common/uiElements';
import LineChart from '../../common/charts/LineChart';

const propTypes = {
	breadcrumbs: PropTypes.array,
	responsiveClass: PropTypes.string,
	vehicle: PropTypes.object, 
	onSelectGraphPoint: PropTypes.func,
};

const FreezerComponent = ({ breadcrumbs, responsiveClass, vehicle, onSelectGraphPoint }) => {
	if (!breadcrumbs || !vehicle || !vehicle.vehSetHasFreezerSen)
		return <div></div>;

	return (
		<div className={("graph-box " + (responsiveClass ? responsiveClass : ''))}>
			<ContentBox title="Freezer" icon="snowflake-o" color="primary" minimize={true} bordered={true}>
				<LineChart displayLegend={false} data={breadcrumbs} onSelectGraphPoint={onSelectGraphPoint} vehicle={vehicle} chartName="freezerOnOff"/>
			</ContentBox>
		</div>
	);
};

FreezerComponent.propTypes = propTypes;
  
const mapStateToProps = state => ({
	breadcrumbs: state.map.breadcrumbs,
	vehicle: state.filters.vehicle
});
  
export default connect(mapStateToProps)(FreezerComponent);