import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, HelpBlock } from 'react-bootstrap';
import { Callout } from '../../common/uiElements';
import StringMask from 'string-mask';

const propTypes = {
	/** The id of the field */
	id: PropTypes.string.isRequired,

	/** The type of the field (KM or Hours) */
	type: PropTypes.oneOf(['kilometers', 'hours']).isRequired,

	initialValue: PropTypes.number.isRequired,

	/** Abbreviation of the type  */
	typeShort: PropTypes.string,

	/** Any identifier for the attribute so it can be reset in case it changes */
	elementId: PropTypes.string.isRequired,

	/** The current value to compare with the initial */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The maximum difference permitted between initial and current values to show just a warning  */
	warningMaxDiff: PropTypes.number,

	/** The maximum difference permitted between initial and current values to show as an error  */
	errorMaxDiff: PropTypes.number,

	hidePreviousValue: PropTypes.bool,

	onChange: PropTypes.func

};

class LimitNumberInput extends Component {
	state = {
		showWarning: false,
		showError: false
	};
	
	componentDidUpdate(prevProps) {
		if (prevProps.elementId !== this.props.elementId)
			this.setState({ 
				showWarning: false,
				showError: false
			});
	}	

	handleChange = e => {
		const { showError, showWarning } = this.state;
		if (showError || showWarning)
			this.setState({ showWarning: false, showError: false});

		const { id, value } = e.target;
		if (isNaN(value)) return;

		const { onChange, type } = this.props;

		if (!onChange)
			return;

		switch (type) {
			case 'kilometers':
				return onChange({ [id]: value * 1000 });
			case 'hours':
				return onChange({ [id]: value * 60 * 60 });	
			default:
				break;
		}
	};

	handleBlur = () => {
		const { initialValue, warningMaxDiff, errorMaxDiff, value } = this.props;
		const total = Math.abs(this.renderValue(initialValue) - this.renderValue(value));	

		this.setState({ 
			showWarning: value && initialValue && warningMaxDiff && total > warningMaxDiff && (!errorMaxDiff || total <= errorMaxDiff) ? true : false,
			showError: value && initialValue && errorMaxDiff && total > errorMaxDiff ? true : false
		}, () => {
			const { showError, showWarning } = this.state;
			if (!showWarning && !showError)
			return;

			if (showError)
				this.input.focus();
			
			window.$('html, body').animate({
				scrollTop: window.$(this.input).offset().top - 250
			}, 300);
		});
	}

	renderValue = (value) => {
		switch (this.props.type) {
		case 'kilometers':
			value = value / 1000;
			break;
		case 'hours':
			value = value / 60 / 60;
			break;
		default:
			break;
		}

		return value.toFixed(0);
	}

	render() {
		const { state, props } = this;

		const initialValueRendered = StringMask.apply(this.renderValue(props.initialValue), '###,###,###', { reverse: true });
		return (
			<div>
				<InputGroup>
					<FormControl 
						inputRef={ref => this.input = ref}
						id={props.id}
						type="tel" 
						maxLength={7}
						value={props.value ? this.renderValue(props.value) : ''}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
					/>
					{ props.typeShort && <InputGroup.Addon>{props.typeShort}</InputGroup.Addon> }
				</InputGroup>
				{
					!props.hidePreviousValue && props.initialValue > 0 && (
						<HelpBlock>
							<i>The previous value was {initialValueRendered}</i>
						</HelpBlock>
					)
				}
				{
					state.showWarning && (
						<Callout icon="warning" title="Warning" color="warning">
							The value seems to be wrong because the difference from the previous value is greater than {StringMask.apply(props.warningMaxDiff, '###,###,###', { reverse: true })}. If it is right, just ignore this alert.
						</Callout>
					)
				}
				{
					state.showError && (
						<Callout icon="times" title="Error">
							Please check if you added an extra digit to the value as the difference from the previous value is greater than {StringMask.apply(props.errorMaxDiff, '###,###,###', { reverse: true })}.<br/><br/>
							<i>If the value you entering is correct but you can still see this message, please leave the value as {initialValueRendered} and talk to your supervisor about the issue.</i>
						</Callout>
					)
				}
			</div>
		);
	}
}

LimitNumberInput.propTypes = propTypes;

export { LimitNumberInput };