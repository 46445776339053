import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import TimeField from 'react-simple-timefield';
// import Icon from 'react-fontawesome';

import { Table, Button, FormGroup, Row, Col, FormControl } from 'react-bootstrap';
import { CheckboxList } from './CheckboxList';
import { Checkbox } from './Checkbox';
import './WeekdaysAndTime.scss';

class WeekdaysAndTime extends Component {
	constructor() {
		super();
		this.state = {
			daysOfWeek: []
		};
	}

	setDaysOfWeek = daysOfWeek => {
		this.setState({ daysOfWeek });
	}

	handleChangeInput = e => {
		const { id, value } = e.target;
		this.setState({ [id]: value });
	}

	render() {
		const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		const sample = [
			{ dayOfWeek: 1, startTime: '10:00', endTime: '12:00', isDeliveryAttended: true },
			{ dayOfWeek: 1, startTime: '18:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 2, startTime: '10:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 2, startTime: '18:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 3, startTime: '10:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 3, startTime: '18:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 4, startTime: '10:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 4, startTime: '18:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 5, startTime: '10:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 5, startTime: '18:00', endTime: '20:00', isDeliveryAttended: true },
			{ dayOfWeek: 6, startTime: '08:00', endTime: '10:00', isDeliveryAttended: false },
			{ dayOfWeek: 7, startTime: '08:00', endTime: '09:00', isDeliveryAttended: false },
		];

		// return (
		// 	<Fragment>
		// 		{
		// 			weekDays.map(weekDay => (
		// 				<FormGroup key={weekDay}>
		// 					<label>{ weekDay }</label>
		// 					<InputGroup style={{ width: '160px' }}>
		// 						<FormControl bsSize="sm" type="text" />
		// 						<InputGroup.Addon></InputGroup.Addon>
		// 						<FormControl bsSize="sm" type="text" />
		// 					</InputGroup>
		// 					<InputGroup style={{ width: '160px' }}>
		// 						<FormControl bsSize="sm" type="text" />
		// 						<InputGroup.Addon></InputGroup.Addon>
		// 						<FormControl bsSize="sm" type="text" />
		// 					</InputGroup>
		// 				</FormGroup>
		// 			))
		// 		}
		// 	</Fragment>
		// );

		return (
			<Row>
				<Col sm={6}>
					<Table condensed hover>
						<tbody>
							{
								weekDays.map((dayOfWeek, index) => (
									<tr key={weekDays}>
										<th style={{ width: '50px' }}>{ dayOfWeek }</th>
										{
											sample.filter(p => p.dayOfWeek === index + 1)
												.map(({ startTime, endTime, isDeliveryAttended }, index) => (
												<td key={index} style={{ width: '100px' }} className={!isDeliveryAttended ? 'text-danger' : ''}>
													{ startTime } - { endTime }
												</td>
											))
										}
									</tr>
								))
							}
							{/* { 
								weekDays.map((dayOfWeek, index) => {
									const periodWindows = sample.filter(p => p.dayOfWeek === index + 1)
									return periodWindows.map(({ startTime, endTime, isDeliveryAttended }, index) => (
										<td key={index} className={!isDeliveryAttended ? 'bg-red' : ''}>
											{ startTime } - { endTime }
										</td>
									))
								}) 
							} */}
						</tbody>
					</Table>
				</Col>
				<Col sm={6}>
					<FormGroup>
						<Button 
							bsStyle="danger" 
							bsSize="sm"
							onClick={this.setDaysOfWeek.bind(this, [])}
						>
							Reset
						</Button>
						&nbsp;
						&nbsp;
						<Button 
							bsStyle="warning" 
							bsSize="sm"
							onClick={this.setDaysOfWeek.bind(this, [6, 7])}
						>
							Sat - Sun
						</Button>
						&nbsp;
						&nbsp;
						<Button 
							bsStyle="info" 
							bsSize="sm"
							onClick={this.setDaysOfWeek.bind(this, [1, 2, 3, 4, 5])}
						>
							Mon - Fri
						</Button>
					</FormGroup>
					<FormGroup>
						<CheckboxList 
							value={this.state.daysOfWeek}
							items={[
								{ text: 'Mon', value: 1 },
								{ text: 'Tue', value: 2 },
								{ text: 'Wed', value: 3 },
								{ text: 'Thu', value: 4 },
								{ text: 'Fri', value: 5 },
								{ text: 'Sat', value: 6 },
								{ text: 'Sun', value: 7 }
							]}
							onChange={daysOfWeek => this.setState({ daysOfWeek })}
						/>
						<FormControl id="daysOfWeek" type="hidden" value={this.state.daysOfWeek.length > 0 ? 'hasValue' : ''} />
					</FormGroup>
					<Row>
						<Col sm={4}>
							<FormGroup>
								<label>Start Time</label>
								<TimeField
									value={this.state.startTime}
									input={<FormControl id="startTime"/>}
									onChange={value => this.setState({ startTime: value })}
								/>
							</FormGroup>
						</Col>
						<Col sm={4}>
							<FormGroup>
								<label>End Time</label>
								<TimeField
					
									value={this.state.endTime}
									input={<FormControl id="endTime" />}
									onChange={value => this.setState({ endTime: value })}
								/>
							</FormGroup>
						</Col>
						<Col sm={4}>
							<label>&nbsp;</label>
							<Checkbox  
								text="Not Attended" 
								checked={this.state.isDeliveryAttended} 
								onChange={e => this.setState({ isDeliveryAttended: e.target.checked })}
							/>
						</Col>
					</Row>
					<FormGroup>
						<Button 
							block
							bsStyle="success"
						>
							Save Window
						</Button>
					</FormGroup>
				</Col>
			</Row>
		);

		// return (
		// 	<div className="weekdays-time">
		// 		<Table condensed bordered>
		// 			<thead>
		// 				<tr>
		// 					<th></th>
		// 					{ 
		// 						this.state.hours.map(hour => (
		// 							<th className="text-center" colSpan="2" key={hour}>
		// 								{ hour }
		// 							</th>
		// 						))
		// 					}
		// 				</tr>
		// 			</thead>
		// 			<tbody>
		// 				{
		// 					weekDays.map((dayOfWeek, index) => (
		// 						<tr key={weekDays}>
		// 							<th style={{ width: '50px' }}>{ dayOfWeek }</th>
		// 							{
		// 								this.state.hoursForHalfCell.map(halfCell => (
		// 									<td key={halfCell}></td>
		// 								))
		// 								// sample.filter(p => p.dayOfWeek === index + 1)
		// 								// 	.map(({ startTime, endTime, isDeliveryAttended }, index) => (
		// 								// 	<td key={index} className={!isDeliveryAttended ? 'text-danger' : ''}>
												
		// 								// 	</td>
		// 								// ))
		// 							}
		// 						</tr>
		// 					))
		// 				}
		// 			</tbody>
		// 		</Table>
		// 	</div>
		// )
	}
}

export { WeekdaysAndTime };