import timeSheetEndpoints from './endpoints/timeSheetEndpoints';
import httpRequests from './httpRequests';

export default {
	/**
     * Get the bundy clock data by period
     * @param startDate 
     * @param endDate 
     */
	getBundyClockByPeriod(startDate: string, endDate: string) {
		const endpoint = timeSheetEndpoints.byPeriod(startDate, endDate);
		return httpRequests.downloadFile(endpoint);
	},
    
	/**
     * Get the bundy clock data by week
     * @param year 
     * @param week 
     */
	getBundyClockByWeek(year: number, week: number) {
		const endpoint = timeSheetEndpoints.byWeek(year, week);
		return httpRequests.downloadFile(endpoint);
	},
    
	/**
     * Get the list of all worker's time sheet data
     * @param workerId 
     * @param startTimeLocal 
     * @param finishTimeLocal 
     * @param isChecked 
     * @param isHidden
     * @param hasEmployeeAddedFinishTime
     * @param hasHRAddedDataManually 
     */
	getWorkerTimeSheetList(workerId: string, startTime: string, finishTime: string, minimumActualWorkedHours: number, isChecked: boolean, isHidden: boolean, hasEmployeeAddedFinishTime: boolean, hasHRAddedDataManually: boolean) {
		const filterString = { workerId, startTime, finishTime, minimumActualWorkedHours, isChecked, isHidden, hasEmployeeAddedFinishTime, hasHRAddedDataManually };
		const endpoint = timeSheetEndpoints.root;
		return httpRequests.get(endpoint, { filterString: JSON.stringify(filterString) });
	},
    
	/**
     * Update a WorkerTimeSheet data
     * @param id WorkerTimeSheet ID
     * @param data WorkerTimeSheet data object
     */
	updateWorkerTimeSheet(id: number, data: object) {
		const endpoint = timeSheetEndpoints.timeSheetWithId(id);
		return httpRequests.put(endpoint, data);
	},

	/**
     * Save a new WorkerTimeSheet data
     * @param data WorkerTimeSheet data object
     */
	saveNewWorkerTimeSheet(data: object) {
		const endpoint = timeSheetEndpoints.root;
		return httpRequests.post(endpoint, data);
	}
};