import servicingEndpoints from './endpoints/servicingEndpoints';
import httpRequests from './httpRequests';

export default {
	countersTotalXls(vehTypeFilter: string[]) {
		const endpoint = servicingEndpoints.countersTotalXls;
		return httpRequests.downloadFile(endpoint, { onlyActive: true, vehTypeFilter });
	},

	countersByDateXls(vehTypeFilter: string[], startTime: number, finishTime: number) {
		const endpoint = servicingEndpoints.countersByDateXls;
		return httpRequests.downloadFile(endpoint, { onlyActive: true, vehTypeFilter, startTime, finishTime });
	}
};