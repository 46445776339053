const pathCDN = 'http://cdn.micway.com.au/tms/img/vehicles';

export default {
	PALLET_JACK: `${pathCDN}/palletJack_icon.png`,
	PALLET_JACK_BATTERY_LOW: `${pathCDN}/palletJackBattLow_icon.png`,
	PALLET_JACK_BATTERY_OK: `${pathCDN}/palletJackBattOk_icon.png`,
	PALLET_JACK_CHARGING: `${pathCDN}/palletJackCharging_icon.png`,
	RIGID: `${pathCDN}/rigid_icon.png`,
	RIGID_ENGINE_ON: `${pathCDN}/rigidEngineOn_icon.png`,
	PRIME_MOVER: `${pathCDN}/primeMover_icon.png`,
	PRIME_MOVER_ENGINE_ON: `${pathCDN}/primeMoverEngineOn_icon.png`,
	TRAILER: `${pathCDN}/trailer_icon.png`,
	B_DOUBLE: `${pathCDN}/bDouble_icon.png`
};