/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

const propTypes = {
	index: PropTypes.number.isRequired,
	onRemoveFile: PropTypes.func.isRequired,
	dataURL: PropTypes.string.isRequired
};

const Item = ({
	index,
	onRemoveFile,
	dataURL
}) => (
	<li className="mpi-item">
		<img src={dataURL} alt={`Upload ${index + 1}`}/>
		<a onClick={() => onRemoveFile(index)} className="mpi-remove-btn">
			<Icon name="trash" />
		</a>
	</li>
);

Item.propTypes = propTypes;

export default Item;