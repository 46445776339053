import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import FaultReportApi from '../../../../../app/api/FaultReportApi';
import FaultReportPhoto from './FaultReportPhoto';

import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { Callout, Form, UserDetailsLink, ErrorBox } from '../../../../common/uiElements';
import { Checkbox, MultiplePicturesInput, VehicleSearch, Signature } from '../../../../common/inputs';
import { FaultReportTypesList, SupervisorsList } from '../../../../common/lists';

const propTypes = {
	/** Fault report object */
	faultReportData: PropTypes.object,

	/** List of vehicles to show in dropdown list */
	fleetNumbers: PropTypes.array,

	/** Called when the inputs change */
	onChange: PropTypes.func,

	/** Called when image is being loaded */
	onLoadPicture: PropTypes.func,

	/** If true, nothing can be edited */
	isReadOnly: PropTypes.bool,

	/** If true, the hadQuickFix and comments fields can be added */
	isEditable: PropTypes.bool,

	/** If true, it shows all fault report fields */
	showAllFields: PropTypes.bool
};

const defaultProps = {
	faultReportData: {}
};

class FaultReport extends Component {
	constructor() {
		super();
		this.state = {
			statusComments: '',
			hadQuickFix: '',
			isSavingStatusComments: false,
			isSavingHadQuickFix: false,
			errorStatusComments: null,
			errorHadQuickFix: null,
			typeOptions: [],
			loadedPhotos: 0
		};

		this.validations = {
			reportedTypeId: 'required',
			reportedTypeOption: 'required',
			reportedVehicleId: 'required',
			reportedDescription: 'required',
			reportedToId: 'required',
			signature: 'required'
		};
	}

	UNSAFE_componentWillMount() {
		const { faultReportData } = this.props;
		this.setState({
			statusComments: faultReportData.statusComments,
			hadQuickFix: faultReportData.hadQuickFix
		});
	}

	handleChange = e => {
		const { id, value } = e.target;
		this.props.onChange(id, value);
	}

	handleChangeType = e => {
		const { id, value } = e.target;
		this.props.onChange(id, value);

		const dtoValue = e.target.options[e.target.selectedIndex].text;
		setTimeout(() => {
			this.props.onChange('dtoFaultReportTypeName', dtoValue);
		}, 100);
	}

	handleSetOptions = options => {
		this.setState({ typeOptions: options });
	}

	handleChangeAdditionalOption = e => {
		const { id, value } = e.target;
		this.props.onChange(id, value);
	}

	handleChangeVehicle = vehicle => {
		const { vehTypeID, vehFleetNumber } = vehicle;
		this.setState({ typeOptions: [] });
		
		this.props.onChange('reportedVehicleId', vehFleetNumber);
		setTimeout(() => this.props.onChange('selectedVehicleType', vehTypeID), 500);
	}

	handleChangeSignature = signatureFile => {
		this.props.onChange('signature', signatureFile);
	}

	handleChangeEditableFields = e => {
		const { id, value } = e.target;
		
		this.setState({
			[id]: value
		});
	}

	handleChangePicture = (pictures) => {
		return this.props.onChange('FaultReportPhotos', pictures);
	}    

	handleBlurStatusComments = () => {
		const { statusComments } = this.state;
		const { faultReportData } = this.props;

		if (statusComments === faultReportData.statusComments)
			return;
		
		this.setState({ isSavingStatusComments: true, errorStatusComments: null });
		FaultReportApi.updateStatusComments(faultReportData.id, statusComments)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;

				this.setState({ isSavingStatusComments: false });
				faultReportData.statusComments = statusComments;
			
			})
			.catch(error => {
				console.error(error);
				this.setState({ 
					isSavingStatusComments: false, 
					errorStatusComments: error 
				});
			});
	}

	handleChangeHadQuickFix = e => {
		const hadQuickFix = e.target.checked;
		const { faultReportData } = this.props;
		
		this.setState({ hadQuickFix, isSavingHadQuickFix: true, errorHadQuickFix: null });
		FaultReportApi.updateQuickFix(faultReportData.id, hadQuickFix)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;

				this.setState({ isSavingHadQuickFix: false });
				faultReportData.hadQuickFix = hadQuickFix;
			
			})
			.catch(error => {
				console.error(error);
				this.setState({ 
					isSavingHadQuickFix: false, 
					hadQuickFix: !this.state.hadQuickFix 
				});
			});
	}

	handleLoadPhoto = () => {
		this.setState({ 
			loadedPhotos: this.state.loadedPhotos + 1 
		}, () => {
			const faultReportPhotosQty = this.props.faultReportData.FaultReportPhotos.length;
			if (faultReportPhotosQty === this.state.loadedPhotos) {
				const elementPhotos = document.getElementById('photos');
				if (elementPhotos)
					new Viewer(elementPhotos, {
						title: false,
						toolbar: true,
						movable: true,
						zoomOnTouch: true,
						zoomOnWheel: true,
						zoomable: true
					});
			}
				
		});
	}

	renderForm = () => {
		const p = this.props;

		return (
			<Fragment>
				<Row>
					<Col xs={12}>
						<FormGroup className="fault-report__description">
							<label>Description *</label>
							{
								p.isReadOnly ? (
									<p>{ p.faultReportData.reportedDescription }</p>
								) : (
									<FormControl 
										id="reportedDescription"
										componentClass="textarea" 
										placeholder="Describe the problem here..." 
										rows="4"
										value={p.faultReportData.reportedDescription}
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
					</Col>
				</Row>
				{
					p.showAllFields && (
						<Row>
							<Col xs={6}>
								<FormGroup>
									<label>Reported To *</label>
									{
										p.isReadOnly ? (
											<p>
												{
													p.faultReportData.WorkerReportedTo ? (
														<UserDetailsLink azureId={p.faultReportData.WorkerReportedTo.azureId}>
															{ p.faultReportData.WorkerReportedTo.shortName }
														</UserDetailsLink>
													) : '-'
												}
											</p>
										) : (
											<SupervisorsList 
												id="reportedToId"
												value={p.faultReportData.reportedToId}
												onLoadList={this.handleOnLoadSupervisorsList}
												onChange={this.handleChange}
											/>
										)
									}
								</FormGroup>
							</Col>
							{
								p.isReadOnly && p.faultReportData.reportedOdometer !== null && p.faultReportData.reportedOdometer > 0 && (
									<Col xs={6}>
										<FormGroup>
											<label>Odometer</label>
											<p>{ (p.faultReportData.reportedOdometer / 1000).toFixed(0) } Km</p>
										</FormGroup>
									</Col>
								)
							}
						</Row>
					)
				}
				<Row>
					<Col xs={12} className="fault-report__photos">
						<h3 className="title">
							<Icon name="camera" /> Photos
						</h3>
						<FormGroup>
							{
								p.isReadOnly ? (
									<div>
										{
											p.faultReportData.FaultReportPhotos.length === 0 ? (
												<p><i>No photo was added.</i></p>
											) : (
												<ul className="list-inline list-unstyled" id="photos">
													{
														p.faultReportData.FaultReportPhotos.map((photo, index) => {
															const photoId = photo.id;
															const faultReportId = p.faultReportData.id;

															return (
																<li className="cursor-pointer" style={{padding: '5px'}} key={index}>
																	<FaultReportPhoto 
																		faultReportId={faultReportId}
																		photoId={photoId} 
																		onLoadComplete={this.handleLoadPhoto}
																	/>
																</li>
															);
														})
													}
												</ul>
											)
										}
									</div>
								) : (
									<MultiplePicturesInput 
										id="FaultReportPhotos" 
										quality={0.4} 
										maxPictures={5} 
										pictures={p.faultReportData.FaultReportPhotos || []} 
										onChange={this.handleChangePicture}
										onLoadPicture={p.onLoadPicture}
									/>
								)
							}
						</FormGroup>
					</Col>
				</Row>
				{
					!p.isReadOnly && (
						<Callout color="info">
							Photos are not required, but they have a great value to give us a deeper understanding of the issue. 
						</Callout>
					)
				}
				{
					!p.isReadOnly && p.showAllFields && (
						<Row>
							<Col xs={12}>
								<h3 className="title">
									<Icon name="pencil" /> Signature
								</h3>
								<FormGroup>
									<Signature 
										id="signature"
										data={p.faultReportData.signatureFile}
										onChange={this.handleChangeSignature} />
								</FormGroup>
							</Col>
						</Row>
					)
				}
			</Fragment>
		)
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<Form validations={this.validations}>
				{
					p.faultReportData.faultReportNumber && (
						<div>
							<Row>
								<Col sm={4} md={3}>
									<FormGroup>
										<label>Had a QuickFix</label>
										{
											!p.isEditable ? (
												<p>{ p.faultReportData.hadQuickFix ? 'Yes' : 'No'  }</p>
											) : (
												<Checkbox
													block
													id="hadQuickFix"
													checked={s.hadQuickFix}
													onChange={this.handleChangeHadQuickFix}
												/>
											)
										}
									</FormGroup>
								</Col>
								<Col sm={8} md={9}>
									<FormGroup>
										<label>Comments</label>
										{
											s.errorStatusComments ? (
												<ErrorBox error={s.errorStatusComments} retryFunc={this.handleBlurStatusComments} />
											) : (
												!p.isEditable ? (
													<p>{ p.faultReportData.statusComments || '-' }</p>
												) : (
													<FormControl
														id="statusComments"
														value={s.statusComments || ''}
														placeholder="Type your comments here..."
														componentClass="textarea"
														rows={2}
														disabled={s.isSavingStatusComments}
														onChange={this.handleChangeEditableFields}
														onBlur={this.handleBlurStatusComments}
													/>
												)
											)
										}
									</FormGroup>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col xs={12}>
									<FormGroup className="fault-report__vehicle">
										<label>Fault Report Number</label>
										<p>{ p.faultReportData.faultReportNumber }</p>
									</FormGroup>
								</Col>
							</Row>
						</div>
					)
				}
				{
					!p.isReadOnly && (
						<Callout color="info">
							Ensure you select the right vehicle, and write a full description to help the maintenance team allocate the request precisely.
						</Callout>
					)
				}
				<Row>
					<Col xs={12} sm={4}>
						<FormGroup className="fault-report__vehicle">
							<label>Vehicle *</label>
							{
								p.isReadOnly ? (
									<p>{ p.faultReportData.reportedVehicleId }</p>
								) : (
									<VehicleSearch
										onlyActive
										id="reportedVehicleId" 
										withoutSearch={p.fleetNumbers ? true : false}
										filterFleetNumbers={p.fleetNumbers}
										value={p.faultReportData.reportedVehicleId}
										onSelectVehicle={this.handleChangeVehicle}
									/>
								)
							}
						</FormGroup>
					</Col>
					{
						p.faultReportData.reportedVehicleId && (
							<Fragment>
								<Col xs={6} sm={4}>
									<FormGroup className="fault-report__type">
										<label>Type *</label>
										{
											p.isReadOnly ? (
												<p>{ p.faultReportData.dtoFaultReportTypeName }</p>
											) : (
												<FaultReportTypesList 
													id="reportedTypeId" 
													value={p.faultReportData.reportedTypeId}
													vehTypeID={p.faultReportData.selectedVehicleType}
													onChange={this.handleChangeType}
													onSetOptions={this.handleSetOptions}
												/>
											)
										}
									</FormGroup>
								</Col>
								{
									( p.faultReportData.reportedTypeOption || (s.typeOptions && s.typeOptions.length > 0)) && (
										<Col xs={6} sm={4}>
											<FormGroup className="fault-report__type">
												<label>Additional *</label>
												{
													p.isReadOnly ? (
														<p>{ p.faultReportData.reportedTypeOption }</p>
													) : (
														<FormControl 
															id="reportedTypeOption"
															value={p.faultReportData.reportedTypeOption}
															componentClass="select"
															onChange={this.handleChangeAdditionalOption}
														>
															<option value="">--- SELECT ---</option>
															{ 
																s.typeOptions && s.typeOptions.map(option => (
																	<option key={option} value={option}>
																		{ option }
																	</option>) 
																)
															}
														</FormControl>
													)
												}
											</FormGroup>
										</Col>
									)
								}
							</Fragment>
						)
					}
				</Row>
				{ p.faultReportData.reportedVehicleId && this.renderForm() }
			</Form>
		);
	}
}

FaultReport.propTypes = propTypes;
FaultReport.defaultProps = defaultProps;

export default FaultReport;