/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../inputs';

const propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]).isRequired
		})
	).isRequired,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	),
	allChecked: PropTypes.bool,
	inline: PropTypes.bool,
	onChange: PropTypes.func
};

const defaultProps = {
	items: [],
	value: [],
	allChecked: false,
	inline: true,
	onChange: () => {}
};

class CheckboxList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			error: false
		};
	}
  
	componentDidMount() {
		const { items, allChecked, onChange } = this.props;
		if (allChecked && onChange) {
			const value = items.map(i => i.value);
			onChange && onChange(value);
		}
	}

	handleChange = (e, value) => {
		const { onChange } = this.props;
		if (!onChange)
			return;

		const newValue = this.props.value;
		const index = this.props.value.indexOf(value);
		if (index !== -1)
			newValue.splice(index, 1);
		else
			newValue.push(value);
		
		onChange(newValue);
	}

	render() {   
		const p = this.props;

		return (
			<ul className={p.inline ? 'list-inline' : 'list list-unstyled'}>
				{
					p.items.map(({ text, value }, index) => (
						<li key={index}>
							<Checkbox 
								value={value} 
								text={text}
								checked={p.value.find(v => v == value) ? true : false} 
								onChange={this.handleChange}
							/>
						</li>
					))
				}
			</ul>
		);
	}
}

CheckboxList.propTypes = propTypes;
CheckboxList.defaultProps = defaultProps;

export { CheckboxList };